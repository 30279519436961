<template>
    <div>
        <app-section>
            <pre>
                {{ page }}
            </pre>
        </app-section>
    </div>
</template>

<script>
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Contact',
    components: {
        AppSection
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    }),
};

</script>

<style lang="scss">

.p-contact {}

</style>
