<template>
    <article
        v-if="element"
        :class="`c-card -${model} -${element.section}`"
    >
        <slot name="before" />
        <div
            class="c-card__asset"
            :class="{'-has-image': $slots.asset || (element.imageAlt || element.image) }"
            ref="asset"
        >
            <slot
                name="beforeAsset"
                v-if="$slots.beforeAsset || element.category"
            >
                <tag
                    :label="element.category.title"
                    :text-color="cardColor"
                    bg-color="light"
                />
            </slot>
            <slot name="asset">
                <asset
                    :asset="cardImage"
                    :rounded="true"
                    :shape="model != 'horizontal' ? assetShape: null"
                />
            </slot>
            <slot name="afterAsset" />
        </div>
        <div ref="content" class="c-card__content">

            <slot
                name="beforeContent"
                v-if="$slots.beforeContent || element.section == 'pressArticle' || element.relatedEntry"
            >
                <div class="c-card__content-before">
                    <span v-if="element.section == 'pressArticle'" class="c-card__date">{{ postDate }}</span>
                    <btn
                        v-if="element.relatedEntry"
                        tag="router-link"
                        :url="`/${element.relatedEntry.uri}`"
                        :label="relatedEntryTitle"
                        :text-color="cardColor"
                        size="small"
                        bg-color="light"
                        icon-before="link"
                        class="c-card__related-entry -tag"
                    />
                </div>

            </slot>

            <slot name="content">
                <div class="t-content">
                    <h1 class="c-card__title">{{ element.title }}</h1>
                </div>
            </slot>

            <slot name="afterContent" />

            <slot name="link">
                <template
                    v-if="element.type == 'document' && element.document"
                >
                    <btn
                        ref="link"
                        tag="a"
                        :url="element.document.url"
                        icon-after="arrow-right"
                        class="c-card__link"
                        download
                        label="Télécharger"
                    />
                </template>
                <template v-else>
                    <btn
                        ref="link"
                        :tag="element.link ? 'a' : 'router-link'"
                        :url="`${element.link || '/' + element.uri}`"
                        icon-after="arrow-right"
                        class="c-card__link"
                        :label="linkLabel"
                    />
                </template>
            </slot>
        </div>
        <slot name="after" />
    </article>
</template>

<script>
import Tag from 'objects/Tag.vue'
import Btn from 'components/Btn.vue'
import Asset from 'objects/Asset.vue'
import { formatDate } from 'src/utils'
import { COLORS } from 'src/constants'

export default {
    name: 'Card',
    components: {
        Asset,
        Btn,
        Tag,
    },
    props: {
        assetShape: {
            type: String,
            required: null
        },
        element: {
            type: Object,
            required: true
        },
        color: {
            String,
            default: 'dark',
            validator: color => COLORS.indexOf(color) !== -1
        },
        linkLabel: {
            String,
            default: 'Lire la suite'
        },
        model: {
            type: String,
            default: 'standard',
            validator: (model) => {
                return ['horizontal', 'enhance', 'standard'].indexOf(model) !== -1
            }
        }
    },
    mounted() {
        const _refs = this.$refs
        this.$el.style.setProperty('--card-color', this.cardColor)

        // Delegate clicks
        setTimeout(() => {
            if (_refs.asset)
                _refs.asset.addEventListener('click', function() {

                    if (_refs.link && _refs.link.$el)
                        _refs.link.$el.click()

                }, false)

            if (_refs.content)
                _refs.content.addEventListener('click', function() {

                    if (_refs.link && _refs.link.$el)
                        _refs.link.$el.click()

                }, false)
        }, 500)
    },
    computed: {
        relatedEntryTitle() {
            return this.element.relatedEntry.title
        },
        postDate() {
            return formatDate(this.element.datePost)
        },
        cardImage() {

            const element = this.element

            if ( (element.section == 'project' || element.section == 'campaign') && this.model != 'horizontal' )
                return element.image

            return element.imageAlt || element.image

        },
        cardColor() {
            let section = this.element.section
            let cardColor = section == 'post'
                ? 'blue'
                : (section == 'pressArticle')
                ?  'orange'
                : this.color

            return cardColor
        }
    }
};

</script>

<style lang="scss">

.c-card {

    .c-card__asset, .c-card__content {
        cursor: pointer;
    }

    &.-enhance {
        --card-margin: calc(2 * var(--grid-gutter));
        border: solid 1px $color-beige;
        border-radius: $border-radius-md;
        padding: 0 var(--grid-gutter) var(--grid-gutter);
        margin-top: var(--card-margin);
        height: calc( 100% - var(--card-margin) );

        .c-card__asset {

            .o-asset {
                margin-top: calc(-2 * var(--grid-gutter));
            }
        }

        .c-card__before-content {
            .o-tag {
                white-space: nowrap;
            }
        }

        .c-card__content {
            position: static;
            grid-gap: var(--grid-gutter-half);
            padding-top: var(--grid-gutter);
            padding-bottom: calc(var(--grid-gutter) * 2 );

            @media #{md("sm")} {
                padding-bottom: var(--grid-gutter);
            }
        }

        .c-card__link {
            position: absolute;
            bottom: var(--grid-gutter-half);
            left: var(--grid-gutter);
        }
    }

    &.-horizontal {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: var(--grid-gutter);

        @media #{md("xs")} {
            grid-template-columns: 1fr 1fr;
        }

        .c-card__content {
            grid-auto-rows: min-content;
            grid-gap: 1rem;
            padding-top: 0;
        }
    }

    &__title {
        font-size: fs('medium');
        font-weight: 400;
    }

    &:not(.-enhance) {

        .c-card__asset {

            &.-has-image {

                .o-tag {
                    position: absolute;
                    left: 1.4rem;
                    bottom: 1.4rem;
                    z-index: 2;

                    // Override styles
                    background-color: $color-light;
                    border: none;
                }
            }
            &:not(.-has-image) {
                .o-tag {
                    background-color: transparent;
                    border: 1px solid var(--tag-text-color);
                }
            }
        }
    }
}

.c-card__content {
    padding-top: var(--grid-gutter-half);
    display:grid;
    grid-auto-rows: auto;
    grid-gap: var(--grid-gutter);

    &-before {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .c-btn {
            --btn-padding-y: 0;
        }
    }

    .c-card__date {
        white-space: nowrap;
        margin-right: var(--grid-gutter-half);
    }
    .c-card__related-entry {
        height: 1.4em;
        width: 100%;
        width: -webkit-fill-available;
        padding: 0;

        .c-btn__label {
            position: absolute;
            left: 1em;
            width: calc(100% - 1em);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .c-card__link {
        margin-left: calc(var(--btn-padding-x) * -1);
    }
}

</style>
