<template>
    <article
        v-if="video"
        class="b-video"
        :class="{'-zoom': zoom}"
    >
        <div class="b-video__asset">
            <asset
                :asset="video.image"
                :rounded="true"
            />

            <button
                class="-play"
                @click="zoomIndex = 0"
                :title="`Lire ${video.title}`"
            >
                <icon icon="play" />
            </button>

        </div>

        <div class="b-video__content">
            <header class="t-content">
                <h1
                    class="-title"
                    v-html="video.title"
                />
                <div
                    v-if="video.description"
                    class="-description | t-cms"
                    v-html="video.description"
                />
            </header>
            <footer>
                <btn
                    tag="button"
                    icon-before="play"
                    class="c-card__link"
                    label="Lire"
                    :title="`Lire ${video.title}`"
                    @click.native="zoomIndex = 0"
                />
            </footer>
        </div>

        <lightbox
            v-if="zoom"
            ref="zoom"
            :items="[video]"
            :index="zoomIndex"
            @close="zoomIndex = null"
        />
    </article>
</template>

<script>
import Asset from 'objects/Asset'
import Icon from 'objects/Icon'

import Btn from 'components/Btn'
import Lightbox from 'components/Lightbox'

import { excerpt } from 'src/utils'

export default {
    name: 'BlockVideo',
    components: {
        Asset,
        Btn,
        Icon,
        Lightbox
    },
    data: () => ({
        zoomIndex: null
    }),
    props: {
        data: false,
        zoom: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        video() {

            if (!this.data.video)
                return null

            const video = this.data.video[0]

            return {
                id: video.id,
                title: video.title,
                description: excerpt(video.description, 144),
                url: video.link,
                image: video.image[0]
            }
        }
    },
    methods: {
        excerpt
    },
    watch: {
        zoomIndex($v) {
            if (this.$refs.zoom)
                this.$refs.zoom.index = $v
        }
    }
}
</script>

<style lang="scss">
.b-video {

    .b-video__asset {

        .-play {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-light;

            .o-icon {
                --svg-width: 2.25rem;

                transform: scale(0.9);
                transition: transform .3s ease;
                will-change: transform;
            }

            &:hover {
                .o-icon {
                    transform: scale(1);
                }
            }
        }
    }

    .b-video__content {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: end;
        gap: var(--grid-gutter);
        margin-top: var(--grid-gutter);

        .t-content {
            --content-gap: 0.5rem;
        }

        @media #{md("sm", "max")} {
            .c-btn {
                display: none;
            }
        }
    }
}
</style>
