<template>
    <span
        class="o-tag"
        :class="{ '-outline' : outline }"
        :style="styles"
    >
        {{ labelCapitalized }}
    </span>
</template>

<script>

import { COLORS } from 'src/constants'
export default {
    name: 'Tag',
    props: {
        label: {
            type: String,
            required: true
        },
        outline: {
            type:Boolean,
            default: false
        },
        textColor: {
            type: String,
            default: 'dark',
            validator: color => COLORS.indexOf(color) !== -1
        },
        bgColor: {
            type: String,
            default: 'transparent',
            validator: color => COLORS.indexOf(color) !== -1
        },
    },
    computed: {
        classNames () {

            let className = ''
            if (this.outline) {
                className += '-outline'
            }
            return className
        },
        labelCapitalized() {

            if (!this.label)
                return null

            let firstLetter = [...this.label][0].toUpperCase()
            let capitalized = firstLetter + [...this.label].slice(1).join('')

            return capitalized
        },
        styles () {

           let styles = `
                --tag-bg-color: var(--color-${this.bgColor});
                --tag-text-color: var(--color-${this.textColor});`

           return styles
        }
    }
}
</script>

<style lang="scss">
.o-tag {

    display: inline-block;
    width: fit-content;
    padding: .5rem;
    font-size: fs('small');
    font-weight: 600;
    color: var(--tag-text-color, var(--color-dark));
    background-color: var(--tag-bg-color, transparent);
    border-radius: $border-radius-xs;

    // UX
    user-select: none;
    &.-outline {
        border: 1px solid var(--tag-text-color, var(--color-dark));
    }
}
</style>
