<template>
    <app-section>
        <app-elements
            :categoryGroups="categoryGroups"
            sectionHandle="post"
            :displayTextSearch="true"
            :options="{
                limit: 12
            }"
        />
    </app-section>
</template>

<script>
import AppElements from 'layout/AppElements'
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Posts',
    components: {
        AppElements,
        AppSection
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        categoryGroups: [
            {
                group: 'postTypes',
                label: 'Catégorie',
                type: 'radio'
            }
        ]
    })
};

</script>

<style lang="scss">

.p-posts {

    .l-section {
        --section-padding-top: 0;
    }

    .l-elements {
        margin-top: calc( 1rem + var(--grid-gutter-half) );
    }
}
</style>
