<template>
    <div v-if="page.heading">
        <app-section v-if="page.statNumbers.length" class="p-home__stats">
            <home-stats
                :title="page.statHeading"
                :description="page.statDesc"
                :button="{
                    label: page.statButtonLabel,
                    target: page.statButtonTarget
                }"
                :stats="page.statNumbers"
            />
        </app-section>

        <app-section v-if="page.featuredCampaign" class="p-home__campain">
            <home-campaign
                :campaignId="page.featuredCampaign.id"
            />
        </app-section>

        <app-section class="p-home__press">
            <home-press/>
        </app-section>

        <app-section v-if="page.featuredProjects" class="p-home__projects">
            <home-projects :project-ids="page.featuredProjects" />
        </app-section>

        <app-section class="p-home__posts">
            <home-posts
                :title="page.postHeading"
                :description="page.postDesc"
            />
        </app-section>
    </div>
</template>

<script>
import AppSection from 'layout/AppSection'

import HomeCampaign from 'home/HomeCampaign'
import HomePosts from 'home/HomePosts'
import HomeStats from 'home/HomeStats'
import HomeProjects from 'home/HomeProjects'
import HomePress from 'home/HomePress'

import { SECTION_HANDLES } from 'src/constants'
import { mapState } from 'vuex'

export default {
    name: 'Home',
    components: {
        AppSection,
        HomeCampaign,
        HomePosts,
        HomeProjects,
        HomePress,
        HomeStats,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
    }),
    computed: {
        ...mapState({
            globals: state => state.global
        })
    }
};

</script>

<style lang="scss">

.p-home {

    .l-header {
        background-color: $color-light;
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        overflow-x: hidden;


        @media #{md("sm")} {
            border-top-left-radius: $border-radius-lg;
            border-top-right-radius: $border-radius-lg;
        }
        &__inner {
            z-index: 9; // This is important!
        }
    }

    .l-main {
        --main-radius-tl: 0;
        --main-radius-tr: 0;
    }
}

.p-home__stats {
    @media #{md("sm")} {
        --section-padding-top: 8rem; // Important to avoid loosing the title (due to the overfloe:hidden)
        --section-padding-bottom: 4rem;
    }
    @media #{md("md")} {
        --section-padding-top: 4rem; // Important to avoid loosing the title (due to the overfloe:hidden)
    }
}

</style>
