<template>
    <div class="c-home-stats">

        <div class="c-home-stats__content | t-content">
            <highlight-text
                :text="title"
                highlight-bg="yellow"
            />
            <p
                v-if="description"
                v-html="excerpt(description, 144)"
            />
            <div class="-actions">
                <btn
                    v-if="button && button.label && button.target[0]"
                    :label="button.label"
                    bg-color="beige"
                    tag="router-link"
                    :url="`/${button.target[0].uri}`"
                />
            </div>
        </div>

        <div class="c-home-stats__grid">
            <div
                v-for="(n) in 4"
                :key="n"
                :class="`stats-block -block-${n}`"
            >
                <div class="stats-block__content" v-if="stats.length && stats[n-1]">
                    <span class="-block-number" ref="blockContent">{{ stats[n-1].line1 }}</span>
                    <span class="-block-title" ref="blockContent">{{ stats[n-1].line2 }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimText from 'objects/AnimText'
import Btn from 'components/Btn'
import HighlightText from 'components/HighlightText'

import shapeAnimation from 'assets/animations/data.json'
import lottie from 'lottie-web'
import { ScrollTrigger, gsap } from 'gsap/all'

import { SECTION_HANDLES } from 'src/constants'
import { excerpt } from 'src/utils'
import Variables from 'mixins/variables'

gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'HomeStats',
    components: {
        AnimText,
        Btn,
        HighlightText
    },
    data: () =>({
        SECTION_HANDLES,
        tl: null,
        y: 0,
        x: 0,
    }),
    props: {
        title: String,
        description: String,
        stats: Array,
        button: Object
    },
    mixins: [Variables],
    mounted() {
        this.container = document.querySelector('.c-home-stats__grid')
        this.animation = this.loadAnimation()
        this.lottieShape = this.animation.renderer.svgElement
        this.setTimeline()
        window.addEventListener('resizeEnd', this.update)
    },
    methods: {
        excerpt,
        loadAnimation() {

            const params = {
                container: this.container,
                renderer:'svg',
                rendererSettings: {
                    className: 'lottie-shape',
                    progressiveLoad: true
                },
                loop: false,
                autoplay: false,
                animationData: shapeAnimation
            }

            return lottie.loadAnimation(params)
        },
        revealContent() {
            //Animate the numbers
            let blockContent = this.$refs.blockContent

            gsap.to(blockContent, {
                delay: .3,
                stagger: .1,
                duration: .6,
                ease: 'power2.out',
                opacity: 1,
                y: 0
            })
        },
        setTimeline() {
            this.tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.container,
                    start: 'top 30%',
                    end: 'bottom 40%',
                    scrub: 0.7,
                    onUpdate: self => {
                        const frameToStop = this.animation.totalFrames * self.progress

                        this.animation.goToAndStop(frameToStop, true)

                        //Reveal only Once
                        if (self.progress > 0.25 && !this.revealContent.isRevealed) {
                            this.revealContent()
                            this.revealContent.isRevealed = true
                        }
                    }
                }
            })

            this.y = this.container.offsetHeight - this.lottieShape.clientHeight
            this.x = this.container.offsetWidth - this.lottieShape.clientWidth / 2

            this.tl.add('verticalAnim')
                .to(this.lottieShape, {
                    y: this.y,
                    x: 0,
                    duration: 1,
                })
                .add("horizontalAnim")
                .to(this.lottieShape, {
                    y: this.y,
                    x: this.x * -1,
                    duration: 1,
                })


        },
        update() {

            this.tl.scrollTrigger.kill()
            this.tl.kill()
            this.setTimeline()
        },
    },
    destroyed() {
        this.tl.scrollTrigger.kill()
        this.tl.kill()
        window.removeEventListener('resizeEnd', this.update)
    }
}
</script>

<style lang="scss">

%responsive-type {
    @include responsive-type(
        $min-font: 25,
        $max-font: 60,
        $min-width: 480,
        $max-width: 1440
    );
}

.c-home-stats {
    --block-size: clamp(14rem, 30vw, 45rem);

    @media #{md(md)} {
        padding-right: calc( var(--nav-trigger-width) / 2 );
    }
}

.c-home-stats__content {
    position: absolute;
    left: 0;
    bottom: calc( var(--block-size) + var(--grid-gutter) * 2 );
    width: 50%;
    padding-top: var(--container-gutter);

    @media #{md(xs)} {
        p {
            max-width: 50rem;
        }
    }

    @media #{md(sm)} {
        padding-left: var(--grid-gutter-half);
        padding-right: var(--grid-gutter-half);
    }

    @media #{md(md)} {
        padding-left: var(--grid-gutter);
    }

    .c-highlight {
        @extend %responsive-type;
        margin-bottom: 1rem;
    }
}

.c-home-stats__grid {

    --row: 3/4; // 1 & 2 always on same row
    --col: 2/3; // 3 & 4 always on same col

    display: grid;
    grid-template-columns: repeat(2, var(--block-size));
    grid-template-rows: repeat(3,  var(--block-size));
    justify-content: end;
    pointer-events: none;

    .stats-block {
        background: $color-beige;

        &__content {
            z-index: 1;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%);

            display: grid;
            gap: var(--grid-gutter-half);
            width: fit-content;
            text-align: center;

            pointer-events: auto;

            .-block-number {
                @extend %responsive-type;
            }

            .-block-title,
            .-block-number {
                opacity: 0;
                transform: translateY(100%);
            }
        }

        &.-block-1 {
            grid-column: 1/2;
            grid-row: var(--row);
            width: var(--block-size);

            &::before {
                @include pseudo-el($width: 100%, $height: 100%, $bg: inherit);
                position: absolute;
                left: -99%;
            }
        }
        &.-block-2 {
            grid-column: 2/3;
            grid-row: var(--row);
            border-bottom-right-radius: 50%;
        }
        &.-block-3 {
            grid-column: var(--col);
            grid-row: 2/3;
        }
        &.-block-4 {
            grid-column: var(--col);
            grid-row: 1/2;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;

            // Starting space (avoid overlap between block and shape)
            transform: translateY(2rem);
            @media #{md('sm')} {
                transform: translateY(4rem);
            }
        }
    }

    @media #{md('sm')} {
        grid-template-columns: repeat(3, var(--block-size));
        grid-template-rows: repeat(2,  var(--block-size));

        .stats-block {
            --row: 2/3;
            --col: 3/4;

            &.-block-2 {
                border-bottom-right-radius: 0;
            }

            &.-block-3 {
                border-bottom-right-radius: 50%;
            }
        }
    }

    .lottie-shape {
        grid-column: 2/3;
        grid-row: 1/2;

        @media #{md(sm)} {
            grid-column: 3/4;
            grid-row: 1/2;
        }
    }
}
</style>
