<template>
    <i :class="className">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let classname = `o-icon -${this.icon}`

            if(this.size) {
                classname += ` -${this.size}`
            }
            return classname;
        }
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

.svg-arrow-left,
.svg-arrow-right {
    --svg-width: 0.5rem;
    --svg-ratio: 11/5;
}
.svg-arrow-shape {
    --svg-ratio: 16/37;
}

.svg-atv-logo,
.svg-atv-logo-monochrome {
    --svg-width: 20rem;
    --svg-ratio: 75/224;
}

.svg-arrow-down {
    --svg-width: 1rem;
    --svg-ratio: 5/11;
}

.svg-arrow-right-big,
.svg-arrow-left-big {
    --svg-width: 1.2rem;
    --svg-ratio: 31/15;
}

.svg-l-shape {
    --svg-ratio: 1/1;
}

</style>
