<template>
    <div
        v-if="data && data.persons.length"
        class="b-persons | t-content"
    >
        <div v-if="data.title" class="b-persons__top">
            <h2 class="t-t4">{{ data.title }}</h2>
        </div>
        <div
            class="b-persons__grid"
            :class="{'-has-images': hasImages }"
        >
            <div
                v-for="(person, i) in data.persons"
                :key="`b-persons-${i}`"
                :class="`b-person person-${i}`"
            >
                <div  class="b-person__infos">
                    <asset
                        :asset="person.image"
                        shape="circle"
                        class="b-person__image"
                    />
                    <div class="b-person__contact-infos t-content">
                        <div>
                            <div class="b-person__name">{{ person.name }}</div>
                            <div class="b-person__role" v-if="person.role">{{person.role}}</div>
                        </div>
                        <a
                            v-if="person.email"
                            :href="`mailto:${person.email}`"
                            class="b-person__email"
                            rel="noopener"
                        >{{ person.email }}</a>
                        <a
                            v-if="person.phone"
                            :href="`tel:${formatPhone(person.phone)}`"
                            class="b-person__phone"
                            rel="noopener"
                        >{{ person.phone }}</a>
                    </div>
                </div>
                <div
                    class="b-person__description | t-cms"
                    v-if="person.description"
                >
                    <p v-html="person.description"></p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Asset from '../objects/Asset.vue';

export default {
    name: 'BlockPersons',
    components: {
        Asset
    },
    props: {
        data: false
    },
    computed: {
        hasImages() {

            if (!this.data || !this.data.persons.length) {
                return null
            }

            return this.data.persons
                .filter(pers => pers.image.length).length
        }
    },
    methods: {
        formatPhone(phone) {
            // Remove spaces and " - "
            return phone.replace(/(\s|-)/g, '')
        }
    }
};

</script>

<style lang="scss">
.b-persons {
    --content-gap: 3rem;

    &__grid {

        --person-grid-width: 18rem;
        display: grid;
        gap: var(--grid-gutter);
        grid-template-columns: repeat(auto-fill, minmax(var(--person-grid-width), 1fr));

        &.-has-images {
            --person-grid-width: 30rem;
        }

        @media #{md('lg')} {
            gap: 6rem;
        }

        .b-person {
            display: flex;
            flex-direction: column;
            font-size: fs('small');
            max-width: 40rem;

            &__infos {
                display: flex;
                flex-direction: column;

                @media #{md('xs')} {
                    flex-direction: row;
                    align-items: center;
                }
            }

            &__image {
                max-width: 14rem;
                width: 100%;
                margin-bottom: var(--grid-gutter);

                @media #{md('xs')} {
                    margin-bottom: 0;
                    margin-right: var(--grid-gutter);
                }
            }
            &__email {
                text-decoration: underline;
            }

            &__email,
            &__phone {
                white-space: pre;
            }

            &__contact-infos  {
                --content-gap: 1.5rem;
            }

            &__name {
                font-size: fs('regular');
            }

            &__description {
                margin-top: var(--grid-gutter-half);
            }
        }
    }
}
</style>
