/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: 'header.hero',
        mode: 'crop',
        width: 500,
        height: 500,
        quality: 90,
        position: 'center-center',
        srcset: `["259w", "343w", "359w", "482w", "518w", "542w", "590w", "686w", "718w", "964w", "1084w", "1180w"]`
    },
    {
        name: 'card.16:9',
        mode: 'crop',
        width: 290,
        height: 166,
        quality: 90,
        position: 'center-center',
        srcset: `["241w", "295w", "308w", "370w", "430w", "466w", "482w", "590w", "616w", "740w", "860w", "932w"]`
    },
    {
        name: 'card.1:1',
        mode: 'crop',
        width: 300,
        height: 300,
        quality: 90,
        position: 'center-center',
        srcset: `["241w", "295w", "308w", "370w", "430w", "466w", "482w", "590w", "616w", "740w", "860w", "932w"]`
    },
    {
        name: 'misc.person',
        mode: 'crop',
        width: 300,
        height: 300,
        quality: 90,
        position: 'center-center',
        srcset: `["140w", "280w"]`
    }
]

const getAsset = (field = 'image', format = false) => {

    let transform

    const data = `
        url
        title
        width
        height
    `

    if ( format && typeof format == 'string' ) {
        transform = transforms.find(t => t.name == format)
    }

    if ( transform ) {

        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `
        } else {

            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                }
            `
        }

    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default getAsset
