<template>
    <div class="b-partners | t-content">
        <div v-if="data.title" class="b-partners__top">
            <h2 class="t-t4">{{ data.title }}</h2>
        </div>
        <slider
            v-if="data.partners && data.partners.length"
            :slides="data.partners"
            ref="slider"
            :fullheight="false"
            :prevNext="true"
            :show-index="true"
            :zoom="false"
            modifier="partners"
            class="b-partners__slider"
            :options="{
                wrapAround: false,
                draggable: false,
                groupCells: true,
                cellAlign: 'left'
            }"
        >
            <template #slide="partner">
                <div
                    class="b-partners__item"
                >
                    <component
                        :is="partner.website ? 'a' : 'div'"
                        :href="partner.website"
                        :target="partner.website ? '_blank' : null"
                        :title="partner.website ? partner.title : null"
                    >
                        <asset
                            :asset="partner.logo"
                            @loaded="refreshSlider()"
                        />
                    </component>
                </div>
            </template>
        </slider>
    </div>
</template>

<script>
import Asset from 'objects/Asset'
import Slider from 'components/Slider'

export default {
    name: 'BlockPartners',
    components: {
        Asset,
        Slider,
    },
    props: {
        data: false
    },
    computed: {
    },
    methods: {
        refreshSlider() {
            this.$refs.slider.resize()
        },
    },
}
</script>

<style lang="scss">
.b-partners {
    --content-gap: 3rem;

    .b-partners__item {
        padding: 0 calc( var(--grid-gutter-half) / 2 );

        @media #{md("md")} {
            padding: 0 var(--grid-gutter-half);
        }

        & > * {
            display: block;
            padding: var(--grid-gutter);
            padding: 15%;
            border: 1px solid $color-beige;
            border-radius: $border-radius-md;
            overflow: hidden;

            transition: border .15s ease-out;

            @media #{md("md")} {
                //padding: var(--grid-gutter);
            }

            &:hover:is(a) {
                border-color: currentColor;
                transition-duration: .3s;
            }
        }

        .o-asset {

            img {
                width: 100%;
                height: 7rem;
                margin: auto;
                object-fit: contain;
            }
        }
    }

    /* .c-slider__slide,
    .b-partners__item {

        height: 100%;

        > * {
            height: 100%;
        }
    } */
}
</style>
