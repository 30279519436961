import { browserName, osName, isMobile } from 'mobile-device-detect'
import { ScrollBox } from 'src/ScrollBox'

export default {
    data: () => ({
        W: {
            w: window.innerWidth,
            h: window.innerHeight,
            ratio: window.innerWidth/window.innerHeight,
            smallScreen: window.innerWidth <= 767
        },
        browser: {
            name: browserName.toLowerCase().replace(' ', '-'),
            os: osName.toLowerCase().replace(' ', '-'),
        },
        isMobile,
    }),
    mounted() {

        // Set size
        this.set()

        // Add Window events
        window.addEventListener('resizeEnd', this.set);
    },
    computed: {
        isSafari() {

            return this.browser.name == 'mobile-safari' || this.browser.name == 'safari'
        }
    },
    methods: {
        set() {

            let scrollbox = new ScrollBox();

            // Define window vars
            this.W = {
                w: window.innerWidth,
                h: window.innerHeight,
                smallScreen: window.innerWidth <= 767,
                scrollbarWidth: scrollbox.width
            }

            this.W.ratio = this.W.w/this.W.h
        },
    },
    destroyed() {

        // Remove Window events
        window.removeEventListener('resizeEnd', this.set);
    },
}
