<template>
    <div
        v-if="articles.length"
        class="c-home-press"
    >
        <div class="c-home-press__top">
            <h2 class="t-t3">Espace presse</h2>
            <btn
                v-if="!isSlider"
                tag="router-link"
                label="Voir les articles"
                :is-shaped="true"
                bg-color="orange-light"
                :url="SECTION_HANDLES.PRESS|resolver"
            />
        </div>
        <card-grid
            v-if="articles"
            ref="cardGrid"
            card-model="standard"
            :cards="otherCards"
            class="c-home-press__grid"
            :slider="true"
            sliderModifier="nav-on-top"
            @sliderActive="sliderChange"
        >
            <template #afterCardNav>
                <div class="c-slider__nav--after">
                    <btn
                        tag="router-link"
                        label="Voir les articles"
                        :is-shaped="true"
                        bg-color="orange-light"
                        :url="SECTION_HANDLES.PRESS|resolver"
                    />
                </div>
            </template>
            <template #extraCards>
                <div class="c-home-press__extra-card | c-slider__slide">
                    <span class="-deco"></span>
                    <asset
                        v-if="firstCardAsset"
                        :asset="firstCardAsset"
                        shape="hexagon"
                        ref="otherCard"
                        class="-asset"
                        @loaded="getAssetSize"
                    />
                    <card
                        v-if="firstCardContent"
                        :element="firstCardContent"
                        class="-content"
                    />
                </div>
            </template>
        </card-grid>
    </div>
</template>

<script>
import CardGrid from 'components/CardGrid.vue'
import Btn from '../components/Btn.vue'
import Card from '../components/Card.vue'
import Asset from '../objects/Asset.vue'

import { excerpt } from 'src/utils'
import { SECTION_HANDLES } from 'src/constants'

export default {
    components: {
        CardGrid,
        Btn,
        Card,
        Asset,
    },
    name: 'HomePress',
    props: {
        pressIds: {
            type: Array,
            default: () => [21, 25, 232],
            validator: ids => ids.length == 3
        }
    },
    data: () => ({
        SECTION_HANDLES,
        articles: [],
        firstCardAsset: null,
        firstCardContent: null,
        otherCards: null,
        isSlider: false
    }),
    computed: {
    },
    created() {

        this.$store.dispatch('pressArticle/searchEntries', { limit: 3 })
            .then(res =>  {
                if (!res.length === 3) {
                    // eslint-disable-next-line
                    console.error('Home Press article fetch failed!')
                    return
                }

                this.articles = res
                let [ _firstCard, ..._otherCards ] = this.articles

                // eslint-disable-next-line
                const { image, imageSquare, ...content } = _firstCard
                this.firstCardAsset = imageSquare
                this.firstCardContent = content
                this.otherCards = _otherCards
            } )
    },
    mounted() {
        window.addEventListener('resizeEnd', this.getAssetSize)
    },
    methods: {
        excerpt,
        getAssetSize() {

            if (this.$refs && this.$refs.otherCard) {
                let otherCard = this.$refs.otherCard.$el
                this.$el.style.setProperty('--asset-width', otherCard.innerWidth || otherCard.clientWidth + 'px')
                this.$el.style.setProperty('--asset-height', otherCard.innerHeight || otherCard.clientHeight + 'px')
            }
        },
        sliderChange(state) {
            this.isSlider = state
        },
    },
    destroyed() {
        window.removeEventListener('resizeEnd', this.getAssetSize)
    }
}
</script>

<style lang="scss">
.c-home-press {

    @media #{md("sm", "max")} {
        padding-bottom: 12rem;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin-bottom: var(--grid-gutter);
    }

    &__grid {

        &.c-card-grid.-slider {
            .c-slider {
                --slide-width: 30rem;
            }
        }

        .c-home-press__extra-card {
            --slide-width: 30rem;
            height: 100%;

            @media #{md('sm')} {
                --slide-width: 60rem;
                display: grid;
                gap: var(--grid-gutter);
                grid-template-columns: 1fr 1fr;
                grid-column: 1/3;
                align-items: start;
            }

            .-deco {
                --deco-width: calc(var(--container-gutter) * 50);
                position: absolute;
                top: 5px;
                left: calc(-1 * var(--deco-width));
                width: calc(var(--deco-width) + 0.5 * var(--asset-width));
                height: calc(var(--asset-width) - 5px);
                background: $color-orange-light;
            }

            .c-card__asset {
                padding-top: 1rem;
            }

            .-content {
                align-self: center;
                margin-left: 0 !important;
            }
        }

        .c-card__content {
            gap: 1rem;
            padding-top: 1rem;
        }
    }
}
</style>
