<template>
    <div
        :class="className"
        :style="styles"
        :data-color="currentPage.colour"
    >
        <loader />

        <app-header
            @toggleNav="toggleNav"
        />
        <tab-list
            v-if="hasTabs"
            class="page-tabs"
            :tabs="tabs"
        />
        <main class="l-main | container" ref="main">
            <router-view :page="currentPage" />
        </main>
        <btn-up label="Haut" />
        <app-footer />
    </div>
</template>

<script>
import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';

import Loader from 'objects/Loader';
import TabList from 'layout/TabList.vue'

import { mapState } from 'vuex'
import { toKebab, hexToRgb } from 'src/utils'
import BtnUp from 'components/BtnUp.vue';

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppFooter,
        Loader,
        BtnUp,
        TabList,
    },
    data: () => ({
        navIsOpen: false,
        tabsHeight: null
    }),
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName)
                classname += ` p-${toKebab(this.currentPageName)}`
                classname += this.hasTabs ? ' -has-tabs' : ''

            return classname
        },
        hasTabs() {
            if(this.currentPage)
                return this.currentPage.parent && this.currentPage.parent.children.length
        },
        tabs() {
            if(this.currentPage && this.currentPage.parent){
                const tabs = this.currentPage.parent.children
                return tabs
            }
        },
        styles() {
            let _styles = ''

            if (this.tabsHeight)
                _styles += this.tabsHeight

            if (this.currentPage) {

                const currentPageColour = this.currentPage.colour || 'blue'

                const   rootColorVariable = `--color-${currentPageColour}`,
                        rootColorValue = getComputedStyle(document.documentElement).getPropertyValue(rootColorVariable).replace(' ', ''),
                        rgbaColor = hexToRgb(rootColorValue),

                        altColor = (currentPageColour.indexOf('-light') !== -1)
                            ? currentPageColour.replace('-light', '')
                            : `${currentPageColour}-light`

                _styles += `
                    --body-bg-color: var(--color-${currentPageColour});
                    --body-bg-color-rgb: ${rgbaColor};
                    --body-alt-color: var(--color-${altColor});
                `
            }
            return _styles
        }
    },
    methods: {
        getTabsHeight() {
            const tabs = document.querySelector('.page-tabs')

            if (tabs)
                this.tabsHeight = `--tabs-height: ${tabs.offsetHeight || 0}px;`

        },
        toggleNav(isOpen) {

            if ( isOpen )
                this.$el.classList.add('nav-is-open')
            else
                this.$el.classList.remove('nav-is-open')

            this.navIsOpen = isOpen
        },
    },
    watch: {
        hasTabs(val) {
            if (!val)
                this.tabsHeight = null

            this.$nextTick(this.getTabsHeight)
        },
    }
}

</script>

<style lang="scss">

.l-wrapper {
    --body-bg-color: #{$color-blue};
    --body-alt-color: #{$color-blue-light};

    padding: var(--grid-gutter-half);
    background-color: var(--body-bg-color);
    overflow-x: hidden;

    &:before {
        @include pseudo-el($width: 100%, $height: var(--grid-gutter-half));
        background-color: inherit;
        position: fixed;
        top:0;
        left:0;
        z-index: 0;
        opacity: 0;
    }

    &:after {
        @include pseudo-el($width: var(--grid-gutter), $height: var(--grid-gutter));
        position: fixed;
        top: var(--grid-gutter-half);
        left: var(--grid-start-x);
        background: radial-gradient(
            circle at bottom right,
            rgba(var(--body-bg-color-rgb), 0) var(--grid-gutter),
            rgba(var(--body-bg-color-rgb), 1) calc(var(--grid-gutter) + 0.1rem)
        );
        z-index: 0;
        opacity: 0;

        @media (min-width: 1540px) {
            .is-windows & {
                --grid-start-x: calc((100vw - #{$grid-max-width}) / 2 - var(--grid-gutter-half)/2);
            }
        }
    }

    body.lightbox-is-open & {
        &:before,
        &:after {
            opacity: 0;
        }
    }

    &.p-home {
        &:before,&:after {
            opacity:1;
            z-index: 11;
        }
    }

    &.header-is-hidden {
        &:before, &:after {
            z-index: 11;
            opacity: 1;
        }
    }

    .page-tabs {
        transform: translateY(-100%);
        width: 100%;
        position: absolute;
        left: var(--grid-start-x);

        @media (min-width: 1537px) {
            left: calc(var(--grid-start-x) - var(--scrollbar-width)/2);
        }
    }

    &.-has-tabs {

        .l-header__content {
            margin-bottom: 5rem;
        }
        .l-main {
            border-top-left-radius: 0;
        }

        @media #{md('xs')} {
        }
    }
}

.l-main {
    --main-radius: #{$border-radius-md};

    @media #{md("sm")} {
        --main-radius: #{$border-radius-lg};
    }

    overflow: hidden;
    z-index: 1;
    background-color: $color-light;
    border-radius:
        var(--main-radius-tl, var(--main-radius))
        var(--main-radius-tr, var(--main-radius))
        var(--main-radius-br, var(--main-radius))
        var(--main-radius-bl, var(--main-radius))
    ;
}

</style>
