<template>
    <div class="c-pagination">

        <ul v-if="totalPages > 1" class="c-pagination__list">
            <!-- Prev -->
            <li>
                <btn
                    label="Page Précédente"
                    :is-shaped="true"
                    shape-dir="left"
                    class="c-pagination__page -prev"
                    :disabled="!hasPrev"
                    @click.native="changePage(prevPage)"
                />
            </li>
            <!-- Pages -->
            <li class="c-pagination__btn-wrapper">
                <span v-if="hasFirst">
                    <btn
                        label="1"
                        class="c-pagination__page"
                        @click.native="changePage(1)"
                    />
                </span>
                <!-- Dots -->
                <span class="c-pagination__dots" v-show="hasFirst && hasDots">...</span>
                <!-- Pages -->
                <span
                    v-for="(page, i) in pages"
                    :key="`page-${i + Math.random() * 100}`"
                >
                    <btn
                        :label="page"
                        class="c-pagination__page"
                        :class="{'-active': current == page}"
                        @click.native="changePage(page)"
                    />
                </span>
                <!-- Dots -->
                <span class="c-pagination__dots" v-show="hasLast && hasDots(true)">...</span>
                <span v-if="hasLast">
                    <btn
                        :label="totalPages"
                        class="c-pagination__page"
                        @click.native="changePage(totalPages)"
                    />
                </span>
            </li>
            <!-- Next -->
            <li>
                <btn
                    label="Page Suivante"
                    :is-shaped="true"
                    class="c-pagination__page -next"
                    :disabled="!hasNext"
                    @click.native="changePage(nextPage)"
                />
            </li>
        </ul>

        <div v-if="hasGoToPage">
            <div>
                <span id="text-before-input">
                    {{ textBeforeInput }}
                </span>
            </div>
            <div>
                <input v-model.number="input" type="number" min="0" :max="totalPages">
            </div>
            <div @click.prevent="changePage(input)">
                <span id="text-after-input">
                    {{ textAfterInput }}
                </span>
            </div>
        </div>

        <btn
            v-if="hasDisplayAll && !allDisplayed && totalPages > 1"
            label="Afficher tout..."
            class="c-pagination__page"
            @click.native="displayAll"
        />
  </div>
</template>

<script>
import Btn from 'components/Btn'

export default {
    name: 'Pagination',
    components: {
        Btn
    },
    props: {
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 12
        },
        pageRange: {
            type: Number,
            default: 2
        },
        textBeforeInput: {
            type: String,
            default: 'Aller à la page'
        },
        textAfterInput: {
            type: String,
            default: 'Aller'
        },
        hasDisplayAll: {
            type: Boolean,
            default: false
        },
        hasGoToPage: {
            type: Boolean,
            default: false
        },
        isBlocked: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        input: 1,
        current: 1,
        pageChanging: false,
        allDisplayed: false
    }),
    created() {

        if (this.currentPage)
            this.current = this.currentPage

        if (this.$route.query.page > this.totalPages)
            this.changePage(this.totalPages)

        if (this.$route.query.page == 'all')
            this.$router.replace({ query: { page: undefined } })
    },
    methods: {
        changePage(page) {

            if (this.isBlocked || this.pageChanging)
                return null

            if ( (page > 0 && page <= this.totalPages) && (page !== this.current) ) {
              this.$emit('block', true)
              this.current = page === 'all' ? 1 : parseInt(page)
              this.pageChanging = true
              this.$emit('pageChange', page)
              this.$router.replace({ query: { page } })
            }
        },
        displayAll() {

            if (this.isBlocked || this.pageChanging || this.allDisplayed)
                return null

            this.pageChanging = true
            this.allDisplayed = true
            this.current = 1
            this.$emit('displayAll')
            this.$router.replace({ query: { page: 'all' } })
        },
        hasDots(fromEnd) {
            const realRange = this.pageRange * 2 + 1

            if (fromEnd)
                return realRange < this.totalPages// && this.current <= realRange

            return realRange < this.totalPages && this.current >= realRange
        },
        reset() {

            if (this.isBlocked || this.pageChanging)
                return null

            this.allDisplayed = false

            if (this.$route.query.page) {
                this.current = 1
                this.pageChanging = true
                this.$router.replace({ query: { page: undefined } })
            }
        }
    },
    computed: {
        hasFirst() {
            return this.rangeStart !== 1
        },
        hasLast() {
            return this.rangeEnd < this.totalPages
        },
        hasPrev() {
            return this.current > 1
        },
        hasNext() {
            return this.current < this.totalPages
        },
        pages() {
            let pages = []

            for (var i = this.rangeStart; i <= this.rangeEnd; i++) {
              pages.push(i)
            }

            return pages
        },
        rangeStart() {
            const start = this.current - this.pageRange

            return (start > 0) ? start : 1
        },
        rangeEnd() {
            const end = this.current + this.pageRange

            return (end < this.totalPages) ? end : this.totalPages
        },
        totalPages() {
            return Math.ceil(this.total / this.perPage)
        },
        nextPage() {
            return this.current + 1
        },
        prevPage() {
            return this.current - 1
        }
    },
    watch: {
        $route() {
            this.pageChanging = false
        },
        current(value) {
            this.input = value
        }
    }
}
</script>
<style lang="scss">
.c-pagination {
    margin-top: var(--grid-gutter);

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
    }

    &__btn-wrapper {
        display: flex;
    }

    @media #{md('xs', 'max')} {
        &__list {
            height: 11rem;
            padding-top: 7rem;
        }

        &__btn-wrapper {
            position: absolute;
            top: 0;
        }
    }

    &__dots,
    &__page {
        display: inline-block;
        margin: 1rem;
        padding: 1rem;
        cursor: pointer;
    }

    &__page {

        &.-active {
            .o-bg-shape {
                --bg-color: var(--hover-color);
            }
        }

        &.-prev,
        &.-next {
            .o-bg-shape {
                --bg-color: var(--hover-color);
            }
        }

        &[disabled],
        &:disabled {
            color: lightgray;
            cursor: default;
        }
    }
}
</style>
