<template>
    <div v-if="data && data.accordions.length" class="b-accordions">
        <accordion
            v-for="(accordion, i) in data.accordions"
            :key="`b-accordions-${i}`"
            :title="accordion.heading"
            :openDefault="i === 0 && data.openFirst ? true : false"
        >
            <div class="t-cms" v-html="accordion.content" />
        </accordion>
    </div>
</template>

<script>
import Accordion from 'components/Accordion'

export default {
    name: 'BlockAccordions',
    components: {
        Accordion
    },
    props: {
        data: false
    }
};

</script>

<style lang="scss">
.b-accordions {
    .c-accordion + * {
        margin-top: var(--grid-gutter-half);
    }

    .c-accordion__buttons {
        margin-top: var(--grid-gutter-half);

        .c-btn__group {
            margin-top: 1em;
        }
    }
}
</style>
