<template>
    <div
        v-if="data"
        class="b-events | t-content"
    >
        <div class=" b-events__top">
            <h2 class="t-t4">{{ data.title }}</h2>
        </div>

        <div class="b-events__grid">
            <card-grid
                :cards="data.events"
                card-model='horizontal'
            >
                <template #beforeCardContent="event">
                    <div
                        class="-event-before-content"
                        :class="{'-cols-2': event.date && event.participants}"
                    >
                        <div
                            v-if="event.participants"
                            class="-event-participants"
                        >
                            <icon icon="person-logo" style="color: var(--color-blue)"/>
                            <tag
                                :label="excerpt(event.participants, 40)"
                                text-color="blue"
                            />
                        </div>
                        <span
                            v-if="event.date"
                            class="-event-date"
                        >{{ formatDate(event.date, { month: 'long' }) }}
                        </span>
                    </div>
                </template>

                <template #afterCardContent="event">
                    <div
                        v-if="event.description"
                        class="-event-description"
                        v-html="excerpt(event.description, 150)"
                    > </div>
                </template>

                <template #cardLink="event">
                    <!-- TMP  -->
                    <btn
                        tag="a"
                        :url="event.link"
                        icon-after="arrow-right"
                        class="c-card__link"
                        label="Consulter"
                    />
                </template>
            </card-grid>
        </div>
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import Btn from 'components/Btn.vue'
import CardGrid from '../components/CardGrid.vue'
import Tag from '../objects/Tag.vue'
import Icon from '../objects/Icon.vue'

import { formatDate, excerpt } from 'src/utils'

export default {
    components: { Card, CardGrid, Btn, Tag, Icon },
    name: 'BlockEvents',
    props: {
        data: null
    },
    methods: {
        excerpt,
        formatDate
    },
    mounted() {
    }

}
</script>

<style lang="scss">
.b-events {
    --content-gap: 3rem;

    &__grid {

        .c-card-grid.-no-slider .c-card-grid__grid {
            grid-row-gap: 4rem;
        }

        @media #{md("xs")} {
            .c-card {
                grid-template-columns: 1fr 1fr;
                &__asset {
                    align-self: flex-start;
                }
            }
        }

        @media #{md("sm")} {
            .c-card {
                grid-template-columns: 1fr;
            }

            .c-card-grid__grid {
                grid-template-columns: 1fr !important;
            }
        }

        @media #{md("md")} {
            .c-card {
                grid-template-columns: 1fr 1fr !important;
            }
        }
        @media #{md("lg")} {
            .c-card {
                &__asset {
                    align-self: center;
                }
            }
        }
    }

    .-event {
        &-before-content {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
        }

        &-participants {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &-date {
            font-size: fs('small');
        }
        @media #{md('lg')} {
            &-before-content.-cols-2 {
                grid-template-columns: 1fr 1fr;
                justify-content: space-between;
                align-items: center;

                & > *:last-child {
                    justify-self: end;
                }
            }
        }
    }

    .o-tag {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
}

</style>
