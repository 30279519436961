<template>
    <div
        v-if="!!data && data.files.length"
        class="b-resources | t-content"
    >
        <div v-if="data.title" class="b-resources__top">
            <h2 class="t-t4">
                {{ data.title || 'Ressources' }}
            </h2>
        </div>
        <div
            class="b-resources__content"
            :class="{
                '-has-2-children': data.text && data.files,
                '-documents-on-left': data.documentsOnLeft
            }"
        >
            <div class="b-resources__text | t-cms" v-html="data.text || ''"></div>
            <div class="b-resources__files" v-if="data.files">
                <a
                    v-for="(file, index) in data.files"
                    :key="`file-${index}`"
                    :href="file.document ? file.document[0].url : null"
                    class="c-btn-download"
                    rel="noopener"
                    download
                >
                    <span>{{ file.title }}</span>
                    <span class="c-btn-download__label">
                        <span>Télécharger</span>
                        <icon icon="arrow-right"/>
                    </span>
                    <background-shape bg-color="light"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from '../components/Btn.vue'
import BackgroundShape from '../objects/BackgroundShape.vue'
import Icon from '../objects/Icon.vue'
export default {
    components: {
        Btn,
        Icon,
        BackgroundShape
    },
    props: {
        data: null
    },
}
</script>

<style lang="scss">
.b-resources {
    padding: var(--grid-gutter);
    background: var(--color-beige);
    border-radius: $border-radius-md;

    &__content {
        display: grid;
        gap: 4rem;

        @media #{md('lg')} {

            &.-has-2-children {
                grid-template-columns: 1fr 1fr;

                &.-documents-on-left {
                    .b-resources__files {
                        order: -1;
                    }
                }
            }
        }

        .b-resources__text {
            a {
                display: block;
                width: fit-content;
                text-decoration: underline;
                color: $color-orange;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }

        .b-resources__files {
            display: grid;
            grid-template-columns: column;
            gap: 1.5rem;
            width: 100%;
            height: fit-content;
        }

        .c-btn-download {
            z-index: 1;
            padding: 2rem;
            display: grid;
            gap: 1.5rem;
            justify-content: space-between;
            transition: filter 0.5s $out-sine;

            .o-bg-shape {
                --border-radius: #{$border-radius-md};
            }

            @media #{md('xs')} {
                grid-auto-flow: column;
            }

            &__label {
                font-weight: 600;
                display: flex;
                align-items: center;
                white-space: pre;
                width: fit-content;
                padding: 1rem;
                margin-left: -1rem;
                border-radius: $border-radius-sm;
                transition: background .5s ease;

                span {
                    margin-right: 1rem;
                }

                .o-icon {
                    transition: transform .2s ease;
                    transform-origin: 50% 50%;
                    transform: translateY(.3rem) rotate(45deg);
                }

                @media #{md('xs')} {
                    padding-left: 1rem;
                    height: fit-content;
                    align-self: center;
                }
            }

            &:hover,
            &:focus {
                filter: drop-shadow(0 0 0.5rem rgba($color-dark, 0.1));
                .o-icon {
                    transform: translateY(.3rem) rotate(90deg);
                }

                .c-btn-download__label {
                    background: var(--color-beige);
                }
            }
        }
    }
}
</style>
