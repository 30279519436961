<template>
    <div
        v-if="anchors.length"
        ref="anchorsList"
        class="c-anchors-list"
        :class="{
            '-is-mobile': isMobile,
            '-is-open': isOpen
        }"
    >
        <btn
            v-if="isMobile"
            :label="activeAnchorLabel"
            icon-after="arrow-right"
            class="c-btn-dropdown"
            @click.native.stop="toggleDropdown"
        />

        <ul class="c-anchors-list__wrapper">
            <li
                v-for="(anchor, i) in anchors"
                :key="`anchor-${i}`"
                class="anchor-link"
                :class="{ '-is-active': activeAnchor == anchor.id }"
            >
                <button
                    @click.stop="scrollTo(anchor.id)"
                >
                    {{ anchor.title }}
                </button>

                <background-shape
                    :is-shaped="true"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import Btn from 'components/Btn.vue'
import BackgroundShape from 'objects/BackgroundShape.vue'
import { gsap, ScrollTrigger } from 'gsap/all'
import Variables from 'mixins/variables'
import { mapGetters } from 'vuex'

export default {
    name: 'AnchorsList',
    components: {
        Btn,
        BackgroundShape,
    },
    props: {
        anchors: {
            type: Array,
            default: () => []
        },
    },
    mixins: [Variables],
    data: () => ({
        activeAnchor: null,
        anchorsList: null,
        isMobile: false,
        isOpen: false,
        activeAnchorLabel: ''
    }),
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading'
        })
    },
    mounted() {

        this.getActiveAnchor()
        this.init()
            .then(this.update)

        window.addEventListener('resizeEnd', this.update)
        window.addEventListener('click', this.closePanel)

        window.addEventListener('scroll', this.toggleTriggerState)
    },
    methods: {
        closePanel() {
            if(this.isOpen) {
                this.isOpen = false
            }
        },
        getActiveAnchor() {
            if(!this.$route.hash)
                this.activeAnchor = [...this.anchors][0].id
        },
        getLeftSpace() {
            if (!this.anchorsList) return

            let container = document.querySelector('.l-main.container')
            let leftSpace = this.anchorsList.getBoundingClientRect().left - container.getBoundingClientRect().left

            this.anchorsList.style.setProperty('--space-left', leftSpace + 'px')
        },
        async init() {
            setTimeout(() => {
                const anchorsList = this.$refs.anchorsList,
                parent = this.$parent.$el
                this.anchorsList =  anchorsList

                let topGap = this.W.smallScreen ? '30px' : '80px'

                this.trigger = ScrollTrigger.create({
                    trigger: parent,
                    pin: anchorsList,
                    pinSpacing: false,
                    invalidateOnRefresh: true,
                    start: `top-=${topGap} top`,
                    // endTrigger: "#footer",
                    // markers: true,
                    end: 'bottom 80%',
                    onUpdate: self => {
                        let positions = [],
                            start = self.start

                        // Number of px scrolled since the trigger started
                        this.scrolled = window.scrollY - start

                        const els = this.anchors.map((anchor) => document.getElementById(anchor.id))
                        els.forEach((el, i, __self) => {
                            positions[i] = i == 0
                                ? 0
                                : positions[i - 1] + __self[i - 1].offsetHeight

                            if (this.scrolled >= positions[i] * 0.9) {
                                this.activeAnchor = this.anchors[i].id
                            }
                        })
                    }
                })

                this.trigger.refresh()
            }, 500)
        },
        toggleTriggerState() {
            if (!this.trigger) return

            const lastAnchorId = this.anchors[this.anchors.length - 1].id
            this.lastAnchor = document.getElementById(lastAnchorId)
            const lastAnchorBounding = this.lastAnchor.getBoundingClientRect()

            if (lastAnchorBounding.top < lastAnchorBounding.height * .02) {
                //this.trigger.disable(true, true)
                gsap.to('.c-anchors-list', {
                    opacity:0,
                    y: -100,
                    duration: 0.2,
                    ease: 'linear'
                })
            }
            else {
                //this.trigger.enable()
                gsap.to('.c-anchors-list', {
                    opacity:1,
                    y: 0,
                    duration: 0.2,
                    ease: 'linear'
                })
            }
        },

        scrollTo(id) {

            let hash = this.$route.hash.replace('#', ''),
                el = document.getElementById(id)

            el.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })

            if (hash != el.id) {
                this.activeAnchor = id
                window.setTimeout(() => {
                    this.$router.replace({ hash: id })
                    this.$nextTick(this.closePanel)
                }, 500)
            }

        },
        toggleDropdown() {
            this.isOpen = !this.isOpen
        },
        update() {
            this.isMobile = this.W.smallScreen
            this.getLeftSpace()
        },
        resizeOnLoad() {
            window.dispatchEvent(new Event('resize'))
            // console.log('event dispatched');
        }
    },
    destroyed() {
        window.removeEventListener('resizeEnd', this.update)
        window.removeEventListener('click', this.closePanel)
        window.removeEventListener('scroll', this.toggleTriggerState)
        this.trigger.kill()
    },
    watch: {
        activeAnchor: {
            handler(activeId) {
                let activeLabel = this.anchors
                    .filter(({ id }) => activeId == id)

                if (!activeLabel.length)
                    return
                this.activeAnchorLabel = activeLabel[0].title
            },
            immediate: true
        },
        $route: {
            handler(route) {
                if(route.hash)
                    this.activeAnchor = route.hash.replace('#', '')
            },
            immediate: true
        },
        isLoading: {
            handler(loading) {
                if (!loading) {
                    // console.log('isLoading::');
                    setTimeout(() => this.resizeOnLoad(), 500)
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss">
.c-anchors-list {
    display: flex;
    flex-direction: column;
    z-index: 3;
    // background: var(--color-light);
    align-items: flex-start;
    justify-content: flex-start;

    .anchor-link {
        @include reset-list;
        display: inline-block;
        margin-bottom: 1.5rem;

        button {
            padding: 1rem;
            text-align: left;
        }

        .o-bg-shape {
            --bg-color: transparent;
            transition: background-color 0.5s $out-sine, opacity 0.5s $out-sine;

            .svg-shape {
                transition: fill 0.5s $out-sine;
            }
        }

        &:before {
            position: absolute;
            content: '';
            top:0;
            left: calc(-1 * var(--space-left));
            width: calc(1px + var(--space-left));
            height: 100%;
            transition: background-color 0.5s $out-sine, opacity 0.5s $out-sine;
        }

        &.-is-active,
        &:hover {
            --bg-color: var(--color-yellow-light);
            .o-bg-shape {
                --bg-color: var(--color-yellow-light);
            }

            &:before {
                background-color: var(--bg-color);
            }
        }

        &:not(.-is-active):hover {

            .o-bg-shape {
                opacity: .3;
            }

            &:before {
                opacity: .3;
            }
        }
    }

    &__dropdown {
        z-index: 50;
    }

    .c-anchors-list__wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
    }

    @media #{md('sm', 'max')} {
        //
        --anchor-width: calc(100% - 10rem);
        width: var(--anchor-width);

        .c-anchors-list__wrapper {
            z-index: -10;
            width: 100%;
            margin-top: 5rem;
            padding-top: 1.5rem;
            background: $color-light;
            border: 1px solid $color-beige;
            border-radius: $border-radius-xs;

            transform: scaleY(0);
            transform-origin: top;
            opacity: 0;
            pointer-events: none;
            transition: transform 0.3s ease, opacity 0.2s ease;
        }

        &.-is-open {

            .c-anchors-list__wrapper {
                transform: scaleY(1);
                opacity: 1;
                z-index: 400;
                pointer-events: all;
            }

            .svg-arrow-right {
                transform: rotate(90deg);
            }
        }

        .svg-arrow-right {
            transition: 0.3s ease transform;
        }

        .anchor-link {

            &:hover:before,
            &.-is-active:before {
                background-color: transparent;
            }

            .o-bg-shape {
                width: calc(100% - 2rem);
            }
        }

        .c-btn-dropdown {
            z-index: 500;
            width: 100%;
            background: $color-light;
            justify-content: space-between;
            border: 1px solid $color-beige;

            .o-bg-shape {
                --bg-color: $color-light !important;
                --hover-color: var(--bg-color);
                z-index: 49 ;
            }
            .c-btn__label {
                text-align: left;
            }
            @media #{md('sm')} {
                display: none;
            }
        }
    }
}
</style>
