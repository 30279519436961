<template>
    <div
        v-if="data"
        class="b-content"
    >
        <div
            ref="content"
            class="b-content__inner | t-cms"
            :class="{'-columns': !data.forceOneColumn}"
            v-html="data.content"
        />
    </div>
</template>

<script>

import { ScrollTrigger } from 'gsap/all'

export default {
    name: "BlockContent",
    props: {
        data: false
    },
    methods: {
        async refreshTrigger() {
            const content = await this.$refs.content || this.$el.querySelector('.b-content__inner')
            if (content) {
                const images = content.querySelectorAll('img')
                if (images.length) {
                    images.forEach(img => {
                        img.addEventListener('load', () => {
                            ScrollTrigger.refresh()
                        } )
                    })
                }
            }
        }
    },
    watch:{
        data: {
            handler: 'refreshTrigger',
            immediate: true
        }
    }
}
</script>

<style lang="scss">
.b-content {

    .b-content__inner {

        &.-columns {

            @media #{md("md")} {
                columns: 2;
                column-gap: var(--grid-gutter);

                figure, picture, blockquote, p, li {
                    break-inside: avoid;
                }
            }
        }
    }
}
</style>
