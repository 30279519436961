<template>
    <div v-if="data" class="b-text-image">
        <asset :asset="data.image" :shape="data.assetShape" :class="imgPosition"></asset>
        <div v-html="data.text" class="t-cms"></div>
    </div>
</template>

<script>
import Asset from '../objects/Asset.vue'
export default {
    components: { Asset },
    name: 'BlockTextImage',
    props: {
        data: null
    },
    computed: {
        imgPosition () {
            return this.data.imgRight ? '-right' : '-left'
        }
    }
}
</script>

<style lang="scss">
    .b-text-image {
        display: grid;
        gap: var(--grid-gutter);
        align-items: center;
        max-width: 80rem;
        margin: auto;

        @media #{md('md')} {
            grid-template-columns: 1fr 1fr;
        }

        .o-asset.-right {
            order: 2;
        }
    }
</style>
