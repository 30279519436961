<template>
    <card
        class="-project"
        :element="element"
        :model="model"
        :color="element.colour"
        link-label="Consulter"
        asset-shape="losange"
    >
        <template #beforeAsset v-if="displayTagAfterAsset">
            <tag
                :label="tagLabel"
                :text-color="element.state && element.colour ? element.colour : null"
            />
        </template>
        <template #beforeContent>
            <div class="c-card__before-content">
                <tag
                    v-if="!displayTagAfterAsset"
                    :label="tagLabel"
                    :outline="element.state ? true : false"
                    :text-color="element.state && element.colour ? element.colour : null"
                />
            </div>
        </template>

        <template #content>
            <div class="t-content">
                <h1 class="t-t5 c-card__title">{{ element.title }}</h1>
                <p v-if="elementDescription">{{ elementDescription }}</p>
            </div>
        </template>

    </card>
</template>

<script>
import Asset from 'objects/Asset'
import Tag from 'objects/Tag'
import Card from 'components/Card'

import { excerpt } from 'src/utils'

export default {
    name: 'CardProject',
    components: {
        Card,
        Tag,
        Asset,
    },
    props: {
        element: {
            type: Object,
            required: true
        },
        displayTagAfterAsset: Boolean,
        model: {
            type: String,
            default: 'enhance',
            validator: (model) => {
                return ['horizontal', 'enhance', 'standard'].indexOf(model) !== -1
            }
        }
    },
    computed: {
        tagLabel() {
            const translation = {
                ongoing: 'Projet en cours',
                done: 'Projet terminé',
                upcoming: 'Projet à venir'
            }

            if (this.element.state) {
                return translation[this.element.state]
            }
        },
        elementDescription() {
            return excerpt(this.element.description, 144)
        }
    }
};

</script>

<style lang="scss">

.c-card.-project {}

</style>
