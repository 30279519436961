<template>
    <div
        v-if="featuredEntries"
        class="b-featured-entries | t-content"
    >
        <div v-if="data.title" class="b-featured-entries__top">
            <h2 class="t-t4">{{ data.title }}</h2>
        </div>
        <div class="b-featured-entries__grid | t-content">
            <div
                v-for="(card, i) in featuredEntries"
                :key="`card-${i}`"
                :class="`-entry -entry-${i}`"
                ref="card"
            >
                <component
                    :is="card.cardName"
                    :element='card'
                    :displayTagAfterAsset="card.section == 'campaign' || card.section == 'project'"
                    model="horizontal"
                >
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '../components/Card'
import CardProject from '../components/CardProject'
import CardCampaign from '../components/CardCampaign'
export default {
    components: { Card, CardProject, CardCampaign },
    name: 'BlockFeaturedEntries',
    props: {
        data: null,
    },
    data: () => ({
        featuredEntries: null,
    }),
    created() {

        if (this.data && this.data.entries.length) {

            // Increase loader value
            this.$store.dispatch('loader/startLoad')

            const entries = this.data.entries

            const dispatches = entries.map(entry =>  {

                if (entry.section == 'page') {
                    return this.$store.dispatch('pages/loadEntry', {handle: 'page', slug: entry.slug})
                } else {
                    return this.$store.dispatch(`${entry.section}/loadElement`, entry.slug)
                }
            })

            Promise.all(dispatches).then(featuredEntries => {
                this.setCardsComponentName(featuredEntries)
                this.featuredEntries = featuredEntries

                // Decrease loader value
                this.$store.dispatch('loader/endLoad')
            })
        }
    },
    mounted() {
    },
    methods: {
        setCardsComponentName(cards) {

            const newCards = [...cards];

            return newCards.forEach(entry => {

                switch (entry.section) {
                    case 'campaign':
                        entry.cardName = 'card-campaign'
                        //entry.displayTagAfterAsset = true
                        break
                    case 'project':
                        entry.cardName = 'card-project'
                        //entry.displayTagAfterAsset = true
                        break
                    default:
                        entry.cardName = 'card'
                        entry.Tag = entry.category
                }
            })
        },
    }

}
</script>

<style lang="scss">
.b-featured-entries {
    --content-gap: 3rem;

    &__grid {
        --content-gap: 4rem;
    }

    .o-tag {
        white-space: nowrap;
    }

    .c-card {
        --content-gap: 2rem;

        @media #{md('sm')} {
            grid-template-columns: 1fr;
        }
        @media #{md('md')} {
            grid-template-columns: 1fr 1fr;
        }

        &__asset {
            align-self: start;
        }

        &__content {
            .c-btn.-tag {
                padding-left: 0;
            }
        }
    }
}
</style>
