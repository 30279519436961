<template>
    <div v-if="data" class="b-highlight">
        <highlight-text
            :text="data.text"
            :highlight-bg="highlightBg || 'yellow-light'"
        />
    </div>
</template>

<script>
import HighlightText from '../components/HighlightText.vue'
export default {
    name: 'BlockHighlight',
    components: { HighlightText },
    props: {
        data: String | Number,
        tag: String,
        options:  Object,
        highlightBg: String
    },
}
</script>

<style lang="scss">

%responsive-type {
    @include responsive-type(
        $min-font: 25,
        $max-font: 60,
        $min-width: 480,
        $max-width: 1440
    );
}
.b-highlight {
    @extend %responsive-type;
}
</style>