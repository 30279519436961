<template>
    <div
        v-if="image"
        class="b-image"
        :class="{'-zoom': zoom}"
    >
        <figure
            @click="zoomIndex = 0"
        >
            <asset
                :asset="image"
                :rounded="true"
            />
            <figcaption
                v-if="data.displayCaption"
                v-html="image.title"
            />
        </figure>
        <lightbox
            v-if="zoom"
            ref="zoom"
            :items="[image]"
            :index="zoomIndex"
            @close="zoomIndex = null"
        />
    </div>
</template>

<script>
import Asset from 'objects/Asset'
import Lightbox from 'components/Lightbox'

export default {
    name: 'BlockImage',
    components: {
        Asset,
        Lightbox
    },
    data: () => ({
        zoomIndex: null
    }),
    props: {
        data: false,
        zoom: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        image() {
            return this.data.image.length ? this.data.image[0] : null
        }
    },
    watch: {
        zoomIndex($v) {
            if (this.$refs.zoom)
                this.$refs.zoom.index = $v
        }
    }
}
</script>

<style lang="scss">
    .b-image {
        &.-zoom {
            cursor: zoom-in;
        }

        figcaption {
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: fs('small');
            text-align: center;
        }
    }
</style>
