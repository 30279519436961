<template>
<div class="p-bem">
    <app-section class="p-bem__section">
        <highlight-text
            tag="h1"
            className="t-t1"
            text="Vers un <strong>accès</strong> à un reseau de <strong>transport</strong> <strong>viable</strong> et durable"
            :highlights="['accès', 'transport', 'viable']"
            highlight-bg="blue-light"
        />

        <br />
        <br />
        <highlight-text
            tag="h2"
            className="t-t2"
            text="Vers des modes de deplacements durables à son échelle"
            :highlights="['deplacements', 'durables']"
            highlight-bg="yellow"
            :options="{ inDelay: 0.5 }"
        />
    </app-section>

    <app-section class="p-bem__section typo">
        <h2>TYPOGRAPHIE</h2>

        <div><code>.t-t1</code><h1 class="t-t1">Heading 1</h1></div>
        <div><code>.t-t2</code><h2 class="t-t2">Heading 2</h2></div>
        <div><code>.t-t3</code><h3 class="t-t3">Heading 3</h3></div>
        <div><code>.t-t4</code><h4 class="t-t4">Heading 4</h4></div>
        <div><code>.t-t5</code><h4 class="t-t5">Heading 5</h4></div>
        <div><code>.t-t6</code><h4 class="t-t6">Heading 6</h4></div>
        <div><code>.t-t7</code><h4 class="t-t7">Heading 7</h4></div>
        <div><code>.t-t8</code><h4 class="t-t8">Heading 8</h4></div>
    </app-section>

    <app-section class="p-bem__section buttons">
        <h2>BUTTONS</h2>
        
        <h3>Button simple</h3>
        <div class="btns">
            <btn
                label="Bouton transparent"
            />
            <btn
                label="Bouton beige"
                bg-color="beige"
            />
            <btn
                label="Bouton red"
                bg-color="red"
            />
            <btn
                label="Bouton blue"
                bg-color="blue"
            />

            <btn
                label="Bouton orange"
                bg-color="orange"
            />
            <btn
                label="Bouton yelow"
                bg-color="yellow"
            />
        </div>
        <h3>Button avec icone</h3>
        <div class="btns">
            <btn
                label="Bouton transparent"
                icon-after="arrow-right"
            />
            <btn
                label="Bouton beige"
                bg-color="beige"
                icon-after="arrow-right"
            />
            <btn
                label="Bouton red"
                bg-color="red"
                icon-after="arrow-right"
            />
            <btn 
                icon-before="twitter"
                bg-color="blue-light"
                label="twitter"
            />
            <btn 
                icon-before="youtube"
                bg-color="red-light"
                label="youtube"
            />
            
        </div>
        <h3>Button avec shape</h3>
        <div class="btns">
            <btn
                label="Bouton red"
                bg-color="red"
                :is-shaped="true"
            />
            <btn
                label="Bouton red-light"
                bg-color="red-light"
                :is-shaped="true"
            />
            <btn
                label="Bouton blue"
                bg-color="blue"
                :is-shaped="true"
            />
            <btn
                label="Bouton blue-light"
                bg-color="blue-light"
                :is-shaped="true"
            />
            <btn
                label="Bouton orange"
                bg-color="orange"
                :is-shaped="true"
                shape-dir="left"
            />
            <btn
                label="Bouton yellow"
                bg-color="yellow"
                :is-shaped="true"
                shape-dir="left"
            />
        </div>

        <h3>TAGS / BADGES</h3>
        <div class="tags">
            <tag 
                label="commentaire"
                text-color="blue"
                :outline="true"
            />
            <tag 
                label="commentaire"
                text-color="red"
            />
            <tag 
                label="commentaire"
                text-color="yellow"
                bg-color="light"

            />
        </div>

        <h3>ANCHORS / ARROW-SHAPE</h3>
        <div class="anchors">
            <div
                class="test-bg"
            >
                <span>Vision</span>
                <background-shape :is-shaped="true" />    
            </div>
        </div>
    </app-section>

    <app-section class="p-bem__section">
        <h3>IMAGES / ASSETS</h3>
        <div class="images">
            <asset
                class="asset-shape-test"
                shape="pentagon-down"
                :asset="asset"
            />
            <asset
                class="asset-shape-test"
                shape="hexagon"
                :asset="asset"
            />
            <asset
                class="asset-shape-test"
                shape="triangle-down"
                :asset="asset"
            />
            <asset
                class="asset-shape-test"
                shape="circle"
                :asset="asset"
            />
            <asset
                class="asset-shape-test"
                shape="square"
                :asset="asset"
            />
        </div>
    </app-section>

    <app-section>
        <grid-content :sticky="true" class="test-sticky">
            <template #left>
                <anchors-list
                    :anchors="anchors"
                />
            </template>

            <template #right>
                <div class="test__page-content">
                    <div
                        v-for="(anchor, i) in anchors"
                        :key="i"
                        :id="anchor.id"
                    >
                        <h3>{{ anchor.title }}</h3>
                        <div v-html="anchor.content"></div>
                    </div>
                </div>
            </template>
        </grid-content>
    </app-section>
    <app-section>
        <tab-list
            :tabs="[
                {title: 'Accompagnement et service-conseil', uri: '/services/accompagnement-et-service-conseil'},
                {title: 'Accompagnement conseil', uri: '/services/accompagnement-et-service-conseil'},
                {title: 'Accompagnement', uri: '/services/accompagnement-et-service-conseil'},
            ]"
        />
    </app-section>
</div>
</template>

<script>
import { mapState } from 'vuex'

import AppSection from 'layout/AppSection.vue'
import Btn from 'components/Btn.vue'
import AnchorsList from 'components/AnchorsList.vue'
import Tag from 'objects/Tag.vue'
import HighlightText from 'components/HighlightText.vue'
import BackgroundShape from 'objects/BackgroundShape.vue'
import Icon from 'objects/Icon.vue'
import Asset from 'objects/Asset.vue'
import GridContent from 'layout/GridContent.vue'
import TabList from 'layout/TabList.vue'

export default {
    name: 'Bem',
    components: { 
        Btn,
        Tag,
        Icon,
        AppSection,
        HighlightText,
        BackgroundShape,
        Asset,
        AnchorsList,
        GridContent,
        TabList,
    },
    data: () => ({
        asset:    {
            height: "320",
            width: "260",
            url: require('../../assets/img/pieton.jpg')
        },
        anchors: [
            {title: 'Anchor 1', id: 'anchor1', uri: '#Anchor1', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
            {title: 'Anchor 2', id: 'anchor2', uri: '#Anchor2', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
            {title: 'Anchor 3', id: 'anchor3', uri: '#Anchor3', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
            {title: 'Anchor 4', id: 'anchor4', uri: '#Anchor4', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
            {title: 'Anchor 5', id: 'anchor5', uri: '#Anchor5', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
            {title: 'Anchor 6', id: 'anchor6', uri: '#Anchor6', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
            {title: 'Anchor 7', id: 'anchor7', uri: '#Anchor7', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae distinctio est nobis? Earum non eum voluptatum necessitatibus vero repellendus veniam, voluptate impedit blanditiis doloribus consequuntur porro amet saepe dolorum adipisci animi vel nulla temporibus numquam ut pariatur. Labore, tempore! Amet vero quis, veritatis cupiditate velit porro aliquam provident? Autem, illo.'},
        ]
    }),
    computed: {
        ...mapState({
            global: state => state.global
        })
    },
    mounted() {

    },
}
</script>

<style lang="scss">
.p-bem {
    code {
        background: $color-beige;
        display: inline-block;
        padding: 0.5rem;
    }
    h3:not(.t-t3) { 
        border-bottom: solid 1px;
        display: inline-block;
        margin-top: 3rem;
    }

    &__section {
        padding: 2rem;
        .l-section__content > * + * {
            margin: var(--grid-gutter) 1rem;
        }
    }

    .test-bg {
        --bg-color: var(--color-beige);
        --border-radius: #{$border-radius-xs};
        padding: 1em;
        font-weight: 600;
        font-size: fs('large');
        display: inline-block;
    }

    .btns, .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
    }

    .anchors > * {
        margin: 2rem 0;
    }

    .images {
        width: 300px;
    }

    .asset-shape-test {
        // width: 300px;
    }

    .test__page-content {
        display: flex;
        flex-direction: column;
        overflow: visible;
        > * {
            min-height: 80vh;
        }
    }
}
</style>