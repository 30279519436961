<template>
    <span
        class="o-bg-shape"
        :style="styles"
        :class="{
            'is-shaped': isShaped,
            '-left': shapeDir == 'left',
            '-right': shapeDir == 'right'
        }"
    >
        <svg
            v-if="isShaped"
            class="svg-shape" viewBox="0 0 16 37" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.46766 0.824016C2.3818 0.281044 1.19815 -0.00022512 -1.61732e-06 0L0 37L0.0853782 37C1.25727 36.9997 2.41552 36.7303 3.48224 36.2101C4.54897 35.6899 5.49944 34.9308 6.26977 33.9839L13.9756 24.5391C15.2629 22.9581 15.9808 20.9358 15.9996 18.8372C16.0185 16.7386 15.3371 14.7019 14.0785 13.0946L6.2851 3.1428C5.51522 2.15848 4.55352 1.36699 3.46766 0.824016Z" />
        </svg>
    </span>
</template>

<script>
import { COLORS } from 'src/constants'

export default {
    name: 'BackgroundShape',
    props: {
        isShaped: {
            type: Boolean,
            default: false
        },
        shapeDir: {
            type: String,
            default: 'right',
            validator: dir => {
                return ['left', 'right'].indexOf(dir) !== -1
            }
        },
        bgColor: {
            type: String,
            validator: color => COLORS.indexOf(color) !== -1
        }
    },
    computed: {
        styles() {
            if (this.bgColor) {
                return `--bg-color: var(--color-${this.bgColor})`
            }
        }
    }
}
</script>

<style lang="scss">
.o-bg-shape {

    background: var(--bg-color);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: var(--border-radius);
    transition: background-color .3s ease-in-out;

    &.is-shaped {

        &.-right {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .svg-shape {
                float:right;
                transform: translateX(calc(100% - 1px));
            }
        }

        &.-left {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            .svg-shape {
                float: left;
                transform: translateX(calc(-100% + 1px)) rotate(180deg);
            }
        }
    }

    .svg-shape {
        height: 100%;
        width: auto;
        fill: var(--bg-color);
        transition: fill 0.4s ease;
    }

    *:hover > &,
    *:focus > & {
        background: var(--hover-color, var(--bg-color));

        .svg-shape {
            fill: var(--hover-color, var(--bg-color));
        }
    }
}
</style>
