<template>
    <div class="b-stats" v-if="data.stats.length && data.stats[0].line1">
        <div class="b-stats__grid"
        :style="`--block-count: ${data.stats.length};`" >
            <div
                v-for="(stat, i) in data.stats"
                :key="`stats-${i}`"
                :class="`stats-block stats-block-${i}`"
                :style="`
                    --block-bg: var(--color-${blockBg[i]})
                `"
            >
                <div class="stats-block__content">
                    <span class="-block-number">{{ stat.line1 }}</span>
                    <span class="-block-title">{{ stat.line2 }}</span>
                    <shape
                        :shape="blockShapes[i]"
                        :outline="false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimText from 'objects/AnimText'
import Shape from '../objects/Shape.vue'

export default {
    name: 'HomeStats',
    components: {
        AnimText,
        Shape,
    },
    props: {
        data: null
    },
    beforeCreate() {
        this.blockShapes = ['losange', 'square', 'circle']
        this.blockBg = ['blue', 'yellow', 'orange']
    },
    computed: {
    },
    destroyed() {
    }
}
</script>

<style lang="scss">

%responsive-type {
    @include responsive-type(
        $min-font: 20,
        $max-font: 40,
        $min-width: 480,
        $max-width: 1440
    );
}

.b-stats {
    --block-size: min(25rem, 25vw);

    min-height: var(--block-size);

    &__grid {
        display: grid;
        gap: var(--grid-gutter);
        margin-left: calc(-1 * var(--grid-gutter));
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(var(--block-count), 1fr);
    }

    @media #{md('sm')} {
        --block-size: min(25rem, 15vw);

        &__grid {
            margin-left: 0;
        }
    }


    .o-icon  {
        display: inline;
    }
    .o-shape {
        color: var(--block-bg);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0;

        svg {
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    .stats-block {
        width: var(--block-size);
        height: var(--block-size);
        z-index: 1;

        &__content {
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .-block-number {
            // font-weight: 600;
            @include responsive-type(
                $min-font: 20,
                $max-font: 40,
                $min-width: 480,
                $max-width: 1440
            );
        }

        .-block-title {
            @include responsive-type(
                $min-font: 10,
                $max-font: 25,
                $min-width: 300,
                $max-width: 1440
            );
        }
    }

    &:after {
        @include pseudo-el($width: 130%, $height: 100%, $bg: #{$color-beige});
        position: absolute;
        top: 0;
        left: calc(0.5 * var(--block-size) - var(--grid-gutter));
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;

        @media #{md('sm')} {
            transform: translateX(calc(var(--grid-gutter)));
        }
    }
    @media #{md('xs')} {
        margin-left: 0;
    }
}
</style>
