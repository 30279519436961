<template>
    <router-link
        title="Accès transports viables"
        aria-label="Retour à l’accueil"
        to="/"
        class="o-logo"
        ref="logo"
    >
        <icon
            :icon="isMonochrome ? 'atv-logo-monochrome' : 'atv-logo'"
            title="Accès transports viables"
        />
    </router-link>
</template>

<script>
import Icon from 'objects/Icon'

export default {
    name: 'AppLogo',
    props: {
        className: {
            type: String,
            required: true
        },
        isMonochrome: Boolean
    },
    data: () => ({
        animation: null,
        logo: null
    }),
    components: { Icon },
    computed: {
        // The element targetted should be unique
        containerClass() {
            return this.className + '-' + this._uid
        }
    }
}
</script>
