<template>
    <span
        :class="className"
        ref="loader"
    >
        <div
            class="o-loader__inner"
        >
            <span ref="bg" class="o-loader__bg"></span>

            <div>
            </div>
        </div>
    </span>
</template>


<script>

import { mapState, mapGetters } from 'vuex'
import { gsap } from 'gsap/all'

const TEXTURE_ANIMATION_DURATION = 0.25

export default {
    name: 'Loader',
    data: () => ({
        loading: true,
    }),
    mounted() {
        this.$store.dispatch('loader/startLoad')

        this.bg = this.$refs.bg || document.querySelector('.o-loader__bg')
        this.loader = this.$refs.loader || document.querySelector('.o-loader')

        //this.textureAnimation.play()

        this.firstloadIn()
    },
    computed: {
        ...mapState({
            firstload: state => state.loader.firstload,
            override: state => state.loader.override
        }),

        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),

        className() {
            let classname = 'o-loader'

            if (this.firstload) {
                classname += ` -firstload`
            }

            if (this.override) {
                classname += ` is-hidden`
            }

            if (this.loading) {
                classname += ' is-loading'
            }

            return classname
        },
    },
    methods: {
        disableScroll() {
            //document.body.classList.add('-scroll-disabled')
        },
        enableScoll() {
            //document.body.classList.remove('-scroll-disabled')
        },
        firstloadIn() {

            this.disableScroll()
            gsap.timeline()
                .to(this.loader, {
                    yPercent: 0,
                    duration: .8,
                })
                .to(this.bg, {
                    yPercent: -100,
                    duration: .8,
                    ease: 'power3.in',
                    onStart: () => {
                    },
                    onComplete: () => {
                        this.$store.dispatch('loader/endLoad')
                    }
                })
        },

        firstloadOut() {

            gsap.timeline({
                onComplete: () => {
                    this.$store.dispatch('loader/firstLoaded')
                    this.loading = false
                    this.$emit('animateOut')
                }
            })
                .to(this.loader, {
                    duration: .8,
                    '--y3': '0',
                    '--y4': '0',
                    ease: 'power2.in',
                    onStart: () => {
                        this.enableScoll()
                        this.setScroll()
                    }
                })
        },
        animateIn() {

            this.loading = true

            this.$store.dispatch('loader/startLoad')

            gsap.timeline({
                onStart: () => {
                },
                onComplete: () => {
                    this.$store.dispatch('loader/endLoad')
                }
            })
            .set(this.loader, {
                '--y1': '100%',
                '--y2': '100%',
                '--y3': '100%',
                '--y4': '100%',
            })
            .to(this.loader, {
                duration: .8,
                '--y1': '0',
                '--y2': '0',
                ease: 'power2.in',
                onComplete: () => {
                    window.scrollTo(0, 0)
                    this.disableScroll()
                }
            })
            .to(this.loader, {
                duration: TEXTURE_ANIMATION_DURATION,
                ease: 'power2.out',
            })
        },
        animateOut() {


            gsap.timeline({
                onComplete: () => {
                    this.$emit('animateOut')
                    this.loading = false
                }
            })
            .to(this.loader, {
                '--y3': '0',
                '--y4': '0',
                duration: 0.8,
                ease: 'power3.in',
                onStart: () => {
                    this.enableScoll()
                    this.setScroll()
                },
            })
        },
        setScroll() {

            // Scroll to hash if exists
            const hash = this.$route.hash

            if (hash) {
                location.href = hash
                let el = document.querySelector(hash)

                if (el) {
                    el.scrollIntoView({
                        block: 'start',
                        inline: 'nearest',
                        behavior: 'smooth'
                    })
                } else {
                    window.scrollTo(0, 0)
                }

            } else {
                window.scrollTo(0, 0)
            }

        }
    },
    watch: {
        isLoading(loading) {

            if (this.override)
                return

            if(loading) {
                this.animateIn()
            } else if(this.firstload){
                this.firstloadOut()
            } else {
                this.animateOut()
            }
        }
    }
}

</script>

<style lang="scss">
body.-scroll-disabled {
    height: 100vh;
    overflow: hidden;
}

.o-loader {
    --y1: 0;
    --y2: 0;
    --y3: 100%;
    --y4: 100%;
    --clipPath: polygon(0 var(--y1), 100% var(--y2), 100% var(--y3), 0 var(--y4));

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50000;
    pointer-events: none;

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: z-index 0s 1s, left 0s 1s;
    background-color: $color-blue;
    clip-path: var(--clipPath);

    &.-firstload {
        // background-color: $color-blue-light;

        .o-loader__bg {
            background-color: $color-blue-light;
        }
    }

    &.is-loading {
        left: 0;
        transition: none;
    }

    &.is-hidden {
        display: none;
    }

    &__inner {
        width: 100%;
        height: 100%;

        > * {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

}

.o-loader__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-blue;
    transform-origin: 50% 100%;
    will-change: transform;
    clip-path: var(--clipPath);
}

</style>
