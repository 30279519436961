<template>
    <tag
        :is="tag"
        :href="url"
        :to="tag == 'router-link' && url ? url : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :target="tag == 'a' ? '_blank' : false"
        class="c-btn"
        :class="{
            '-focus': focus,
            '-large': size === 'large',
            '-small': size === 'small',
            '-has-shape': isShaped,
            '-has-shape--left': isShaped && shapeDir == 'left',
            '-has-shape--right': isShaped && shapeDir == 'right',
            '-has-icon': iconBefore || iconAfter,
        }"
    >
        <icon
            v-if="iconBefore !== null"
            class="c-btn__icon"
            :icon="iconBefore"
        />
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>

        <icon
            v-if="iconAfter !== null"
            class="c-btn__icon"
            :icon="iconAfter"
        />

        <background-shape
            class="c-btn__bg"
            :is-shaped="isShaped"
            :shape-dir="shapeDir"
        />

    </tag>
</template>

<script>
import Icon from 'objects/Icon';
import BackgroundShape from 'objects/BackgroundShape.vue';
import { COLORS } from 'src/constants'

export default {
    name: 'Btn',
    components: {
        Icon,
        BackgroundShape,
    },
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        url: {
            type: String,
            default: null,
        },
        label: {
            type: String|Number,
            default: 'Button Label',
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
        bgColor: {
            type: String,
            default: 'transparent',
            validator: color => COLORS.indexOf(color) !== -1
        },
        bgHoverColor: {
            type: String,
            validator: color => COLORS.indexOf(color) !== -1
        },
        textColor: {
            type: String,
            default: 'dark',
            validator: color => COLORS.indexOf(color) !== -1
        },
        isShaped: {
            type: Boolean,
            default: false
        },
        shapeDir: String,
        size: {
            type: String,
            default: 'regular'
        }
    },
    data: () => ({
        focus : false
    }),
    mounted() {
        this.setBackground()
        this.setHoverColor()
        this.setTextColor()
    },
    methods: {
        setTextColor() {
            this.$el.style.setProperty('--text-color', `var(--color-${this.textColor})`)
        },
        setBackground () {
            this.$el.style.setProperty('--bg-color', `var(--color-${this.bgColor})`)
        },
        setHoverColor() {
            let hoverColor = 'beige'

            if (this.bgHoverColor) {

                hoverColor = this.bgHoverColor

            } else {

                let bgColor = this.bgColor
                if (bgColor !== 'transparent' && bgColor !== 'beige') {

                    hoverColor = bgColor.split('-').includes('light')
                        ? bgColor.replace('-light', '')
                        : bgColor + '-light'

                } else if (bgColor == 'beige') {
                    hoverColor = 'beige-dark'
                }
            }

            this.$el.style.setProperty('--hover-color', `var(--color-${hoverColor})`)
        }
    }
}
</script>

<style lang="scss">

.c-btn {
    --border-radius: #{$border-radius-xs};
    --btn-text-color: var(--text-color);
    --btn-padding-x: 1rem;
    --btn-padding-y: 1rem;

    display: inline-flex;
    align-items: center;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    font-weight: 600;
    border-radius: var(--border-radius);
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    color: var(--btn-text-color);
    width: fit-content;

    &.-small {
        font-size: fs('small');
    }

    &.-large {
        font-size: fs('large');
    }


    &.-has-shape--left {
        margin-left: 1.6rem; // Shape width
        margin-right: 0;
    }
    &.-has-shape,
    &.-has-shape--right {
        margin-right: 1.6rem; // Shape width
    }

    &[target="_blank"] {

        .c-btn__icon.-arrow-right {
            transition: transform .2s ease;
            transform-origin: 75% 60%;
        }
        &:hover {

            .c-btn__icon.-arrow-right {
                transform: rotate(-45deg);
            }
        }
    }
    &[download] {

        .c-btn__icon.-arrow-right {
            transition: transform .2s ease;
            transform-origin: 75% 50%;
        }
        &:hover {

            .c-btn__icon.-arrow-right {
                transform: rotate(90deg);
            }
        }
    }
}

.c-btn__label {
    z-index: 1;
}

.c-btn__bg {
    z-index: 0;
    transition: background-color .3s ease-in-out;

    &.o-bg-shape .svg-shape {
        transition: fill .3s ease-in-out;
    }
}

.c-btn__icon {
    z-index: 1;

    & + *, /// icon-Before - icon-After
    * + & {
        padding-left: 1rem;
    }

    &.-play {
        --svg-width: 1rem;
    }
}

.c-btn__group {
    --btns-gap: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: var(--btns-gap);

    &.-vertical {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--btns-gap);

        > * {
            width: fit-content;
        }
    }
}

</style>
