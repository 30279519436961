<template>
    <app-section>
        <featured-entry
            v-if="featuredEntry"
            :entry="featuredEntry"
            card-type="campaign"
            before-asset-shape="square"
        />
        <app-elements
            v-if="isPageLoaded && featuredEntryId !== null"
            sectionHandle="campaign"
            cardType="campaign"
            cardModel="enhance"
            :options="{
                limit: 9,
                ids: [featuredEntryId],
                idsExclude: true
            }"
        />
    </app-section>
</template>

<script>
import AppElements from 'layout/AppElements'
import AppSection from 'layout/AppSection'
import FeaturedEntry from '../components/FeaturedEntry.vue';

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Campaigns',
    components: {
        AppElements,
        AppSection,
        FeaturedEntry,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        featuredEntry: null,
    }),
    computed: {
        isPageLoaded() {
            return this.page.id || false
        },
        featuredEntryId() {

            // Escape if page isn't properly loaded
            if (!this.isPageLoaded)
                return null

            return this.page.featuredEntry ? this.page.featuredEntry.id : 0
        }
    },
    watch: {
        featuredEntryId(id) {

            if (id && this.featuredEntry === null) {

                this.$store.dispatch('loader/startLoad')

                this.$store.dispatch('campaign/loadElement', this.page.featuredEntry.slug)
                    .then(campaign => {
                        this.featuredEntry = campaign
                        this.$store.dispatch('loader/endLoad')
                    })
            }
        }
    }
};

</script>

<style lang="scss">

.p-campaigns {
    .c-featured__entry {

        // Override the deco position
        .deco-wrapper {
            .deco-2 {
                border-bottom-left-radius: 0;
            }

            .deco-1 {
                top: 0;
                left: -250%;
            }
        }

        .o-shape {
            background: transparent;
            left: calc(-100% - var(--container-gutter));
        }
    }
}

</style>
