<template>
    <button
        class="c-btn-up"
        :class="{ 'is-visible': isVisible }"
        :style="`--label: '${label}'`"
        title="Retour vers le haut"
        aria-label="Retour vers le haut"
        @click="scrollTop"
    >
        <shape shape="pentagon-up" :outline="false" />
    </button>
</template>

<script>
import Shape from "objects/Shape.vue";
import Btn from "components/Btn.vue";

import Variables from "src/mixins/variables";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: "BtnUp",
    components: {
        Shape,
        Btn
    },
    mixins: [Variables],
    props: {
        label: String
    },
    data: () => ({
        isVisible: false
    }),
    mounted() {
        this.scrollTrigger = ScrollTrigger.create({
            trigger: "header",
            start: `top+=${this.W.h}`,
            onUpdate: self => {
                this.isVisible = self.progress >= 1;
            }
        });
    },
    methods: {
        scrollTop() {
            window.scrollTo(0, 0);
        }
    }
};
</script>

<style lang="scss">
.c-btn-up {
    --bg-color: var(--color-beige);
    --z: 49998;
    position: fixed;
    bottom: var(--grid-gutter);
    right: var(--grid-gutter);
    z-index: var(--z);

    pointer-events: none;
    opacity: 0;
    transform: scaleY(0.95) translateY(60%);
    transition: opacity 0.15s ease-out, transform 0.15s ease-out, filter 0.5s ease;

    .o-shape {
        z-index: 1;

        svg {
            --svg-width: 4.5rem;
            color: $color-beige;
            z-index: calc(var(--z) -1);
        }
    }

    &.is-visible {
        pointer-events: all;
        opacity: 1;
        transform: scaleY(1) translateY(0%);
        transition: opacity 0.25s ease-out, transform 0.25s ease-out, filter 0.3s ease-out;
    }

    &:before {
        content: var(--label);
        position: absolute;
        top: 50%;
        right: 50%;
        font-weight: 600;
        font-size: fs("small");
        transform: translate(50%, -25%);
        z-index: 2;
        transition: filter 0.5s ease;
    }

    &:hover {
        filter: drop-shadow(0 0 0.5rem rgba($color-dark, 0.5));
    }
}
</style>
