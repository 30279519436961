import snippets from './snippets'
import getAsset from './assets'

/*
 * Craft GraphQL Queries
 */

const navs = window.__initialData__.navs


/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    navMain: nodes (navHandle: "${navs.main}", level: 1) {
        type
        title
        url
        uri: nodeUri
        children {
            type
            title
            uri: nodeUri
            url
            id
            children {
                type
                title
                uri: nodeUri
                url
                id
            }
        }
    }
    navFooter: nodes (navHandle: "${navs.footer}", level: 1) {
        type
        title
        url
        uri: nodeUri
        children {
            type
            title
            uri: nodeUri
            url
        }
    }
    forms: globalSet(handle: "formConfirmations") {
        ... on formConfirmations_GlobalSet {
            contact: body2
        }
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            address
            phone
            email

            #
            twitter
            instagram
            facebook
            youtube
            linkedIn

            #
            navHeading: heading
            ${snippets.buttons}
        }
    }
}`


/**********************************
 *
 *
 *  SEARCH
 *
 *
 **********************************/

export const categoryGroup = `query Categories($group: [String!], $relations: [EntryCriteriaInput]){
    categories(group: $group, relatedToEntries: $relations) {
        id
        title
        handle: slug
    }
}
`

export const searchEntries = (section, options) => {

    if (section == undefined){
        throw('A section is required to perform the searchEntries query');
    }

    let appendQuery = ""

    options.idsExclude = options.idsExclude || false

    if (options.relatedToCategories && options.relatedToCategories.length) {

        let relatedToCategories = []

        options.relatedToCategories.forEach(categ => {
           let category = `{
                group: ["${categ.group}"],
                id: [${categ.id}]
           }`

           relatedToCategories.push(category)
        })

        appendQuery += `, relatedToCategories: [${relatedToCategories}]`
    }
    if (options.searchText) {
        appendQuery += `, search: "${options.searchText}"`
    }

    if (options.fixedOrder) {
        appendQuery += `, fixedOrder: ${options.fixedOrder}`
    } else {
        let orderKey = options.order ? options.order.key : 'postDate',
        orderDirection = options.order ? options.order.direction : 'DESC'

        let orderBy = `${orderKey} ${orderDirection}`
        appendQuery += `, limit: ${options.limit || 100}, offset: ${options.offset || 0}`
        appendQuery += `, orderBy: "${orderBy}"`
    }

    if (options.ids) {

        // Filter undefined values + map `id` only
        let _ids = options.ids.filter(Boolean).map(id => parseInt(id))

        _ids.forEach(id => {

            if(isNaN(id))
                throw('ids should be of type Number')

        })

        if (options.idsExclude) {
            appendQuery += `, id: ["not", ${_ids}]`
        } else {
            appendQuery += `, id: [${_ids}]`
        }
    }

    return `{
        totalCount: entryCount(section: "${section}")
        resultsCount: entryCount(section: "${section}" ${appendQuery})
        entries(section: "${section}", ${appendQuery}) {
            ${snippets.entry}

        }
    }`
}


/**********************************
 *
 *
 *  CONTENT
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.defaultData}
        section: sectionHandle
        ...on home_home_Entry {
            #
            heading: body2
            description
            colour
            ${getAsset('image', 'header.hero')}
            ${snippets.advancedHero}
            ${snippets.buttons}

            #
            statHeading: body3
            statDesc: description2
            statNumbers: ${snippets.numbers}
            statButtonLabel: label
            statButtonTarget: relationEntryRelated {
                ${snippets.entry}
            }
            #
            featuredCampaign: relationEntryCampaign {
                id
            }

            #
            featuredProjects: relationEntriesProject {
                id
            }

            #
            postHeading: heading2
            postDesc: description3

            #
            ${snippets.seo}
        }
    }
}`

// Contact
export const contact = `{
    entry(section: "contact") {
        ${snippets.defaultData}
        section: sectionHandle
        ...on contact_contact_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            ${snippets.seo}
        }
    }
}`

// Pages
export const page = slug => `{
    entry(section: "page", slug: "${slug}") {
        ${snippets.page}
    }
}`

// Projects
export const projects = `{
    entry(section: "projects") {
        ${snippets.entry}

        ...on projects_projects_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            featuredEntry: relationEntryProject {
                id
                slug
            }
            ${snippets.seo}
        }
    }
}`

export const project = slug => `{
    entry(section: "project", slug: "${slug}") {
        ${snippets.project}
    }
}`

// Campaigns
export const campaigns = `{
    entry(section: "campaigns") {
        ${snippets.entry}

        ...on campaigns_campaigns_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            ${snippets.campaignTypes}
            featuredEntry: relationEntryCampaign {
                id
                slug
            }
            ${snippets.seo}
        }
    }
}`

export const campaign = slug => `{
    entry(section: "campaign", slug: "${slug}") {
        ${snippets.campaign}
    }
}`

// Visions
export const visions = `{
    entry(section: "visions") {
        ${snippets.entry}

        ...on visions_visions_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            ${snippets.seo}
        }
    }
}`

export const vision = slug => `{
    entry(section: "vision", slug: "${slug}") {
        ${snippets.vision}
    }
}`

// Issues
export const issues = `{
    entry(section: "issues") {
        ${snippets.entry}

        ...on issues_issues_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            ${snippets.seo}
        }
    }
}`

export const issue = slug => `{
    entry(section: "issue", slug: "${slug}") {
        ${snippets.issue}
    }
}`

// Posts
export const posts = `{
    entry(section: "posts") {
        ${snippets.entry}

        ...on posts_posts_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            ${snippets.seo}
        }
    }
}`

export const post = slug => `{
    entry(section: "post", slug: "${slug}") {
        ${snippets.post}
    }
}`

// Press
export const press = `{
    entry(section: "press") {
        ${snippets.entry}

        ...on press_press_Entry {
            ${snippets.hero}
            ${snippets.advancedHero}
            ${snippets.seo}
        }
    }
}`

export const pressArticle = slug => `{
    entry(section: "pressArticle", slug: "${slug}") {
        ${snippets.pressArticle}
    }
}`



export default {
    // GENERAL
    general,

    // SEARCH
    categoryGroup,
    searchEntries,

    // CONTENT
    home,
    contact,
    page,
    projects,
    project,
    campaigns,
    campaign,
    visions,
    vision,
    issues,
    issue,
    posts,
    post,
    press,
    pressArticle
}
