<template>
    <div>
        <app-section class="p-campaign">
            <app-builder
                v-if="page.sections && page.sections.length"
                :sections="page.sections"
                :display-anchors="page.hasAnchors"
            >
                <template #beforeSections v-if="page">
                    <tag
                        :label="page.campaignType.title"
                        :text-color="page.colour"
                        :outline="true"
                    />
                </template>
            </app-builder>
        </app-section>
    </div>
</template>

<script>
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'
import AppBuilder from 'layout/AppBuilder.vue'
import Tag from '../objects/Tag.vue';

export default {
    name: 'Page',
    components: {
        AppSection,
        AppBuilder,
        Tag,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    })
};

</script>

<style lang="scss">

.p-campaign {

}

</style>
