<template>
    <span
        class="o-shape"
        :class="`-${shapeName}`"
    >
        <icon :icon="shapeName" />
    </span>
</template>

<script>
import Icon from 'objects/Icon'

import { SHAPES } from 'src/constants'

export default {
    name: 'Shape',
    components: {
        Icon
    },
    data: () => ({
        SHAPES
    }),
    props: {
        shape: {
            type: String,
            default: null,
            validator: shape => {
                return SHAPES.indexOf(shape) !== -1
            },
            required: true
        },
        outline: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        shapeName() {

            let shapeName = `shape-${this.shape}`
            return (this.outline) ? `${shapeName}-outline` : shapeName
        }
    }
}
</script>

<style lang="scss">
.o-shape {
    pointer-events: none;
    user-select: none;
}
</style>
