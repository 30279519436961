<template>
    <app-section>
        <app-elements
            sectionHandle="issue"
            cardModel="horizontal"
            :options="{
                limit: 6
            }"
        >
            <template #beforeCardAsset>
                <tag label="Enjeu" textColor="blue" />
            </template>
            <template #cardContent="issue">
                <div class="t-content">
                    <h1 class="c-card__title">{{ issue.title }}</h1>
                    <p v-html="excerpt(issue.description, 144)" />
                </div>
            </template>
        </app-elements>
    </app-section>
</template>

<script>
import AppElements from 'layout/AppElements'
import AppSection from 'layout/AppSection'

import Tag from 'objects/Tag'

import { SECTION_HANDLES } from 'src/constants'
import { excerpt } from 'src/utils'

export default {
    name: 'Issues',
    components: {
        AppElements,
        AppSection,
        Tag
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    }),
    methods: {
        excerpt
    },
};

</script>

<style lang="scss">

.p-issues {
}
</style>
