<template>
    <div class="c-mailchimp">
        <form
            class="c-mailchimp__form"
            @submit.prevent="subscribe"
        >
            <div class="c-mailchimp__fields">
                <div class="o-input--group -name">
                    <input
                        type="text"
                        name="prenom"
                        placeholder="Prénom*"
                        @input="setField('prenom', $event.target.value)"
                        class="o-input"
                        required
                    />
                    <input
                        type="text"
                        name="nom"
                        placeholder="Nom*"
                        @input="setField('nom', $event.target.value)"
                        class="o-input"
                        required
                    />
                </div>
                <div class="o-input--group -adress">

                    <input
                        id="mcEmailInput"
                        type="email"
                        name="email"
                        placeholder="Adresse courriel*"
                        class="o-input"
                        @input="setEmail($event.target.value)"
                        required
                    />
                    <input
                        type="text"
                        name="codepostal"
                        placeholder="Code postal"
                        @input="setField('codepostal', $event.target.value)"
                        class="o-input"
                    />
                </div>
            </div>

            <btn
                type="submit"
                label="S'inscrire"
                bg-color="beige"
                class="c-mailchimp__btn"
                :is-shaped="true"
            />
        </form>

        <div
            v-if="error || success || loading"
            class="c-mailchimp__messages | t-cms"
        >
            <div v-if="error" v-html="error" class="-error"></div>
            <div v-if="success">Succès</div>
            <div v-if="loading">Chargement</div>
        </div>
    </div>
</template>

<script>

import Btn from 'components/Btn';

import jsonp from 'jsonp'
import queryString from 'query-string'

export default {
    name: "MailChimp",
    components: {
        Btn
    },
    data: () => ({
        email: null,
        success: false,
        error: null,
        loading: false,
        customFields: {
            PRENOM: null,
            NOM: null,
            CODEPOSTAL: null
        },
        userId: 'dc88b40963e5d43afe30da0af',
        listId: 'cf0779cc20',
        accountHandle: 'transportsviables.us11'
    }),
    computed: {

        baseUrl() {
            return `https://${this.accountHandle}.list-manage.com/subscribe`
        },
        data() {
            const formData = {
                u: this.userId,
                id: this.listId,
                EMAIL: this.email
            }

            const merge = {...formData, ...this.customFields};

            return queryString.stringify(merge)
        },
    },
    methods: {
        setEmail(value = '') {
            this.email = value.trim()
        },

        setField(field, value = '') {
            field = field.toUpperCase()
            this.customFields[field] = value.trim()
        },

        subscribe() {

            if (this.email === null || this.loading) {
                return
            }

            this.success = false
            this.error = null
            this.loading = true

            const url = `${this.baseUrl}/post-json?${this.data}`

            jsonp(url, { param: 'c' }, this.onResponse)
        },

        onResponse(error, data) {
            this.loading = false

            if (error) {
                this.error = error
            }

            if (data && data.result === 'error') {

                this.error = this.formatErrorMessage(data.msg)
                // if(this.email) {
                //     window.location.href = `${this.baseUrl}?${this.data}`
                // } else {
                //     this.error = this.formatErrorMessage(data.msg)
                // }
            }

            if (!this.error) {
                this.success = true
                this.email = null
            }
        },

        formatErrorMessage(message) {
            return message
            //return message.slice(4)
        },
    },
}

</script>

<style lang="scss">

.c-mailchimp {
    --form-gutter: 1.2rem;

    .o-input--group {
        display: grid;
        gap: var(--form-gutter);

        @media #{md('xs')} {
            grid-template-columns: 1fr 1fr;
        }
    }

    .o-input {
        font-size: fs("small");
        border: solid 1px $color-dark;
        padding: 1rem;
        border-radius: $border-radius-xs;
        display: block;
        width: 100%;

        &::placeholder {
            opacity: 1;
            font-weight: 600;
        }
    }
}

.c-mailchimp__form {

    display: grid;
    gap: var(--form-gutter);

    .c-mailchimp__fields {
        display: grid;
        gap: var(--form-gutter);

        @media #{md('sm')} {
            grid-column: 1/6;
        }

        @media #{md('lg')} {
            grid-column: 1/10;
        }
    }

    .c-mailchimp__btn {

        @media #{md('sm')} {
            grid-column: 1/6;
            justify-self: start;
        }

        @media #{md('lg')} {
            grid-column: 10/12;
            align-self: end;
        }
    }
}

.c-mailchimp__messages {
    width: 100%;

    & > * {
        padding-top: 1em;

        &.-error {
            color: red;
        }
    }
}

</style>
