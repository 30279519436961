import { checkStatus } from 'src/utils';

/*
 * GraphQL Configuration and variables
 */

export const api = '/api'

export const fetchApi = (query, variables) => {

    return fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query, variables })
        })
        .then(checkStatus)
        .then(r => {
            return r.json()
        })
        .then(r => {
            return r.data || r.errors || null;
        })
        .catch(error => {
            console.error('Error: /api', error) // eslint-disable-line
            return undefined
        })
}



export const fetchJSON = url => {
    // console.log('fetchJSON:', query)

    return fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(r => r.json())
        .then(r => r)
        .catch(error => {
            console.log('fetchJSON error:', error) // eslint-disable-line
            return undefined
        })
}
