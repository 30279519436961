<template>
    <div
        v-if="data"
        class="b-spacer"
    />
</template>

<script>
export default {
    name: "BlockSpacer",
    components: {
    },
    props: {
        data: false
    }
}
</script>

<style lang="scss">
.b-spacer {
    height: var(--grid-gutter-half);
}
</style>
