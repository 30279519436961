<template>
    <div
        v-if="campaign"
        class="c-home-campaign"
        :class="{ 'is-mobile': isMobile }"
    >
        <div class="c-home-campaign__asset" ref="assetWrap">
            <asset
                shape="pentagon-down"
                class="-campaign-image"
                :asset="campaign.hero || campaign.image"
            />
        </div>

        <div class="c-home-campaign__header">
            <h2 class="-title | t-t1">Campagne</h2>
            <btn
                label="Voir les campagnes"
                bg-color="light"
                bgHoverColor="beige"
                :is-shaped="true"
                tag="router-link"
                :url="SECTION_HANDLES.CAMPAIGNS|resolver"
            />
        </div>
        <div class="c-home-campaign__content | -campain">
            <div class="-campain-header">
                <h3 class="-campaign-title | t-t3">{{ campaign.title }}</h3>
                <span
                    v-if="campaign.beginDate && campaign.endDate"
                    class="-campaign-dates"
                >
                    <span>Du {{ beginDate }}</span>
                    <span>au {{ endDate }}</span>
                </span>
            </div>
            <div class="-campaign-shape">
                <asset
                    v-if="campaign.logo"
                    :asset="campaign.logo"
                    class="-campaign-logo"
                />
                <span class="-campain-edition">{{ campaign.edition }}</span>
                <span
                    v-if="campaign.beginDate && campaign.endDate"
                    class="-campaign-dates"
                >
                    <span>Du {{ beginDate }}</span>
                    <span>au {{ endDate }}</span>
                </span>
                <background-shape :is-shaped="true" />
            </div>

            <tag
                v-if="campaign.campaignType"
                :label="campaign.campaignType.title"
                :outline="true"
            />

            <p
                v-if="description"
                class="-campaign-description"
            >
                {{ description }}
            </p>

            <btn
                v-if="campaign.uri"
                tag="router-link"
                :url="`/${campaign.uri}`"
                icon-after="arrow-right"
                label="Consulter"
                class="btn-campaign__link"
            />

        </div>
    </div>
</template>

<script>
import Asset from 'objects/Asset'
import BackgroundShape from 'objects/BackgroundShape'
import Shape from 'objects/Shape'
import Tag from 'objects/Tag'
import Btn from 'components/Btn'

import { formatDate, excerpt } from 'src/utils'
import { SECTION_HANDLES } from 'src/constants'
import Variables from 'mixins/variables'

export default {
    components: {
        Asset,
        BackgroundShape,
        Tag,
        Shape,
        Btn,
    },
    props: {
        campaignId: {
            type: String | Number
        }
    },
    mixins: [Variables],
    data: () => ({
        SECTION_HANDLES,
        campaign: null,
        isMobile: false,
    }),
    created() {
        // TODO: convert to a campaign/loadElement (w/slug) once the backend field is in place
        this.$store.dispatch('campaign/searchEntries', { ids: [this.campaignId] })
            .then(res => {

                if ( res.length )
                    this.campaign = res[0]
            })
    },
    mounted() {
        window.addEventListener('resizeEnd', this.setSizes)
    },
    computed: {
        beginDate() {
            let endYear = this.endDate.slice(-4),
                date  = formatDate(this.campaign.beginDate),
                beginYear = date.slice(-4)
                return endYear == beginYear
                    ? date.replace(beginYear, '')
                    : date
        },
        endDate() {
            return formatDate(this.campaign.endDate)
        },
        description() {
            return excerpt(this.campaign.description, 120)
        },
    },
    methods: {
        setSizes() {
            this.isMobile = this.W.smallScreen

        }
    },
    destroyed() {
        window.removeEventListener('resizeEnd', this.setSizes)
    },
    watch:{
        campaign: {
            handler: 'setSizes',
            immediate: true
        },
    }

}
</script>

<style lang="scss">

.c-home-campaign {
    --top-space: 18rem;
    --bottom-space: 7.5rem;
    --left-space: clamp(var(--grid-gutter), 15vw, 10rem);

    display: grid;
    grid-template-areas:
        "heading"
        "img"
        "content"
    ;

    padding-top: var(--top-space);
    padding-bottom: var(--bottom-space);
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    gap: var(--grid-gutter);

    // OSX Chrome fix
    align-items: start;
    grid-template-rows: auto 1fr;

    .btn-campaign__link {
        margin-left: -1rem;
    }

    @media #{md('xs')} {
        --top-space: 20rem;
    }

    @media #{md('sm')} {
        --top-space: 14rem;
        --bottom-space: 14rem;

        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            "img img img heading heading heading"
            "img img img content content content"
        ;
        row-gap: var(--grid-gutter-half);

        > *:first-of-type {
            align-self: flex-start !important;
        }
    }

    @media #{md('md')} {
        column-gap: calc(2 * var(--grid-gutter));
    }

    // Background
    &:after {
        width: 150%;
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(-2 * var(--grid-gutter));
        content: '';
        background: var(--color-blue-light);

        pointer-events: none;
    }

    .c-home-campaign__asset {
        position: static;
        grid-area: img;

        @media #{md('sm')} {

        }
        .o-asset {
            //--shape-radius: 16rem;
            --shape-width: calc(100% - 4 * (var(--grid-gutter)));
            width: 100%;
            max-width: calc(100% - 2 * (var(--grid-gutter)));
            margin: auto;
            min-width: 20rem;
            overflow: visible;
            z-index: 1;

            @media #{md('sm')} {
                //--shape-radius: 26rem;
                --shape-width: calc(100% - 2 * (var(--grid-gutter)));
                max-width: 50rem;
            }

            // DECOS
            &:before,
            &:after {
                position: absolute;
                z-index: -1;
                content: '';
                background: var(--color-blue);
                pointer-events: none;
            }

            // Vertical
            &:before {
                left: calc(var(--grid-gutter) * 2);
                bottom: 50%;
                width: var(--shape-width);
                height: calc( 50% + var(--top-space) + var(--grid-gutter) );

                @media #{md('sm')} {
                    left: calc(var(--grid-gutter));
                    height: calc( 50% + var(--top-space) );
                }
            }


            // Horizontal
            &:after {
                bottom: 0;
                right: calc( var(--shape-width)/2 + var(--grid-gutter)*2 );
                width: 100rem;
                height: var(--shape-width);
                //border-bottom-right-radius: var(--shape-radius);

                @media #{md('sm')} {
                    right: calc( var(--shape-width)/2 + var(--grid-gutter) );
                }
            }
        }
    }


    .c-home-campaign__header {
        grid-area: heading;
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr;
        justify-content: space-between;
        align-items: center;
        margin-top: calc(-1 * var(--top-space) + (var(--grid-gutter) * 4));
        height: 0; // Important, sets our header height to zero to avoid empty grid space
        z-index: 1;

        @media #{md('sm')} {
            display: none;
        }
        @media #{md('md')} {
            display: flex;
            margin-top: 0;
            flex-direction: row;
            height: fit-content;
        }

        .-title {
            @include responsive-type(35, 60, 480, 1600);

            @media #{md('sm')} {
                padding-right: 2rem;
            }
        }

        .c-btn {
            height: fit-content;
            white-space: pre;
        }
    }

    .c-home-campaign__content {
        display: grid;
        grid-area: content;
        gap: var(--grid-gutter);
        align-items: start;
        padding-top: var(--grid-gutter);
        padding-bottom: var(--grid-gutter);
        z-index: 1;

        @media #{md("sm")} {
            gap: var(--grid-gutter-half);
            padding-top: 0;
            width: 100%;
        }

        .-campaign-description {
            max-width: 35rem;
        }

        .-campaign-dates {
            display: flex;
            flex-wrap: wrap;
            white-space: pre;
        }

    }

    .-campain {

        .-campain-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .-campaign-dates {

                @media #{md('md')} {
                    display: none !important;
                }
            }
        }

        .-campaign-title {
            padding-right: 2rem;
            @include responsive-type(25, 35, 480, 1600);
            white-space: pre;
        }

        .-campaign-shape {
            padding: var(--grid-gutter-half);
            margin-right: var(--grid-gutter);
            width: fit-content;
            height: 100%;
            display: grid;
            grid-auto-flow: column;
            align-items: center;

            > *:not(.o-bg-shape) {
                padding: 0 2rem;
                position: static;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                & + *:not(.o-bg-shape):before {
                    width:1px;
                    background: $color-beige;
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateX(-2rem);
                    max-height: var(--grid-gutter);
                    height: max(var(--grid-gutter), 100%);
                }
            }

            .-campaign-logo {
                .o-asset__img {
                    width: auto;
                    height: 2.6rem;

                    @media #{md('md')} {
                        height: 3.4rem;
                    }
                }
            }

            .-campaign-dates {
                display: none !important;

                @media #{md('md')} {
                    display: flex !important;
                }
            }

            .-campaign-edition {
                white-space: pre;
            }

            .o-bg-shape {
                --border-radius: #{$border-radius-sm};
                --bg-color: #{$color-light};

                @media #{md('sm')} {
                    --border-radius: #{$border-radius-md};
                }
            }
        }
    }
}

</style>
