<template>
    <span class="o-copyright | t-regular">
        <span >
            &copy; {{ year }}, Accès transport viables. Tous droits réservés
        </span>
        <span>
            Web et développement: 
            <a 
                class="link" 
                target="_blank"
                rel="noopener" 
                href="https://mambomambo.ca" 
                title="MamboMambo"
            >MamboMambo</a>
        </span>
    </span>
</template>

<script>

export default {
    name: 'Copyright',
    computed: {
        year: () => new Date().getFullYear()
    }
}
</script>

<style lang="scss">

</style>
