<template>
    <div
        v-if="posts.length"
        class="c-home-posts"
    >
        <div class="c-home-posts__top">
            <div class="t-content">
                <h2
                    v-if="title"
                    class="t-t3"
                    v-html="title"
                    />
                <p
                    v-if="description"
                    v-html="description"
                />
            </div>
            <btn
                v-if="!isSlider"
                tag="router-link"
                label="Voir les publications"
                :is-shaped="true"
                bg-color="blue"
                :url="SECTION_HANDLES.POSTS|resolver"
            />
        </div>

        <card-grid
            ref="slider"
            card-model="standard"
            :cards="posts"
            :slider="true"
            class="c-home-posts__grid"
            sliderModifier="nav-on-top"
            @sliderActive="sliderChange"
        >
            <template #afterCardNav>
                <div class="c-slider__nav--after">
                    <btn
                        tag="router-link"
                        label="Voir les publications"
                        :is-shaped="true"
                        bg-color="blue"
                        :url="SECTION_HANDLES.POSTS|resolver"
                    />
                </div>
            </template>
        </card-grid>
    </div>
</template>

<script>
import CardGrid from 'components/CardGrid.vue'
import Btn from '../components/Btn.vue'

import { SECTION_HANDLES } from 'src/constants'

export default {
    components: {
        CardGrid,
        Btn,
    },
    name: 'HomePosts',
    props: {
        postIds: {
            type: Array,
            validator: ids => ids.length == 3
        },
        title: String,
        description: String,
    },
    data: () => ({
        SECTION_HANDLES,
        isSlider: false,
        posts: []
    }),
    created() {

        this.$store.dispatch('post/searchEntries', { limit: 4 })
            .then(res =>  this.posts = res )
    },
    methods: {
        sliderChange(state) {
            this.isSlider = state
        }
    }
}
</script>

<style lang="scss">
.c-home-posts {

    &__top {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: var(--grid-gutter);

        .t-content {
            --content-gap: 1rem;
            max-width: 40ch;
        }
    }

    &__grid {

        &.c-card-grid.-slider {
            .c-slider {
                --slide-width: 30rem;
            }
        }

        .c-card__content {
            gap: var(--grid-gutter-half);
        }
    }
}
</style>
