import { SECTION_HANDLES } from 'src/constants'
import { removeTags } from 'src/utils'

/*
 * Data parser
 */

// Utilities
const arrayHasItem = array => {
    return typeof array !== 'undefined' && array.length > 0
}

export const parseIcon = icon => {
    return icon && icon.name ? icon.name.replace('svg-', '') : null
}

export const parseButtons = buttons => {
    let _buttons = []

    if (!arrayHasItem(buttons))
        return

    buttons.forEach(b => {

        if (b.target)
            b.target = arrayHasItem(b.target) ? b.target[0] : null

        if ( b.externalLink == null && b.target == null )
            return

        const url = b.externalLink || `/${b.target.uri}`,
              label = b.label || b.target.title

        _buttons.push({
            label: label,
            tag: b.externalLink ? 'a' : 'router-link',
            colour: b.colour,
            iconAfter: b.style == "arrow" ? "arrow-right" : null,
            isShape: b.style === "shape",
            url: url
        })
    })

    return _buttons
}


// Parse method
export const parseData = (type, data) => {

    if (data.document)
        data.document = arrayHasItem(data.document) ? data.document[0] : null

    if (data.image)
        data.image = arrayHasItem(data.image) ? data.image[0] : null

    if (data.imageAlt)
        data.imageAlt = arrayHasItem(data.imageAlt) ? data.imageAlt[0] : null

    if (data.hero)
        data.hero = arrayHasItem(data.hero) ? data.hero[0] : null

    if (data.logo)
        data.logo = arrayHasItem(data.logo) ? data.logo[0] : null

    if (data.relatedEntry)
        data.relatedEntry = arrayHasItem(data.relatedEntry) ? data.relatedEntry[0] : null

    if (data.featuredEntry)
        data.featuredEntry = arrayHasItem(data.featuredEntry) ? data.featuredEntry[0] : null

    if (data.targetAudience)
        data.targetAudience = arrayHasItem(data.targetAudience) ? data.targetAudience[0] : null

    if (data.buttons)
        data.buttons = parseButtons(data.buttons)

    switch(type) {

        // HOME
        case(SECTION_HANDLES.HOME): {

            if (data.heading)
                data.heading = removeTags(data.heading)

            if (data.statHeading)
                data.statHeading = removeTags(data.statHeading)

            if (data.featuredCampaign)
                data.featuredCampaign = arrayHasItem(data.featuredCampaign) ? data.featuredCampaign[0] : null

            if (data.featuredProjects)
                data.featuredProjects = arrayHasItem(data.featuredProjects) ? data.featuredProjects.map(p => p.id) : null

            break
        }

        // CAMPAIGN
        case(SECTION_HANDLES.CAMPAIGN): {

            data.campaignType = arrayHasItem(data.campaignType) ? data.campaignType[0] : null

            break;
        }

        // POST
        case(SECTION_HANDLES.POST): {

            data.category = arrayHasItem(data.category) ? data.category[0] : null

            break;
        }

        // PRESS_ARTICLE
        case(SECTION_HANDLES.PRESS_ARTICLE): {

            data.category = arrayHasItem(data.category) ? data.category[0] : null

            break;
        }
    }

    return data
}
