<template>
    <div>
        <app-section class="l-sections">
            <app-builder
                v-if="page.sections && page.sections.length"
                :sections="page.sections"
                :display-anchors="page.hasAnchors"
            />
        </app-section>
    </div>
</template>

<script>
import AppBuilder from 'layout/AppBuilder'
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'PressArticle',
    components: {
        AppBuilder,
        AppSection
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    }),
};

</script>

<style lang="scss">

.p-press-article {

    .l-grid-content--full {
        max-width: 90rem;
        margin-left: auto;
        margin-right: auto;
    }
}

</style>
