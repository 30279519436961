<template>
    <div
        v-if="image"
        :class="className"
        ref="picture"
        :style="styles"
    >
        <template v-if="shape">
            <slot name="beforeAsset" />
            <picture class="o-asset__wrapper">
                <source
                    v-if="image.srcset"
                    :data-srcset="lazy ? image.srcset : false"
                    :srcset="!lazy ? image.srcset : false"
                    :type="image.mimeType"
                >
                <img
                    class="o-asset__img"
                    :class="{ 'lazyload' : lazy }"
                    ref="image"
                    :src="lazy ? '/static/images/blank.gif' : image.url"
                    :data-sizes="lazy ? 'auto' : false"
                    :data-src="lazy ? image.url : false"
                    :alt="title ? title : image.title"
                    :width="image.width"
                    :height="image.height"
                    @lazyloaded="loaded()"
                    @load="loaded()"
                />
            </picture>
            <div class="o-asset__shapes">
                <svg width="0" height="0">
                    <clipPath id="losange" clipPathUnits="objectBoundingBox">
                        <path d="M0.907024 0.275535L0.724465 0.0929762C0.600496 -0.0309922 0.399504 -0.030992 0.275536 0.0929764L0.0929764 0.275536C-0.030992 0.399504 -0.0309922 0.600496 0.0929762 0.724465L0.275535 0.907023C0.399504 1.03099 0.600496 1.03099 0.724465 0.907024L0.907024 0.724465C1.03099 0.600496 1.03099 0.399504 0.907024 0.275535Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="hexagon" clipPathUnits="objectBoundingBox">
                        <path d="M0.614396 0.015625H0.385667C0.339199 0.015626 0.29359 0.0281516 0.25364 0.0518837C0.213689 0.0756159 0.180874 0.109678 0.158646 0.150484L0.0315038 0.383958C0.0108312 0.421887 0 0.464393 0 0.507589C0 0.550786 0.0108312 0.593292 0.0315038 0.63122L0.158646 0.864631C0.180864 0.905446 0.213677 0.939515 0.25363 0.963248C0.293583 0.986982 0.339196 0.999501 0.385667 0.999489H0.614396C0.660859 0.999503 0.706465 0.986984 0.746408 0.96325C0.786352 0.939516 0.819152 0.905446 0.841354 0.864631L0.968496 0.63122C0.989169 0.593292 1 0.550786 1 0.507589C1 0.464393 0.989169 0.421887 0.968496 0.383958L0.841354 0.150484C0.819142 0.109678 0.786338 0.0756151 0.746398 0.0518822C0.706457 0.0281492 0.660856 0.0156238 0.614396 0.015625V0.015625Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="pentagon-up" clipPathUnits="objectBoundingBox">
                        <path d="M0.761279 1H0.237524C0.174529 1 0.114113 0.974975 0.0695685 0.930431C0.0250241 0.885887 0 0.825471 0 0.762476V0.462943C1.92364e-06 0.428269 0.00759434 0.394015 0.0222462 0.362589C0.0368981 0.331162 0.0582534 0.303325 0.0848111 0.281031L0.353371 0.0556055C0.396745 0.0191931 0.451702 -0.000523661 0.508332 1.05784e-05C0.564961 0.000544818 0.619536 0.0212949 0.662216 0.0585192L0.917411 0.281475C0.942963 0.303763 0.963447 0.331263 0.977486 0.362127C0.991525 0.392991 0.998794 0.426503 0.998803 0.460409V0.762666C0.998753 0.825629 0.973705 0.885995 0.929166 0.930498C0.884628 0.975001 0.824241 1 0.761279 1V1Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="pentagon-down" clipPathUnits="objectBoundingBox">
                        <path d="M0.237211 0H0.760972C0.823957 -2.23946e-09 0.884363 0.0250163 0.928906 0.0695474C0.973449 0.114079 0.998482 0.174478 0.998498 0.237463V0.537063C0.998505 0.57173 0.990914 0.605977 0.976261 0.637395C0.961608 0.668813 0.940249 0.696638 0.913686 0.718913L0.645122 0.944405C0.601746 0.980821 0.546782 1.00053 0.490149 0.999989C0.433515 0.999443 0.378941 0.978674 0.336275 0.941428L0.081393 0.71847C0.0558406 0.696182 0.0353562 0.668681 0.0213171 0.637817C0.00727798 0.606953 9.04134e-06 0.57344 0 0.539533V0.237463C1.67254e-05 0.174533 0.0250066 0.114181 0.0694811 0.0696593C0.113956 0.0251373 0.174281 8.39046e-05 0.237211 0V0Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="square" clipPathUnits="objectBoundingBox">
                        <path d="M0.758026 0H0.241974C0.108336 0 0 0.108335 0 0.241974V0.758026C0 0.891664 0.108336 1 0.241974 1H0.758026C0.891664 1 1 0.891664 1 0.758026V0.241974C1 0.108335 0.891664 0 0.758026 0Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="triangle-down" clipPathUnits="objectBoundingBox">
                        <path d="M0.686956 0.884459L0.977575 0.305208C0.993673 0.273121 1.00133 0.237426 0.999811 0.201523C0.998294 0.165621 0.987655 0.130707 0.968908 0.100107C0.950161 0.0695082 0.923931 0.0442435 0.892716 0.0267197C0.861501 0.00919595 0.826341 -2.80008e-06 0.790587 6.39355e-10H0.209412C0.173658 -2.80008e-06 0.138498 0.00919595 0.107283 0.0267197C0.0760681 0.0442435 0.0498386 0.0695082 0.0310918 0.100107C0.0123449 0.130707 0.00170569 0.165621 0.000188615 0.201523C-0.00132846 0.237426 0.00632623 0.273121 0.0224243 0.305208L0.313043 0.884459C0.390437 1.03851 0.609499 1.03851 0.686956 0.884459Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="triangle-up" clipPathUnits="objectBoundingBox">
                        <path d="M0.313043 0.115709L0.0224243 0.694851C0.00632623 0.726931 -0.00132846 0.76262 0.000188615 0.798516C0.00170569 0.834411 0.0123449 0.869319 0.0310918 0.899912C0.0498386 0.930506 0.0760681 0.955766 0.107283 0.973286C0.138498 0.990807 0.173658 1 0.209412 1H0.790587C0.826341 1 0.861501 0.990807 0.892716 0.973286C0.923931 0.955766 0.950161 0.930506 0.968908 0.899912C0.987655 0.869319 0.998294 0.834411 0.999811 0.798516C1.00133 0.76262 0.993673 0.726931 0.977575 0.694851L0.686956 0.115709C0.609499 -0.0385695 0.390437 -0.0385695 0.313043 0.115709Z" fill="#1F1750"/>
                    </clipPath>
                    <clipPath id="circle" clipPathUnits="objectBoundingBox">
                        <path d="M0.5 1C0.776142 1 1 0.776142 1 0.5C1 0.223858 0.776142 0 0.5 0C0.223858 0 0 0.223858 0 0.5C0 0.776142 0.223858 1 0.5 1Z" fill="#1F1750"/>
                    </clipPath>
                </svg>

            </div>
        </template>
        <picture v-else>
            <source
                v-if="image.srcset"
                :data-srcset="lazy ? image.srcset : false"
                :srcset="!lazy ? image.srcset : false"
                :type="image.mimeType"
            >
            <img
                class="o-asset__img"
                :class="{ 'lazyload' : lazy }"
                ref="image"
                :src="lazy ? '/static/images/blank.gif' : image.url"
                :data-sizes="lazy ? 'auto' : false"
                :data-src="lazy ? image.url : false"
                :alt="title ? title : image.title"
                :width="image.width"
                :height="image.height"
                @lazyloaded="loaded()"
                @load="loaded()"
            />
        </picture>
    </div>
</template>

<script>
import { SHAPES } from 'src/constants'
import { round } from 'src/utils'

import 'lazysizes';

export default {
    name: 'Asset',
    props: {
        asset: {
            type: Object|Array,
            default: () => []
        },
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        cover: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        shape: {
            type: String,
            default: null,
            validator: shape => {
                return SHAPES.indexOf(shape) !== -1
            }
        },
    },
    data: () => ({
        isLoaded: false,
        SHAPES
    }),
    created() {
        this.isLoaded = this.lazy
    },
    computed: {
        className() {
            let classname = 'o-asset'

            if(this.cover) {
                classname += ' -cover'
            }

            if(this.contain) {
                classname += ' -contain'
            }

            if(this.rounded) {
                classname += ' -rounded'
            }

            if(this.shape) {
                classname += ` -shape -${this.shape}`
            }

            if(this.lazy) {
                classname += ' -lazy'

                if(this.loaded) {
                    classname += ' is-loaded'
                }
            }

            return classname
        },
        image() {
            let image = this.asset

            // If is array, take first index
            if(Array.isArray(image) && image.length > 0) {
                return image[0]
            }

            if(image && image.url) {
                return image
            }

            if(this.url) {
                return {
                    url: this.url,
                    title: this.title,
                    width: false,
                    height: false,
                }
            }
        },
        width() {
            return this.image.width
        },
        height() {
            return this.image.height
        },
        ratio() {
            if(!this.width || !this.height) {
                return null
            }

            return round(this.width / this.height, 3)
        },
        styles() {
            let styles = false

            const shape = this.shape

            if(this.ratio) {
                styles = `--asset-ratio: ${this.ratio};`
            }

            if (shape) {
                styles = `--shape: url("#${shape}")`
            }

            return styles
        },
    },
    methods: {
        loaded() {
            this.isLoaded = true
            this.$emit('loaded')
        },
    },
    watch: {
        isLoaded(isLoaded) {
            if(isLoaded) {
                //this.$emit('loaded')
            }
        },
    },
};

</script>

<style lang="scss">

.o-asset {
    display: block;
    pointer-events: none;
    user-select: none;
    z-index: 1;

    &.-cover {

        .o-asset__img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.-shape {
        overflow: hidden;

        .o-asset__wrapper {
            display: block;
            -webkit-clip-path: var(--shape);
            clip-path: var(--shape);
            overflow: hidden;
        }

        .o-asset__img {
            object-fit: cover;

            @supports (aspect-ratio: 1/1) {
                aspect-ratio: 1/1;
            }
        }

    }

    &.-rounded {
        border-radius: $border-radius-md;
        overflow: hidden;
    }

    &__shapes {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
    }
}

.o-asset__img {
    @include img;
}
</style>
