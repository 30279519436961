<template>
    <div v-if="cards.length > 0" :class="`-grid-${gridModel + ' ' + className}`">
        <slider
            v-if="sliderIsActive"
            :options="sliderOpts"
            :slides="cards"
            :slider="slider"
            :prevNext="prevNext"
            :fullwidth="fullwidth"
            :fullheight="true"
            ref="slider"
            class="c-card-grid__grid"
            :style="`--slider-height:${sliderHeight}px`"
            :modifier="sliderModifier"
            :show-index="showIndex"
        >
            <template #beforeNav>
                <slot name="beforeCardNav" />
            </template>
            <template #afterNav>
                <slot name="afterCardNav" />
            </template>

            <template #sliderExtraCards>
                <slot name="extraCards"/>
            </template>

            <template #slide="card">
                <component
                    :is="componentName"
                    :element="card"
                    :model="cardModel"
                    ref="card"
                >
                    <!-- Before -->
                <template #before>
                    <slot name="beforeCard" v-bind="card" />
                </template>

                <!-- Asset -->
                <template #beforeAsset>
                    <slot name="beforeCardAsset" v-bind="card" />
                </template>
                <template #asset>
                    <slot name="cardAsset" v-bind="card" />
                </template>
                <template #afterAsset>
                    <slot name="afterCardAsset" v-bind="card" />
                </template>

                <!-- Content -->
                <template #beforeContent>
                    <slot name="beforeCardContent" v-bind="card" />
                </template>
                <template #content>
                    <slot name="cardContent" v-bind="card" />
                </template>
                <template #afterContent>
                    <slot name="afterCardContent" v-bind="card" />
                </template>

                <!-- Router link -->
                <template #link>
                    <slot name="cardLink" v-bind="card" />
                </template>

                <!-- After -->
                <template #after>
                    <slot name="afterCard" v-bind="card" />
                </template>

                </component>
            </template>
        </slider>

        <div v-else class="c-card-grid__grid" ref="grid">
            <slot name="extraCards"/>
            <component
                v-for="(card, index) in cards"
                :key="`card-grid-${card.id}-${index}`"
                :is="componentName"
                :element="card"
                :model="cardModel"
                ref="card"
            >
                <!-- Before -->
                <template #before>
                    <slot name="beforeCard" v-bind="card" />
                </template>

                <!-- Asset -->
                <template #beforeAsset>
                    <slot name="beforeCardAsset" v-bind="card" />
                </template>
                <template #asset>
                    <slot name="cardAsset" v-bind="card" />
                </template>
                <template #afterAsset>
                    <slot name="afterCardAsset" v-bind="card" />
                </template>

                <!-- Content -->
                <template #beforeContent>
                    <slot name="beforeCardContent" v-bind="card" />
                </template>
                <template #content>
                    <slot name="cardContent" v-bind="card" />
                </template>
                <template #afterContent>
                    <slot name="afterCardContent" v-bind="card" />
                </template>

                <!-- Router link -->
                <template #link>
                    <slot name="cardLink" v-bind="card" />
                </template>

                <!-- After -->
                <template #after>
                    <slot name="afterCard" v-bind="card" />
                </template>

            </component>
        </div>
    </div>
</template>

<script>

import Slider from 'components/Slider'
import Card from 'components/Card'
import CardCampaign from 'components/CardCampaign'
import CardProject from 'components/CardProject'

export default {
    name: 'CardGrid',
    components: {
        Slider,
        Card,
        CardCampaign,
        CardProject
    },
    props: {
        showIndex: Boolean,
        sliderModifier: String,
        cardType: String,
        cardModel: {
            type: String,
            default: 'standard',
            validator: (model) => {
                return ['horizontal', 'enhance', 'standard'].indexOf(model) !== -1
            }
        },
        cards: {
            type: Array,
            default: () => [],
        },
        slider: {
            type: Boolean,
            default: false,
        },
        prevNext: {
            type: Boolean,
            default: true,
        },
        fullwidth: {
            type: Boolean,
            default: false,
        },
        fullheight: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        sliderIsActive: false,
        sliderHeight: null,
        gridModel: '',
        sliderOpts: {
            watchCSS: true,
            groupCells: true,
            percentPosition: false,
            accessibility: false
        },
    }),
    mounted() {
        if (this.slider) {
            this.setSlider()

            window.addEventListener('resizeEnd', this.setSlider)
        }

    },
    computed: {
        componentName() {
            return this.cardType ? `card-${this.cardType}` : `card`
        },
        className() {
            let classname = 'c-card-grid'

            classname += this.sliderIsActive ? ' -slider' : ' -no-slider'

            classname += this.cardType ? ` -${this.cardType}` : ''

            return classname
        },
    },
    methods: {
        setSlider() {

            if (!this.slider)
                return

            this.sliderIsActive = false

            this.$nextTick(() => {
                if (this.$refs.grid) {
                    const gridRows = window.getComputedStyle(this.$refs.grid).getPropertyValue('grid-template-rows').split(' ').length

                    if (gridRows > 1) {
                        this.sliderIsActive = true
                    }
                }
            })
        },
    },
    beforeDestroy() {

        if (this.slider) {
            window.removeEventListener('resizeEnd', this.setSlider)
        }
    },
    watch: {
        $refs: {
            handler(refs) {
                this.$nextTick(() => {
                    if(refs.card) {
                        this.gridModel = refs.card[0].model
                    }
                })
            },
            immediate: true
        },
        elements: {
            handler() {
                this.setSlider()
            },
            immediate: true
        },
        sliderIsActive(value) {
            this.$emit('sliderActive', value)

            // Save height to avoid glitch on iOS, since most scrolls trigger a resize
            if (value === true) {
                this.$nextTick(() => {
                    this.sliderHeight = this.$el.querySelector('.c-card-grid__grid').offsetHeight
                })
            }
        }
    }
};

</script>

<style lang="scss">
.c-card-grid {
    $self: &;
    --grid-auto-size: auto-fill;
    --grid-min-width: 26rem;
    --grid-custom-gap: 4rem;

    &.-no-slider {
        .c-card-grid__grid {
            display: grid;
            grid-gap: var(--grid-custom-gap);
            grid-template-columns: repeat(
                var(--grid-auto-size),
                minmax(var(--grid-min-width), 1fr)
            );
            justify-content: center;
            overflow-anchor: none;

            @media #{md("lg")} {
                --grid-min-width: 30rem;
            }
        }
        &.-grid-horizontal  {
            .c-card-grid__grid {
                // grid-template-columns: repeat(auto-fill, minmax(30vh, 50rem));
                grid-template-columns: 1fr;

                @media #{md("md")} {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }


    &.-slider {

        .c-card-grid__grid {
            height: var(--slider-height, auto);
        }
        .c-slider {
            --slide-width: 18em;
        }

        .c-slider__inner:after {
            content: "flickity";
            display: none;
        }

        .c-card {
            width: 100%;

            &:not(:first-child) {
                margin-left: 1em;
            }
        }
    }

    @media #{md("sm")} {
        &.-no-slider {
            .c-card-grid__grid {
                grid-row-gap: vh(7);
            }
        }
    }
}
</style>

