<template>
    <div v-if="data && data.tabs.length" class="b-tabs">
        <nav class="b-tabs__tabs">
            <btn
                v-for="(tab, i) in data.tabs"
                :key="`tab-${i}`"
                :label="tab.title"
                @click.native="setActiveTab(tab)"
                class="b-tabs__btn"
                :class="{'-b-active-tab': activeTab && activeTab.title == tab.title }"
                bg-color="beige"
            />
        </nav>
        <div class="b-tabs__content">
            <div
                class="b-tabs__content-inner | t-cms"
                v-if="activeTab"
                ref="tabContent"
                v-html="activeTab.body">
            </div>
        </div>
    </div>
</template>

<script>
import Btn from 'components/Btn.vue'
import { gsap } from 'gsap/all'

export default {
    name: 'BlockTabs',
    components: {
        Btn
    },
    data: () =>({
        activeTab: null
    }),
    props: {
        data: null,
    },
    mounted() {
        if(this.data && this.data.tabs.length)  {
            this.activeTab = this.data.tabs[0]
        }
    },
    methods: {
        tl (tab) {
            const tabContent = this.$refs.tabContent
            let tl = gsap.timeline()
                .addLabel('switchTab')
                .to(tabContent, {
                    opacity: 0,
                    duration: 0.2,
                    ease: 'power2.out',
                    onComplete: () => this.activeTab = tab
                })
                .to(tabContent, {
                    opacity: 1,
                    duration: 0.5,
                    ease: 'power2.in'
                })
            return tl
        },
        setActiveTab(tab) {
            this.tl(tab).play('switchTab')
        }
    },
    beforeDestroy() {
        this.tl(this.activeTab).kill()
    }
};

</script>

<style lang="scss">
.b-tabs {

    &__tabs {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &__content {
        border: 1px solid $color-beige;
        border-radius: $border-radius-xs;
        border-top-left-radius: 0;
    }

    &__content-inner {
        padding: var(--grid-gutter);
        max-width: calc(100vw - 4 * var(--grid-gutter));

        blockquote, p, li {
            break-inside: avoid;
        }

        @media #{md('md')} {
            columns: 2;
            column-gap: var(--grid-gutter);
        }
    }

    &__btn {
        --border-radius: #{$border-radius-md};
        --btn-padding-x: 2.5rem;
        --btn-padding-y: 2rem;
        padding-bottom: 1.5rem;
        white-space: pre;

        .o-bg-shape {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            transition-duration: 0s;
        }

        &.-b-active-tab {

            .o-bg-shape {
                --bg-color: white;
                --hover-color: white;
                transition-duration: 0s;
                border: solid 1px $color-beige;
                border-bottom: 0;
            }

            //Not working yet
            &:after {
                @include pseudo-el($width: calc(100% - 2px), $height: 50%, $bg: white);
                position: absolute;
                top: 75%;
                left: 1px;
                z-index: 2;
            }
        }
    }
}
</style>
