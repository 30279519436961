<template>
    <footer id="footer" class="l-footer | container">
        <section id="infolettre" class="l-footer__grid | grid">
            <div></div>
            <div class="l-footer__newsletter">
                <h1 class="-title | t-t5">
                    Informez-vous en vous inscrivant à l'infolettre
                </h1>
                <mail-chimp />
            </div>
        </section>

        <section class="l-footer__grid | grid">
            <div class="l-footer__logo">
                <app-logo :is-monochrome="true" className="footer-logo"/>
            </div>

            <div class="l-footer__nav">
                <div class="-footer-address">
                    <h1 class="t-t7">
                        Nous joindre
                    </h1>

                    <div v-if="contact.details" class="-address-infos t-cms">
                        <p class="-address">
                            {{ contact.details.address }}
                        </p>
                        <a :href="`mailto:${contact.details.email}`" class="contact-link -email">
                            {{ contact.details.email }}
                        </a>
                        <a :href="`tel:${contact.details.phone}`" class="contact-link -phone">
                            {{ contact.details.phone }}
                        </a>
                    </div>
                </div>

                <div class="-footer-links">
                    <div class="-footer-nav__links | -footer-socials">
                        <span class="t-t7">
                            Nous suivre
                        </span>
                        <ul v-if="contact.socialMedias">
                            <li
                                v-for="(social) in contact.socialMedias"
                                :key="social.name"
                            >
                                <btn
                                    v-if="social.name"
                                    tag="a"
                                    ref="socialBtn"
                                    class="btn-social"
                                    :label="social.name"
                                    :url="social.url"
                                    text-color="light"
                                    bg-color="dark"
                                    :bg-hover-color="social.color"
                                    :icon-before="social.name.toLowerCase()"
                                />
                            </li>
                        </ul>
                    </div>
                    <!-- levl 1 -->
                    <nav
                        class="-footer-nav__links"
                        v-for="(nav_level1, i) in footerNav"
                        :key="`nav_level1${i}`"
                    >
                        <span class="-footer-nav__links-title | t-t8">
                            {{ nav_level1.title }}
                        </span>
                        <!-- levl 2 -->
                        <ul
                            v-if="nav_level1.children && nav_level1.children.length"
                            :style="`--column-count: ${nav_level1.children.length > 4 ? 2 : 1}`"
                        >
                            <li
                                v-for="(nav_level2, j) in nav_level1.children"
                                :key="`nav_level2-${j}`"
                            >
                                <component
                                    :is="getNavNodeType(nav_level2)"
                                    :to="getNavNodeTo(nav_level2)"
                                    :title="nav_level2.title"
                                    exact
                                    itemprop="url"
                                    v-text="nav_level2.title"
                                    class="link"
                                />
                        </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <!-- Copyright -->
        <section class="l-footer__grid | grid">
            <div></div>
            <copyright class="l-footer__copyright" />
        </section>
    </footer>
</template>

<script>
import { mapState } from "vuex"
import Btn from "components/Btn.vue"
import MailChimp from "components/MailChimp"
import AppLogo from 'objects/AppLogo.vue'
import Copyright from 'objects/Copyright.vue'

const SOCIAL_COLORS = {
    facebook: 'facebook',
    instagram: 'instagram',
    twitter: 'twitter',
    youtube: 'youtube',
    linkedin: 'linkedin'
}

export default {
    components: {
        Btn,
        AppLogo,
        MailChimp,
        Copyright,
    },
    name: "AppFooter",
    data: () => ({
    }),
    mounted() {
    },
    computed: {
        year: () => new Date().getFullYear(),
        ...mapState({
            settings: state => state.global.settings,
            footerNav: state => state.global.navs.footer
        }),

        contact() {
            const { facebook, instagram, linkedIn, twitter, youtube, ...details } = this.settings

            const medias = [facebook, instagram, linkedIn, twitter, youtube]
                .filter(media => media)
                .map(media => {

                    // media: https://facebook.com or https://www.facebook.com
                    media = media.replace('www.', '')
                    let name = media.substring(
                        media.indexOf('/') + 2,
                        media.indexOf('.')
                    )


                    return {
                        name: name.charAt(0).toUpperCase() + name.substring(1),
                        url: media,
                        color: SOCIAL_COLORS[name],
                    }

                })
            return { socialMedias: medias, details }
        }
    },
    methods: {
        getNavNodeType(node) {

            if (node.type == "craft\\elements\\Entry") {

                if (node.children && node.children.length) {
                    return 'button'
                } else {
                    return 'router-link'
                }
            } else {
                if (node.uri.indexOf('#') > -1) {
                    return 'router-link'
                } else {
                    return 'a'
                }
            }
        },
        getNavNodeTo(node) {

            if (node.type == "craft\\elements\\Entry") {

                return `/${node.uri }` || `/`

            } else {
                return node.url
            }
        }
    },
};
</script>

<style lang="scss">
.l-footer {

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--grid-gutter);
        margin-top: calc(2 * var(--grid-gutter));
        margin-bottom: var(--grid-gutter);

        @media #{md('md')} {
            grid-template-columns: 2fr 10fr;
        }
    }

    &__logo {
        .o-icon svg {
            max-width: var(--svg-width);
            width:100%;
        }
    }


    &__copyright {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        span {
            padding-bottom: 1rem;
        }
    }
}

.l-footer__newsletter {

    display: grid;
    grid-template-columns: 1fr;
    gap: var(--grid-gutter-half);

    border-bottom: 1px solid currentColor;
    padding-bottom: calc(2 * var(--grid-gutter));

    @media #{md('md')} {
        grid-template-columns: 4fr 8fr;
        column-gap: var(--grid-gutter);
        align-items: start;
    }

    @media #{md('lg')} {
        align-items: end;
    }

    .-title {
        max-width: 13ch;
    }
}

.l-footer__nav {

    ul {
        @include reset-list;
    }

    .-address-infos {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;


        .-email {
            // text-decoration: underline; //
            padding-bottom: 1rem;
        }

        .contact-link {
            width: fit-content;
            text-decoration: none;
            padding-bottom: 0;
            margin-bottom: 1rem;
            transition: opacity .5s ease;

            @include link($underline: true);

            &:hover {
                color: $color-dark;
                // opacity: .8;
            }
        }
    }

    .-footer-links {
        margin-top: var(--grid-gutter);
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-gap: var(--grid-gutter);

        .-footer-nav__links {

            &-title {
                white-space: nowrap;
            }

            ul {
                padding-top: 1rem;

                li + li {
                    margin-top: 1rem;
                }
            }
        }

        .btn-social {
            --hover-color: var(--color-dark);

            &:hover {
                --text-color: var(--color-light);
                color: var(--text-color);
            }
        }
    }

    @media #{md('sm')} {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--grid-gutter);

        .-footer-links {
            margin-top: 0;
            flex-basis: 0;
            flex-grow: 1;
        }
    }

    @media #{md('md')} {
        grid-template-columns: 4fr 8fr;
    }

    @media #{md('lg')} {
        .-footer-links {
            grid-auto-flow: column;
            grid-auto-columns: min-content;
        }

        .-footer-nav__links ul{
            columns: var(--column-count);
            column-count: var(--column-count);
            column-gap: 1rem;
            width: fit-content;
        }
    }
}

</style>
