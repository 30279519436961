<template>
    <div class="b-videos" v-if="videos.length">
        <h4 class=" b-videos__title | t-t4">{{ data.title }}</h4>
        <div class="b-videos__wrapper">
            <card-grid
                :cards="videos"
                card-model='horizontal'
            >
                <template #beforeCardContent="video">
                    <div class="-video-before-content">
                        <div
                            v-if="video.participants"
                            class="-participants"
                        >
                            <icon icon="person-logo" style="color: var(--color-blue)"/>
                            <tag
                                :label="excerpt(video.participants, 40)"
                                text-color="blue"
                            />
                        </div>
                    </div>
                </template>

                <template #afterCardAsset="video">
                    <button
                        class="-play"
                        @click="zoomIndex = getVideoIndex(video.id)"
                        :title="`Lire ${video.title}`"
                    >
                        <icon
                            icon="play"
                            :title="`Lire ${video.title}`"
                        />
                    </button>
                </template>

                <template #cardContent="video">
                    <h1
                        class="-title"
                        v-html="video.title"
                    />
                </template>

                <template #afterCardContent="video">
                    <div
                        v-if="video.description"
                        class="-description"
                        v-html="excerpt(video.description, 150)"
                    > </div>
                </template>

                <template #cardLink="video">
                    <btn
                        tag="button"
                        :url="video.link"
                        icon-before="play"
                        class="c-card__link"
                        label="Lire"
                        :title="`Lire ${video.title}`"
                        @click.native="zoomIndex = getVideoIndex(video.id)"
                    />
                </template>
            </card-grid>

            <lightbox
                v-if="zoom"
                ref="zoom"
                :items="videos"
                :index="zoomIndex"
                @close="zoomIndex = null"
            />
        </div>
    </div>
</template>

<script>
import Icon from 'objects/Icon'
import Tag from 'objects/Tag'

import Btn from 'components/Btn'
import Card from 'components/Card'
import CardGrid from 'components/CardGrid'
import Lightbox from 'components/Lightbox'

import { excerpt } from 'src/utils'

export default {
    name: 'BlockVideos',
    components: {
        Btn,
        Card,
        CardGrid,
        Icon,
        Lightbox,
        Tag
    },
    data: () => ({
        zoomIndex: null
    }),
    props: {
        data: null,
        zoom: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        videos() {

            if (!this.data.videos)
                return

            return this.data.videos.map(v => ({
                id: v.video[0].id,
                participants: v.participants,
                title: v.video[0].title,
                description: v.video[0].description,
                url: v.video[0].link,
                image: v.video[0].image[0]
            }))
        }
    },
    methods: {
        excerpt,
        getVideoIndex(id) {
            return this.videos.findIndex(v => v.id === id)
        }
    },
    watch: {
        zoomIndex($v) {
            if (this.$refs.zoom)
                this.$refs.zoom.index = $v
        }
    }
}
</script>

<style lang="scss">
.b-videos {

    &__title {
        padding-bottom: var(--grid-gutter);
    }

    &__wrapper {

        @media #{md("xs")} {
            .c-card {
                grid-template-columns: 1fr 1fr;
                &__asset {
                    align-self: flex-start;
                }
            }
        }

        @media #{md("sm")} {
            .c-card {
                grid-template-columns: 1fr;
            }

            .c-card-grid__grid {
                grid-template-columns: 1fr !important;
            }
        }

        @media #{md("md")} {
            .c-card {
                grid-template-columns: 1fr 1fr !important;
            }
        }
        @media #{md("lg")} {
            .c-card {
                &__asset {
                    align-self: center;
                }
            }
        }
    }

    .-video {
        &-before-content {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    }

    .-participants {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .-title {
        font-size: fs("medium");
        font-weight: 400;
    }


    .c-card__asset {
        .-play {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            .o-icon {
                --svg-width: 2.25rem;
                transition: transform .3s ease, color .4s ease;
                will-change: transform;
                width: fit-content;
                height: fit-content;
                padding: 1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0.9);
                border-radius: 50%;
                color: var(--color-dark);
                background: white;

                svg {
                    transform: translateX(.1rem);
                }
            }
        }

        &:hover {
            .-play {
                .o-icon {
                    transform: translate(-50%, -50%) scale(1);
                    color: var(--body-bg-color);
                }
            }
        }
    }


    .o-tag {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
}

</style>
