<template>
    <div
        v-if="projects.length"
        class="c-home-projects"
    >
        <div class="c-home-projects__top">
            <h2 class="t-t3">Projets</h2>
            <btn
                v-if="!isSlider"
                label="Voir les projets"
                bg-color="light"
                bgHoverColor="beige"
                :is-shaped="true"
                tag="router-link"
                :url="SECTION_HANDLES.PROJECTS|resolver"
                itemprop="url"
                exact
            />
        </div>
        <div class="c-home-projects__grid">
            <card-grid
                ref="projectSlider"
                :cards="projects"
                :slider="true"
                card-type="project"
                card-model="enhance"
                sliderModifier="nav-on-top"
                @sliderActive="sliderChange"
            >
                <template #afterCardNav>
                    <div class="c-slider__nav--after">
                        <btn
                            label="Voir les projets"
                            bg-color="light"
                            bgHoverColor="beige"
                            :is-shaped="true"
                            tag="router-link"
                            :url="SECTION_HANDLES.PROJECTS|resolver"
                            itemprop="url"
                            exact
                        />
                    </div>
                </template>
            </card-grid>
        </div>

        <div class='c-home-projects__bg'>
            <span class="shape-block"></span>
            <span class="shape-block"></span>
            <span class="shape-block"></span>
        </div>
    </div>
</template>

<script>

import Btn from 'components/Btn'
import CardGrid from 'components/CardGrid'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'HomeProjects',
    components: {
        Btn,
        CardGrid
    },
    props: {
        projectIds: {
            type: Array,
            default: () => [],
            validator: ids => ids.length == 3
        }
    },
    data: () => ({
        SECTION_HANDLES,
        projects: [],
        isSlider: false
    }),
    created() {
        const options = {
            fixedOrder: true,
            ids: this.projectIds
        }

        this.$store.dispatch('loader/startLoad')

        this.$store.dispatch('project/searchEntries', options)
            .then(res => {
                this.projects = res
                this.$store.dispatch('loader/endLoad')
            }
        )
    },
    methods: {

        sliderChange(state) {
            this.isSlider = state
        },
    }
}
</script>

<style lang="scss">

.c-home-projects {
    padding: calc(2 * var(--grid-gutter)) 0;

    //BG
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(-2 * var(--grid-gutter));
        z-index: -1;
        width: 150%;
        height: 100%;
        background: $color-yellow-light;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin-bottom: var(--grid-gutter);
    }

    &__grid {

        .c-card-grid {
            z-index: 20;

            .c-slider {
                --slide-width: min(35rem, calc(100vw - 4 * var(--grid-gutter))) !important;
            }

            // .c-card-grid.-no-slider .c-card-grid__grid
            .c-card-grid__grid {
                --grid-min-width: 35rem;
            }
        }

        .c-card.-project {
            z-index: 20;
            background: $color-light;
            border-color: transparent;
        }
    }

    &__bg {
        --block-size: clamp(14rem, 25vw, 30rem);
        position: absolute;
        left: calc(-1 * var(--container-gutter));
        bottom: 0;
        display: grid;
        grid-template-columns: repeat(4, var(--block-size));
        grid-template-rows: repeat(2, var(--block-size));
        width: calc(100% + 2 * var(--container-gutter)) ;
        height: calc(2 * var(--block-size));

        .shape-block {
            background: var(--color-yellow);

            &:last-of-type {
                grid-column: 2/3;
                grid-row: 2/3;
            }

            &:nth-of-type(2) {
                width: 100%;
                border-top-right-radius: 60%;
            }

            @media #{md('xs')} {
                &:last-of-type {
                    grid-column: 3/4;
                }

                &:nth-of-type(2) {
                    width: 200%;
                    border-top-right-radius: 60%;
                }
            }
        }
    }
}

</style>
