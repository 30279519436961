<template>
    <div>
        <app-section v-if="page">
            <app-builder
                :sections="page.sections"
                :displayAnchors="page.hasAnchors"
            >
                <template #beforeSections>
                    <tag
                        v-if="projectState"
                        :label="projectState"
                        :text-color="page.colour"
                        :outline="true"
                    />
                    <div 
                        v-if="page.subjects && page.subjects.length"
                        class="-project-subjects"
                    >
                        <icon 
                            icon="project-subject-icon"
                            :style="`color: var(--color-${page.colour})`"
                        />
                        <tag
                            v-for="(subj, i) in page.subjects"
                            :key="subj.title"
                            :label="i != page.subjects.length - 1 ? subj.title + ' /' : subj.title"
                            :text-color="page.colour"
                            class="-project-subject__tag"                       
                        />
                    </div>
                </template>
            </app-builder>
        </app-section>
    </div>
</template>

<script>
import AppBuilder from 'layout/AppBuilder'
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'
import Tag from '../objects/Tag.vue';
import Icon from '../objects/Icon.vue';

export default {
    name: 'Project',
    components: {
        AppBuilder,
        AppSection,
        Tag,
        Icon
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    }),
    computed: {
        projectState() {

            if (this.page.state) {

                const translation = {
                    ongoing: 'Projet en cours',
                    done: 'Projet terminé',
                    upcoming: 'Projet à venir'
                }

                return translation[this.page.state]
            }
        },
    }
};

</script>

<style lang="scss">

.p-project {
    .-project-subjects {
        display: flex;
        align-items: center;
        .o-icon {
            padding-right: .7rem;
            height: fit-content;
        }
    }
    .-project-subject__tag {
        padding-left: 0;
        padding-right: 0.3rem;
    }
}

</style>
