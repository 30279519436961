<template>
    <div
        class="l-nav"
        ref="nav"
    >
        <button
            class="l-nav__toggle | o-btn-toggle"
            @click.stop="navIsOpen = !navIsOpen"
            aria-label="Ouvrir et fermer le menu"
            role="button"
            aria-pressed="false"
        >
            <span>Menu</span>
            <span class="menu-logo"> </span>
        </button>
        <div
            v-if="mainNav"
            class="l-nav__inner"
            :aria-hidden="navIsOpen"
        >
            <div class="l-nav__container" @click.stop >
                <div class="l-nav__top">
                    <h3
                        v-if="navHeading"
                        class="t-t5"
                        v-html="navHeading"
                    />
                    <div v-if="buttons" class="l-nav__top-actions | c-btn__group">
                        <btn
                            v-for="(button, i) in buttons"
                            :key="`home-header-btn-${i}`"
                            :tag="button.url == '#infolettre' ? 'router-link' : button.tag"
                            :url="button.url"
                            :label="button.label"
                            bg-color="light"
                            :is-shaped="button.isShape"
                            :iconAfter="button.iconAfter"
                        />
                    </div>
                </div>
                <nav
                    itemscope
                    itemtype="http://schema.org/SiteNavigationElement"
                >
                    <!-- <pre>{{ mainNav }}</pre> -->
                    <ul class="l-nav__list -level-1">
                        <li
                            v-for="(item_lvl1, i) in mainNav"
                            :key="i"
                            class="l-nav__item"
                            :style="`
                                --column-count: ${item_lvl1.children.length >= 4 ? 2 : 1}
                            `"
                            :data-columns="item_lvl1.children.length >= 4 ? 2 : 1"
                            itemprop="name"
                        >
                            <btn
                                :label='item_lvl1.title'
                                class="l-nav__link"
                                bg-color="transparent"
                            />

                            <!-- LVL 2 -->
                            <ul
                                v-if="item_lvl1.children"
                                class="l-nav__list -level-2"
                            >
                                <li
                                    v-for="(item_lvl2, i) in item_lvl1.children"
                                    :key="`level2-${i}`"
                                    class="l-nav__item"
                                    itemprop="name"
                                >
                                    <btn
                                        :tag="getNavNodeType(item_lvl2)"
                                        :url="getNavNodeTo(item_lvl2)"
                                        :forceFrame="true"
                                        :title='item_lvl2.title'
                                        class="l-nav__link"
                                        :class="{'-has-level3': item_lvl2.children.length }"
                                        :data-id="item_lvl2.children.length ? item_lvl2.id : false"
                                        exact
                                        itemprop="url"
                                        @click.native.stop="handleLevel2Click(item_lvl2)"
                                        :label="item_lvl2.title"
                                        :is-shaped="true"
                                        :icon-after="item_lvl2.children.length ? 'arrow-down' : ''"
                                    />

                                    <!-- LVL 3 -->
                                    <ul
                                        v-if="item_lvl2.children && item_lvl2.children.length"
                                        class="l-nav__list -level-3"
                                    >
                                        <li
                                            v-for="(item_lvl3, i) in item_lvl2.children"
                                            :key="`level3-${i}`"
                                            class="l-nav__item"
                                            itemprop="name"
                                        >
                                            <btn
                                                :tag="getNavNodeType(item_lvl3)"
                                                :url="getNavNodeTo(item_lvl3)"
                                                :forceFrame="true"
                                                :title='item_lvl3.title'
                                                class="l-nav__link link"
                                                exact
                                                itemprop="url"
                                                :is-shaped="true"
                                                :label="item_lvl3.title"
                                                :style="`--index: ${i}`"
                                            />
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Btn from 'components/Btn.vue'

export default {
    name: 'AppNav',
    components: {
        Btn,
    },
    data: () => ({
        currentScroll: 0,
        navIsOpen: false,
        navWidth: 0,
        navHeight: 0,
        navInnerHeight: null,
        navLinks: () => []
    }),
    mounted() {
        //this.$nextTick(() => {
        setTimeout(() => {

            this.setNavDimensions()
        }, 300)

        // Add Window events
        window.addEventListener('resizeEnd', this.setNavDimensions);
    },
    computed: {
        ...mapState({
            mainNav: state => state.global.navs.main,
            navHeading: state => state.global.settings.navHeading,
            buttons: state => state.global.settings.buttons,
        })
    },
    methods: {
        openNav() {
            this.navIsOpen = true

            this.currentScroll = window.scrollY
            document.documentElement.style.overflowY = 'hidden';
        },
        closeNav() {
            if (!this.navIsOpen) {
                return
            }
            this.navIsOpen = false

            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)
        },
        handleLevel2Click({ children, id}) {
            const hasLevel3 = children.length
            if (!hasLevel3) return

            const btn = this.$el.querySelector(`[data-id="${id}"]`)

            btn.classList.toggle('level-3-open')
        },
        closeLevel3() {
            const level3 = document.querySelector('.level-3-open')
            if (level3) {
                level3.classList.remove('level-3-open')
            }
        },
        setNavDimensions() {
            const toggle = document.querySelector('.l-nav__toggle')
            this.navWidth = toggle.offsetWidth
            this.navHeight = toggle.offsetHeight

            document.body.style.setProperty('--nav-trigger-width', this.navWidth + 'px')
            document.body.style.setProperty('--nav-trigger-height', this.navHeight + 'px')
        },
        setNavInnerHeight(inner) {
            // Open the nav (behind the scene) to get the nav-inner height
            this.openNav()

            this.$nextTick(() => {
                this.navInnerHeight = inner.offsetHeight

                this.$el.style.setProperty('--nav-inner-height', this.navInnerHeight + 'px')
                this.closeNav()
            })
        },
        getNavNodeType(node) {

            if (node.type == "craft\\elements\\Entry") {

                if (node.children && node.children.length) {
                    return 'button'
                } else {
                    return 'router-link'
                }
            } else {
                if (node.uri.indexOf('#') > -1) {
                    return 'router-link'
                } else {
                    return 'a'
                }
            }
        },
        getNavNodeTo(node) {

            if (node.type == "craft\\elements\\Entry") {

                return `/${node.uri }` || `/`

            } else {
                return node.url
            }
        }
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggle', isOpen)

            if(!isOpen) {
                this.closeLevel3()
                window.removeEventListener('click', this.closeLevel3)
                window.removeEventListener('click', this.closeNav)
            }
            else {
                this.$nextTick(() => {
                    window.addEventListener('click', this.closeLevel3)
                    window.addEventListener('click', this.closeNav)
                })
            }
        },
        $route() {
            this.closeNav()
        },
        mainNav: {
            handler() {
                this.$nextTick(() => {
                    const inner = document.querySelector('.l-nav__inner')

                    if (inner) {
                        this.setNavInnerHeight(inner)
                    }


                    this.navLinks = document.querySelectorAll('.l-nav__link')
                    this.navLinks.forEach((item, idx) => item.style.setProperty('--index', idx))
                })
            },
            immediate: true
        }
    },
    destroyed() {

        // Remove Window events
        window.removeEventListener('resizeEnd', this.setNavDimensions);
        window.removeEventListener('click', this.closeLevel3);
    },
};

</script>

<style lang="scss">
.l-nav {
    --nav-radius: #{$border-radius-md};
    --nav-radius-alt: calc( var(--nav-radius) + 0.1rem);
    --transition-delay: 0s;

    @media #{md("sm")} {
        --nav-radius: #{$border-radius-lg};
    }
}

.l-nav__toggle {
    font-size: fs("small");
    font-weight: 600;
    padding: 1.5rem 2.5rem;
    border-bottom-left-radius: var(--nav-radius);
    background-color: var(--body-bg-color);
    display: inline-flex;
    align-items: baseline;

    // UX
    user-select: none;
    cursor: pointer;
    z-index: 1;

    transition: border-radius .3s ease;

    &:focus,
    &:active,
    &:hover {
        border-bottom-left-radius: calc(  var(--nav-radius) * 0.8 );

        .menu-logo:before {
            width: 100%;
        }
    }

    @media #{md("sm")} {
        font-size: fs("large");
        padding: 2.4rem 3.5rem;
    }

    .menu-logo {
        width: 2rem;
        height: .8rem;
        display: inline-block;
        margin-left: 1.5rem;

        &:before,
        &:after {
            @include pseudo-el($width: 60%, $height: .2rem);
            position: absolute;
            background: $color-dark;
            right: 0;
            transition: width .3s $in-quad, transform .4s $out-quad;

        }

        &:after {
            bottom: 0;
            width: 100%
        }

        .nav-is-open & {
            &:before {
                width: 100%;
                transform:  rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg) translate(.4rem, -.4rem);
            }
        }
    }
}

.l-nav__inner {
    position: absolute;
    top:0;
    right: 0;
    z-index: 0;

    height: var(--nav-trigger-height);
    background-color: var(--body-bg-color);
    border-bottom-left-radius: var(--nav-radius);
    width: 100%;
    transition-delay: var(--transition-delay);
    transition: width .8s, height .5s;

    .nav-is-open & {
        width: 100vw;
        padding-left: var(--grid-gutter);
        height: var(--nav-inner-height);
        transition: width .8s, height .5s;

        @media #{md('sm')} {
            width: 70rem;
        }
    }

    &:before,
    &:after {
        @include pseudo-el($width: var(--nav-radius), $height: var(--nav-radius));
        position: absolute;
        z-index: 150;
    }
    &:before {
        top: 0;
        left: calc( var(--nav-radius) * -1 );
        background: radial-gradient(circle at bottom left,rgba(var(--body-bg-color-rgb), 0) var(--nav-radius), rgba(var(--body-bg-color-rgb), 1) var(--nav-radius-alt));
    }
    &:after {
        bottom: calc( var(--nav-radius) * -1 );
        right: 0;
        background: radial-gradient(circle at bottom left,rgba(var(--body-bg-color-rgb), 0) var(--nav-radius), rgba(var(--body-bg-color-rgb), 1) var(--nav-radius-alt));
    }

}

.l-nav__container {
    padding: var(--grid-gutter);
    padding-bottom: calc(2 * var(--grid-gutter));
    width:0;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0;
    // transition: opacity 0s ease, height .5s, width 0.5s ;
    pointer-events: none;

    @media #{md('sm')} {
        padding-bottom: var(--grid-gutter);
    }

    .nav-is-open & {
        padding-top: var(--container-gutter);
        width: auto;
        // height: 100%;
        opacity: 1;
        transition: opacity 0.3s ease calc(var(--transition-delay) + .5s);
        pointer-events: all;
    }

    .l-nav__top {
        padding: var(--grid-gutter) 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--grid-gutter);
        border-bottom: 1px solid currentColor;
        overflow: hidden;
        transition-delay: calc(var(--transition-delay) + .6s);

        &-actions {
            display: grid;
            gap: var(--grid-gutter-half);
            justify-self: start;
            grid-template-columns: 1fr 1fr;
            height: fit-content;
            white-space: pre;
            overflow: hidden;

            .c-btn{
                --hover-color: var(--color-beige) !important;
            }
        }

        & + nav {
            padding-top: var(--grid-gutter);
        }

        @media #{md('sm')} {
            padding-top: 0;
            grid-template-columns: auto min-content;
        }

        > * {
            transform: translateY(100%);
            opacity: 0;
        }
    }
}
.l-nav__list {
    @include reset-list;

    &.-level-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--grid-gutter);

        > .l-nav__item {

            width: 100%;
            padding-bottom: var(--grid-gutter-half);

            &[data-columns="2"] {
                grid-column: 1/3;

                .-level-2 {
                    column-count: var(--column-count);
                }

                @media #{md('sm')} {
                    grid-column: auto;
                    grid-row: 1/3;
                    grid-auto-flow: column dense;

                    .-level-2 {
                        column-count: 1;
                    }
                }
            }

            > .l-nav__link {
                width: 100%;
                font-weight: 600;
                position: static;
                pointer-events: none;
                padding-left: 0;

                &:after {
                    @include pseudo-el;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    transform: translateY(2rem);
                    background: currentColor;
                }
            }
        }
    }

    &.-level-2 {
        margin-top: 2rem;

        & > .l-nav__item {
            & > .l-nav__link {
                font-size: fs("regular");

                @media #{md("md")} {
                    font-size: fs("large");
                }
            }
        }

        .l-nav__link {
            font-weight: 400;
            justify-content: space-between;
            width: calc(100% - 2rem);
            white-space: pre;
        }

        .svg-shape  {
            //Override default transform
            transform: translateX(calc(99%)) !important;
        }

        .o-bg-shape {
            --bg-color: transparent;
            --hover-color: var(--color-light);
        }

        @media #{md('sm', 'max')} {
            .l-nav__item {
                position: static;
            }
        }

        .-has-level3 {

            & + .l-nav__list {
                position: absolute;
                background: white;
                z-index: 10;
                left: 0;
                font-size: fs('small');
                top: calc(100% + 1rem);
                padding: 2rem;
                border-radius: $border-radius-xs;
                transform: scaleY(0);
                transform-origin: left top;
                transition: .5s ease;
                pointer-events: none;

                .l-nav__item {
                    overflow: hidden;
                    padding-top: 0;

                    .c-btn {
                        transform: translateY(100%);
                       transition: .45s ease;
                    }
                }

                .link {
                    overflow: visible;
                    padding-left: 0;
                    padding-right: 0;
                    width: fit-content;
                }

            }

            &.level-3-open {
                .o-bg-shape {
                    --bg-color: var(--hover-color);
                }

                & + .l-nav__list {
                    transform: scaleY(1) !important;
                    opacity: 1;
                    pointer-events: all;

                    .c-btn {
                        animation: reveal 0.6s forwards;
                        animation-delay: calc(var(--index) * 0.1s);
                        animation-timing-function: $out-quad;

                    }
                }
            }
        }
    }

    @keyframes reveal {
        100%{
            transform: translateY(0);
            opacity: 1;
        }
    }
    @keyframes revealOnX {
        100%{
            transform: translateX(0);
            opacity: 1;
        }
    }
}

//Animations on Openning
.nav-is-open {
    .l-nav__top {

        > * {
            animation: reveal forwards;
            animation-duration: .8s;
            animation-delay: .4s;
        }

        &-actions {
            animation-delay: .5s;
        }
    }

    .l-nav__link {

        > * {
            animation: reveal forwards;
            animation-duration: .4s;
            animation-delay: calc(.8s);
            animation-timing-function: $in-out-quad;
        }
    }
}

.l-nav__link {
    cursor: pointer;

    > * {
        opacity: 0;
        transform: translateY(100%);
    }

    @media #{md('sm')} {
        > * {
            transform: translateX(100%);
        }
    }

    &.is-current {}
}


</style>
