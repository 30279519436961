<template>
    <div class="b-embed">
        <iframe :src="data.url" scrolling="no" frameborder="no" />
    </div>

</template>

<script>

export default {
    name: 'BlockEmbed',
    components: {
    },
    props: {
        data: false
    },
    computed: {
    }
}
</script>

<style lang="scss">
.b-embed {
    & > iframe,
    & > object {
        width: 100%;
        max-width: 90rem;
        min-height: 45rem;
    }
}
</style>
