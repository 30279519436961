<template>
    <div
        class="l-elements"
        :class="{
            'has-filters': hasFilters,
            'is-loading': isBlocked
        }"
    >
        <div class="l-elements__filters" v-if="hasFilters && ready">

            <form>
                <label  v-if="displayLimit" for="limit">
                    <h4>Limite</h4>
                    <input
                        id="limit"
                        type="number"
                        min="1"
                        class="o-input"
                        placeholder="Limite"
                        v-model="searchOptions.limit"
                    >
                </label>
                <div
                    v-for="(group) in categoryGroups"
                    class="l-elements__filter-group"
                    :class="`group-${group.group}`"
                    :key="`group-${group.group}`"
                >
                    <div v-if="filters[group.group].length">

                        <btn
                            :label="filterLabel(group)"
                            icon-after="arrow-down"
                            ref="filterLabel"
                            type="button"
                            class="c-btn__filter"
                            :data-filter-btn="group.group"
                            @click.native.prevent.stop="toggleFilterPanel(group.group)"
                        />
                        <!-- Dropdown -->
                        <div
                            v-if="group.type == 'dropdown'"
                            class="filter-items__wrapper"
                            @click.stop=""
                        >
                            <div class="l-elements__filter-inner">
                                <h1 class="t-t7">{{ group.label }}</h1>
                                <div
                                    class="l-elements__filter-items"
                                    :class="`-${group.type} `"
                                    ref="categories"
                                >
                                    <select
                                        :name="group.group"
                                        :ref="group.group"
                                        @change="toggleCheck({
                                            id: $event.target.value,
                                            group: group.group,
                                            type: group.type
                                        })"
                                    >
                                        <option value="0">
                                            Faites un choix...
                                        </option>
                                        <option
                                            v-for="(category, j) in filters[group.group]"
                                            :key="`category-${j}`"
                                            :value="category.id"
                                        >
                                            {{ category.title }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- Radio/Checkbox -->
                        <div
                            v-else
                            class="filter-items__wrapper"
                            @click.stop=""
                        >
                            <div class="l-elements__filter-inner">
                                <h1 class="t-t7">{{ group.label }}</h1>
                                <div
                                    class="l-elements__filter-items"
                                    :class="`-${group.type}`"
                                >
                                    <div
                                        v-for="(category, j) in filters[group.group]"
                                        :key="`category-${j}`"
                                        ref="categories"
                                    >
                                        <!-- Radio -->
                                        <template v-if="group.type === 'radio'">
                                            <input
                                                class="o-input"
                                                type="radio"
                                                :value="category.id"
                                                :id="`category-${category.id}`"
                                                :ref="group.group"
                                                @change="toggleCheck({
                                                    id: category.id,
                                                    value: category.id,
                                                    group: group.group,
                                                    type: group.type
                                                })"
                                                :name="group.group"
                                            />
                                            <label :for="`category-${category.id}`">{{ category.title }}</label>
                                        </template>
                                        <!-- Checkbox -->
                                        <template v-else-if="group.type=== 'checkbox'">
                                            <input
                                                class="o-input"
                                                type="checkbox"
                                                :value="category.id"
                                                :id="`category-${category.id}`"
                                                :ref="group.group"
                                                @change="toggleCheck({
                                                    id: category.id,
                                                    value: $event.target.checked,
                                                    group: group.group,
                                                    type: group.type
                                                })"
                                                :name="`group-${group.group}-${category.handle}`"
                                            />
                                            <label :for="`category-${category.id}`">{{ category.title }}</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Text -->
                <label
                    v-if="displayTextSearch"
                    for="text"
                    class="l-elements__search | o-label"
                >
                    <input
                        id="text"
                        type="text"
                        class="l-elements__search | o-input"
                        placeholder="Rechercher par mot-clé"
                        v-model="searchOptions.searchText"
                    >
                </label>
                <!-- Buttons -->
                <div class="l-elements__filter-buttons">
                    <btn
                        label="Soumettre"
                        bg-color="beige"
                        type="submit"
                        @click.prevent.native="updateSearchQuery(false)"
                    />
                    <btn
                        label="Réinitialiser"
                        type="button"
                        bg-color="light"
                        @click.prevent.native="reset"
                        class="c-btn__reset"
                    />
                </div>
            </form>
        </div>
        <div class="l-elements__list" v-if="ready">
            <div v-if="$slots.listHeader" class="l-elements__list-header">
                <slot name="listHeader">
                </slot>
            </div>

            <slot name="elements" v-bind="sortedElements">
                <!-- Default template -->
                <card-grid
                    :cards="sortedElements"
                    :cardType="cardType"
                    :cardModel="cardModel"
                >
                    <!-- Before -->
                    <template #beforeCard="card">
                        <slot name="beforeCard" v-bind="card" />
                    </template>

                    <!-- Asset -->
                    <template #beforeCardAsset="card">
                        <slot name="beforeCardAsset" v-bind="card" />
                    </template>
                    <template #cardAsset="card">
                        <slot name="cardAsset" v-bind="card" />
                    </template>
                    <template #afterCardAsset="card">
                        <slot name="afterCardAsset" v-bind="card" />
                    </template>

                    <!-- Content -->
                    <template #beforeCardContent="card">
                        <slot name="beforeCardContent" v-bind="card" />
                    </template>
                    <template #cardContent="card">
                        <slot name="cardContent" v-bind="card" />
                    </template>
                    <template #afterCardContent="card">
                        <slot name="afterCardContent" v-bind="card" />
                    </template>

                    <!-- Router link -->
                    <template #cardLink="card">
                        <slot name="cardLink" v-bind="card" />
                    </template>

                    <!-- After -->
                    <template #beforeAfter="card">
                        <slot name="beforeAfter" v-bind="card" />
                    </template>

                </card-grid>
            </slot>

            <pagination
                ref="pagination"
                :total="totalElements"
                :perPage="currentPerPage"
                :currentPage="defaultPage"
                :pageRange="pageRange"
                @displayAll="displayAllElements"
                @pageChange="getElementsByPage"
                @block="isBlocked = true"
                :isBlocked="isBlocked"
            />

        </div>
    </div>
</template>

<script>
import Btn from 'components/Btn'
import CardGrid from 'components/CardGrid'
import Pagination from 'components/Pagination'

import { mapState } from 'vuex'
import _ from 'lodash'

export default {
    name: 'AppElements',
    components: {
        Btn,
        CardGrid,
        Pagination
    },
    props: {
        categoryGroups: {
            type: Array,
            default: () => []
        },
        displayTextSearch: {
            type: Boolean,
            default: false
        },
        displayLimit: {
            type: Boolean,
            default: false
        },
        pageRange: {
            type: Number,
            default: 1,
        },
        sectionHandle: {
            type: String,
            required: true
        },
        cardType: String,
        cardModel: {
            type: String,
            default: 'standard',
            validator: (model) => {
                return ['horizontal', 'enhance', 'standard'].indexOf(model) !== -1
            }
        },
        options: {
            type: Object,
            default: () => {}
        },
        excludeIds: {
            type: Array
        }
    },
    data: () => ({
        ready: false,
        filters: {},
        filteredElements: [],
        activeFilters: { },
        searchOptions: {
            limit: 12,
            offset: 0,
            relatedToCategories: [],
            searchText: '',
            order: {
                key: 'postDate',
                direction: 'DESC'
            }
        },
        defaultPerPage: 0,
        currentPerPage: 0,
        defaultPage: 1,
        isBlocked: false,
        filterPanelIsOpen: false,
    }),
    created() {

        // Increase loader value
        this.$store.dispatch('loader/startLoad')

        // Merge options
        this.searchOptions = {
            ...this.searchOptions,
            ...this.options
        }

        // Keep track of the default pagination
        this.defaultPerPage = this.searchOptions.limit
        this.currentPerPage = this.defaultPerPage

        // Look if there's a targeted page
        const page = parseInt(this.$route.query.page)
        if (page && page > 1) {
            this.defaultPage = page
            this.searchOptions.offset = (page - 1) * this.searchOptions.limit
        }

        // Build category queries
        let categoriesQueries = this.categoryGroups.map(category => {

            this.$set(this.activeFilters, category.group, [])

            return this.$store.dispatch(
                this.sectionHandle + '/getCategoryGroup',
                { group: category.group, sections: [this.sectionHandle] }
            )
        })

        //
        const searchQuery = this.$store.dispatch(this.sectionHandle + '/searchEntries', this.searchOptions)

        // Get categories and first entries query
        Promise.all([
            ...categoriesQueries,
            searchQuery
        ]).then((results) => {

            // Create a key for each single group under `this.filters`
            results.forEach((res, i) => {

                if (this.categoryGroups[i]) {
                    let groupHandle = this.categoryGroups[i].group
                    this.filters[groupHandle] = res
                }
            })

            // Get searchQuery index
            const lastIndex = results.length - 1

            // Update elements following the searchQuery results
            this.filteredElements = results[lastIndex]

            // Mark the component as `ready`
            this.ready = true

            // Decrease loader value
            this.$store.dispatch('loader/endLoad')

        })

    },
    mounted() {
        document.addEventListener('click', this.closeFilterPanel)
    },
    computed: {
        ...mapState({
            categoryGroupsFromStore(state) { return state[this.sectionHandle].categoryGroups},
            elements(state) { return state[this.sectionHandle].elements},
            totalElements(state) { return state[this.sectionHandle].resultsCount}
        }),
        hasFilters () {
            return this.categoryGroups.length
        },
        searchPagesCount () {
            if (this.searchOptions.limit && this.searchOptions.limit != 0) {
                return Math.ceil(this.totalElements / this.searchOptions.limit)
            }
            else return 1
        },
        sortedElements() {

            if (!this.filteredElements || this.filteredElements.length === 0)
                return []

            const orderKey = this.searchOptions.order.key
            const orderDirection = this.searchOptions.order.direction

            if (orderDirection == 'DESC')
                return [...this.filteredElements].sort((a, b) => (a[orderKey] < b[orderKey]) ? 1 : -1)

            return [...this.filteredElements].sort((a, b) => (a[orderKey] > b[orderKey]) ? 1 : -1)
        }
    },
    methods: {
        closeFilterPanel() {

            if(this.filterPanelIsOpen) {

                // this.buttonClicked and this.container should exist when this function fires
                this.filterPanelIsOpen = false
                this.buttonClicked.classList.remove('-is-focus')
                this.container.classList.remove('-is-open')
            }
            return
        },
        displayAllElements() {
            this.searchOptions.limit = this.totalElements
            this.currentPerPage = this.totalElements
            this.updateSearchQuery(false)
        },
        filterLabel({ label, group }) {
            let length = this.activeFilters[group].length
            let filterLabel = label.toLowerCase()

            filterLabel += length !== 0 ? ` (${length})` : ''

            return `Filtrer par ${filterLabel}`
        },
        getElements() {

            this.isBlocked = true
            this.$store.dispatch('loader/setOverride', true)

            let position = this.$el.getBoundingClientRect().top;

            window.scrollBy({
              top: position - 50,
              left: 0,
              behavior: 'smooth'
            })

            this.$store.dispatch(this.sectionHandle + '/searchEntries', this.searchOptions)
                .then(results => {
                    this.filteredElements = results
                    this.isBlocked = false
                    this.$store.dispatch('loader/setOverride', false)
                })

        },
        getElementsByPage(page) {

            this.isBlocked = true
            this.searchOptions.offset = (page - 1) * this.searchOptions.limit
            this.updateSearchQuery(true)
        },
        reset() {

            // Reset filters
            Object.keys(this.filters).forEach(filter => {

                this.$set(this.activeFilters, filter, [])

                //Uncheck all the inputs
                if ( this.$refs[filter] ) {

                    this.$refs[filter].forEach(input => {
                        input.checked = false
                        input.selectedIndex = 0
                    })
                }
            })

            // Reset search history
            this.lastOptionsFetched = null

            this.currentPerPage = this.defaultPerPage

            // Reset pagination
            this.$refs.pagination.reset()

            // Reset options
            this.searchOptions.limit = this.defaultPerPage
            this.searchOptions.searchText = ''
            this.searchOptions.offset = 0
            this.searchOptions.relatedToCategories = []

            this.getElements()
        },
        toggleCheck(selected) {

            let {
                id,
                group,
                type,
                value
            } = selected

            let filterGroup = new Set(this.activeFilters[group])

            switch (type) {
                case 'radio':
                case 'dropdown': {
                    // Clear the field + reset to new value
                    filterGroup.clear()
                    filterGroup.add(id)

                    break
                }

                default : {
                    // Add to filterGroup if value is true
                    if (value) {
                        filterGroup.add(id)
                    }
                    else {
                        filterGroup.delete(id)
                    }
                }
            }

            // Update activeFilters
            this.$set(this.activeFilters, group, [...filterGroup])

            // console.log('activeFilters', this.activeFilters, filterGroup)
        },
        toggleFilterPanel(group) {

            this.buttonClicked = this.$el.querySelector(`[data-filter-btn="${group}"]`)

            this.container = this.buttonClicked.nextSibling
            this.filterPanelIsOpen = !this.filterPanelIsOpen

            this.buttonClicked.classList.toggle('-is-focus')
            this.container.classList.toggle('-is-open')

        },
        updateSearchQuery(keepPage) {

            this.isBlocked = true

            if (keepPage === false) {
                // Reset to page 1 when making a new search
                this.searchOptions.offset = 0
                if(this.$refs.pagination) {
                    this.$refs.pagination.reset()
                }
            }

            const relatedToCategories = []

            Object.keys(this.filters).forEach(group => {

                const ids = [...this.activeFilters[group]].map(id => parseInt(id))

                if (ids.length) {
                    let category = {
                        group: [group],
                        id: ids
                    }
                    relatedToCategories.push(category)
                }
            })

            this.searchOptions.relatedToCategories = relatedToCategories

            // De-structure to break reactivity
            const newOptions = { ...this.searchOptions },
                  isEqual = _.isEqual(newOptions, this.lastOptionsFetched)

            // Fetch Only if options have changed
            if ( !isEqual ) {
                this.getElements()
                this.lastOptionsFetched = newOptions
            } else {
                this.isBlocked = false
            }
        },
    },

    destroyed() {
        document.removeEventListener('click', this.closeFilterPanel)
    },
}
</script>

<style lang="scss">
.l-elements {
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--grid-gutter));

    .l-elements__list {
        transition: opacity 0.3s ease;
    }

    .c-card {
        pointer-events: all;

        .o-tag {
            transition: opacity 0.1s ease;
        }

        .c-card__asset {

            picture {
                display: block;
                background: $color-beige;
            }
            img {
                transition: opacity 0.1s ease;
            }
        }

        .c-card__content {

            .c-card__content-before > *, .t-content {
                border-radius: $border-radius-xs;
                transition: color 0.1s ease, background 0.1s ease;
            }
        }
    }
    &.is-loading {

        .l-elements__list {
            opacity: 0.5;
        }

        .o-tag {
            opacity: 0;
            transition-duration: 0.3s;
        }

        .c-card {
            pointer-events: none;
        }

        .c-card__asset{
            @include skeleton($color: $color-beige, $duration: 1.5s);

            &:before {
                border-radius: $border-radius-md;
            }
        }

        .c-card__content {

            .c-card__content-before > *, .t-content {
                // background: $color-beige;
                // color: $color-beige;
                // transition-duration: 0.3s;
                @include skeleton($color: $color-beige)
            }
        }
    }

    &.has-filters {

        .l-elements__filters {
            width: 100%;
            justify-self: center;

            @media #{md('xs')} {
                padding-right: var(--safe-nav-padding);
            }
        }

        .l-elements__list {
            margin-top: var(--grid-gutter);
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            margin-left: -1rem;

            @media #{md('md')} {
                flex-direction: row;
                align-items: center;
            }

            h4 {
                margin-bottom: 0.5rem;
            }

            .l-elements__filter-buttons {
                display: flex;

                > * + * {
                    margin-left: var(--grid-gutter-half);
                }
            }

            .l-elements__filter-items {
                display: flex;
                flex-direction: column;
                justify-content: left;
                gap: var(--grid-gutter-half);
                padding-top: var(--grid-gutter);
            }

            input + label,
            label + input {
                padding-left: var(--grid-gutter-half);
            }

            --filter-panel-bg: var(--color-beige);
            .l-elements__filter-inner {

                background: var(--filter-panel-bg);
                padding: calc(var(--grid-gutter));
                border-radius: $border-radius-md;
                transform: translateY(-100%);
                transition: transform 0.15s $out-quad;
            }

            .filter-items__wrapper {
                overflow: hidden;
                width: max-content;
                position: absolute;
                margin-top: var(--grid-gutter-half);
                pointer-events: none;

                &.-is-open {
                    pointer-events: all;
                    .l-elements__filter-inner {
                        transform: translateY(0);
                        z-index: 50;
                    }
                }
            }

            // Make the input text grow if there is space
            .o-label.l-elements__search {
                flex-basis: 0;
                flex-grow: 1;
                width: min(100%, 500px)
            }

            .o-input.l-elements__search {
                border: solid 1px $color-beige;
                padding: 1.9rem;
                border-radius: $border-radius-xs;
                width: 100%;
                display: block;
            }

            .o-input[type="checkbox"] {

                &:before, &:after {
                    @include pseudo-el($width: calc(100% + 2px), $height: calc(100% + 2px));
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    border-radius: $border-radius-xs;
                    background: var(--filter-panel-bg);
                }

                &:after {
                    border: solid 2px $color-dark;
                    transition: background-color .3s ease;
                }

                &:checked:after {
                    background: $color-dark;
                    z-index: 1;
                }
            }

            .o-input[type="radio"] {

                &:before, &:after {
                    @include pseudo-el($width: calc(100% + 4px), $height: calc(100% + 4px));
                    position: absolute;
                    background: var(--filter-panel-bg);
                    top: -2px;
                    left: -2px;
                    border: 2px solid $color-dark;
                }

                &:after {
                    border: 0;
                    transform: scale(.6);
                    transition: background-color .3s ease;
                }

                &:before, &:after {
                    border-radius: 50%;
                }

                &:checked:after {
                    background: $color-dark;
                }
            }

            ::placeholder {
                opacity: 1;
                font-weight: 600;
            }
        }

        .c-btn__filter {
            .svg-arrow-down {
                transition: transform 0.4s $out-sine;
            }

            // Keep the btn hover color when the filter panel is open
            &.-is-focus {

                .svg-arrow-down {
                    transform: rotate(-180deg);
                }

                > .c-btn__bg {
                    --bg-color: var(--hover-color);
                }
            }
        }

        .c-btn__reset {
            font-weight: 400;

            &:before {
                --hover-color: transparent !important;
                width: calc(100% - 2 * var(--btn-padding-y, 0) + 1px);
                height: 1px;
                background: currentColor;
                content: '';
                position: absolute;
                z-index: 1;
                left: var(--btn-padding-y);
                bottom: calc(var(--btn-padding-y, 0));
                transform: 0;
                transform-origin: 0;
                transition: transform 0.3s $out-sine;
            }

            &:hover:before {
                transform: scaleX(0);
                transform-origin: 100%;
                transition: transform 0.3s $in-quad;
            }
        }
    }

    &__list {
        width: 100%;
    }
}
</style>
