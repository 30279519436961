<template>
    <div
        v-if="entry"
        class="c-featured__entry"
        :style="style"
    >
        <asset
            :asset="entryImage"
            :shape="imgShape"
        >
            <template #beforeAsset v-if="displayBeforeAsset">
                <span class="deco-wrapper">
                    <span class="deco deco-1"></span>
                    <span class="deco deco-2"></span>
                    <span class="deco deco-3"></span>
                    <shape :shape="beforeAssetShape" :outiline="true"/>
                </span>
            </template>
        </asset>

        <component
            v-if="entryContent"
            :is="componentName"
            :element="entryContent"
            :model="model"
        />
    </div>
</template>

<script>

import Asset from '../objects/Asset.vue';
import CardProject from '../components/CardProject.vue';
import CardCampaign from '../components/CardCampaign.vue';
import Card from '../components/Card.vue';
import Shape from '../objects/Shape.vue';

export default {
    name: 'FeaturedEntry',
    components: {
        Asset,
        Shape,
        Card,
        CardProject,
        CardCampaign,
    },
    props: {
        entry: {
            type: Object,
            required: true
        },
        beforeAssetShape: {
            type: String,
        },
        displayBeforeAsset: {
            type: Boolean,
            default: true
        },
        imgShape: {
            type: String,
            default: 'pentagon-down'
        },
        cardType: {
            type: String,
            required: true,
            validator: card => ['project', 'campaign', 'default'].indexOf(card) !== -1
        },
        model : {
            type: String
        }
    },
    data:() => ({
        entryContent: null,
        entryImage: null
    }),
    computed: {
        style() {
            if(this.entry)
                return `--featured-color: var(--color-${this.entry.colour})`
        },
        componentName() {
            return this.cardType != 'default' ? `card-${this.cardType}` : `card`
        },
    },
    mounted() {
        if (this.entry) {
            const { image, imageAlt, ...content } = this.entry

            this.entryImage = image || imageAlt
            this.entryContent = content
        }
    }
}
</script>

<style lang="scss">
.c-featured__entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    //transform: translateY(calc(var(--container-gutter) * -1));

    .c-card {
        border: 0;
        margin-top: 0;
    }

    @media #{md('sm')} {
        flex-direction: row;
        justify-content: center;
        margin-left: calc(var(--container-gutter));

        .c-card.-enhance {
            width: calc(var(--grid-gutter) * 2 + 30rem);
            padding-left: var(--container-gutter);
            padding-right: 0;

            .c-card__link {
                left: var(--container-gutter);
            }
        }
    }

    @media #{md('md')} {
        margin-left: calc(var(--container-gutter) * 3);
    }

    .o-asset {
        overflow: visible;
        min-width: 25rem;
    }

    .deco-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;

        .deco {
            background: var(--featured-color);
            width: 100%;
            height: 100%;
            position: absolute;

            &-1 {
                left: -150%;
                top: -100%;
            }

            &-2 {
                left: -150%;
                top: 0;
                border-bottom-left-radius: 50%;
            }

            &-3 {
                left: -50%;
                top: 0;
            }
        }

    }

    .o-shape {
        left: -150%;

        .o-icon {
            width: 100%;
            height: 100%;
        }

        svg {
            --svg-width: 100% !important;
            color: $color-dark; // TMP ??
        }
    }
}
</style>
