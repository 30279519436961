<template>
    <card
        :element="element"
        :model="model"
        :color="element.colour"
        link-label="Consulter"
        asset-shape="pentagon-down"
    >

        <template #beforeAsset v-if="displayTagAfterAsset">
            <tag
                v-if="element.campaignType"
                :label="tagLabel"
                :text-color="element.colour"
            />
        </template>
        <template #beforeContent>
            <div class="c-card__before-content">
                <tag
                    v-if="element.edition"
                    :label="element.edition"
                    :outline="element.state ? true : false"
                    bg-color="beige"
                />
                <span
                    v-if="element.beginDate && element.endDate"
                    class="c-card__date"
                >
                    <span>{{ beginDate }}</span>
                    <span> au {{ endDate }}</span>
                </span>
            </div>
        </template>

        <template #content>
            <div class="t-content">
                <asset
                    v-if="element.logo"
                    :asset="element.logo"
                    class="c-card__logo"
                />
                <h1 v-else class="t-t5 c-card__title">{{ element.title }}</h1>
                <tag
                    v-if="element.campaignType && !displayTagAfterAsset"
                    :label="tagLabel"
                    :outline="true"
                    :text-color="element.colour"
                />
                <p v-html="elementDescription" v-if="elementDescription"/>
            </div>
        </template>

    </card>
</template>

<script>
import Asset from 'objects/Asset'
import Tag from 'objects/Tag'
import Card from 'components/Card'

import { excerpt, formatDate } from 'src/utils'

export default {
    name: 'CardCampaign',
    components: {
        Card,
        Tag,
        Asset,
    },
    props: {
        element: {
            type: Object,
            required: true
        },
        displayTagAfterAsset: Boolean,
        model: {
            type: String,
            default: 'enhance',
            validator: (model) => {
                return ['horizontal', 'enhance', 'standard'].indexOf(model) !== -1
            }
        }
    },
    computed: {
        beginDate() {
            let endYear = this.endDate.slice(-4),
                date  = formatDate(this.element.beginDate),
                beginYear = date.slice(-4)
                return endYear == beginYear
                    ? date.replace(beginYear, '')
                    : date
        },
        endDate() {
            return formatDate(this.element.endDate)
        },
        elementDescription() {
            return excerpt(this.element.description, 144)
        },
        tagLabel() {
            return excerpt(this.element.campaignType.title, 30)
        }
    }
};

</script>

<style lang="scss">

.c-card.-campaign {

    .c-card__logo {
        max-width: 45%;
    }

    .c-card__before-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .c-card__date {
            font-size: fs('small');
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            text-align: right;

            span:first-of-type{
                margin-right: 0.5ch;
            }
            span:last-of-type {
                white-space: nowrap;
            }

        }
        .o-tag + .c-card__date {
            margin-right: 1rem;
        }
    }
}

</style>
