import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'src/store'

// import view from './directives/view' // eslint-disable-line
import reveal from './directives/reveal' // eslint-disable-line
import { resizeEnd } from 'src/utils';
import 'src/filters'



// Plugins
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm';

// Add window resizeEnd event
resizeEnd()

// Add global resize listener
// Vue.use(resizeListener)

// Vars
Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === 'production';

// Meta descriptions
Vue.use(VueMeta, {})

// GTM
if ( isProd && process.env.VUE_APP_GTM ) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_cookies_win:'x'
        },
        defer: true,
        enabled: true,
        debug: false,
        vueRouter: router,
        loadScript: true,
        // ignoredViews: [''],
        // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}

// Initial load
store.dispatch('global/initLoad')

// Vue instance
new Vue({
    el: '#vue',
    router,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
