<template>
    <div
        v-if="asset"
        :class="className"
    >
        <div
            class="c-decos__asset"
        >
            <asset
                :asset="asset"
                :shape="customAssetShape"
                @loaded="$emit('imageLoaded')"
            />
        </div>
        <div
            v-if="customModel != 'top'"
            class="c-decos__pattern"
            :class="`-${customPattern}`"
        >

            <div class="c-decos__path">
                <span class="-path-1"></span>
                <span class="-path-2"></span>
                <span class="-path-3"></span>
            </div>

            <shape class="c-decos__shape -shape-1" :shape="randomShapes[0]" />
            <shape class="c-decos__shape -shape-2" :shape="randomShapes[1]" :outline="false" />
            <shape class="c-decos__shape -shape-3" :shape="randomShapes[2]" :outline="false" />

        </div>
        <shape
            v-else
            :shape="shape"
        />
    </div>
</template>

<script>
import Asset from 'objects/Asset'
import Icon from 'objects/Icon'
import Shape from 'objects/Shape'

import { SHAPES } from 'src/constants'
import { mapState } from 'vuex'

import { getRandom } from 'src/utils'

export default {
    name: 'HeaderMedias',
    components: {
        Asset,
        Icon,
        Shape
    },
    data: () => ({
        index: null,
        SHAPES,
        randomShapes: []
    }),
    props: {
        model: {
            type: String,
            validator: shape => {
                return [
                    'top',
                    'overlap'
                ].indexOf(shape !== -1)
            },
            default: 'top'
        },
        pattern: {
            type: String,
            validator: shape => {
                return [
                    'pattern-1',
                    'pattern-2'
                ].indexOf(shape !== -1)
            },
            default: 'pattern-1'
        },
        asset: Object,
        assetShape: {
            type: String,
            validator: shape => {
                return SHAPES.indexOf(shape !== -1)
            },
            default: 'pentagon-up'
        },
        shape: {
            type: String,
            validator: shape => {
                return SHAPES.indexOf(shape !== -1)
            },
            default: 'hexagon'
        }
    },
    created() {

        this.randomShapes = getRandom(this.SHAPES, 3);
    },
    computed: {
        ...mapState({
            page: state => state.global.currentPage
        }),
        currentSection() {
            return this.page.section || null
        },
        customAssetShape() {

            if (!this.currentSection)
                return

            if (this.currentSection == 'project')
                return 'pentagon-up'

            if (this.currentSection == 'campaign')
                return 'circle'

            return this.assetShape
        },
        customShape() {

            if (!this.currentSection)
                return

            // Conditions here

            return this.shape
        },
        customModel() {

            if (!this.currentSection)
                return

            // Conditions here
            if (this.currentSection == 'project' || this.currentSection == 'campaign')
                return 'overlap'

            return this.model
        },
        customPattern() {

            if (!this.currentSection)
                return

            // Conditions here
            if (this.currentSection == 'project')
                return 'pattern-2'

            if (this.currentSection == 'campaign')
                return 'pattern-1'

            return this.pattern
        },
        className() {
            let classes = 'c-decos'

            classes += ` -${this.customModel}`

            return classes
        }
    }
}
</script>

<style lang="scss">
.c-decos {
    --decos-path-width: 6rem;
    //transform: translateY(30%);
    //margin-bottom: -45%;

    /* &.-overlap {
        z-index: 0;
    } */

    @media #{md("sm")} {
        --decos-path-width: 8rem;
    }
    @media #{md("md")} {
        --decos-path-width: 9rem;
    }
    @media #{md("lg")} {
        --decos-path-width: 10rem;
    }


    .c-decos__asset {
        z-index: 2;
        height: calc( var(--header-medias-width) * 0.55 );
    }

    .o-shape {
        z-index: 0;
    }

    &.-top {
        --svg-width: var(--header-medias-width);

        .o-shape {
            position: absolute;
            top:0;
            left: calc(100% + var(--grid-gutter));

            @media #{md("md")} {
                top: calc( var(--header-medias-width) * -1 - (var(--grid-gutter) * 1.5));
                left: auto;
            }
        }
    }

    &.-overlap {

        .c-decos__asset {

            @media #{md("sm")} {
                right: var(--grid-gutter-half);
            }
        }
        .c-decos__pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc( var(--header-medias-width) * 1.25 );
            height: 100%;
            //outline: 2px dashed darkorchid;

            @media #{md("xxs")} {
                //outline: 2px dashed black;
            }

            @media #{md("xs")} {
                width: calc( var(--header-medias-width) * 1.5 );
                //outline: 2px dashed lime;
            }
            @media #{md("sm")} {
                width: calc( var(--header-medias-width) * 1.5 );
                //outline: 2px dashed red;
            }
            @media #{md("md")} {
                width: calc( var(--header-medias-width) * 1.15 );
                height: calc( var(--header-height) + var(--grid-gutter-half) );
            }

            @media #{md("lg")} {
                width: calc( var(--header-medias-width) * 1.3 );
            }
        }
    }

    .c-decos__pattern {
        --decos-height-ratio: calc(var(--header-medias-height) * var(--decos-ratio));
    }

    .c-decos__path {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;

        span {
            position: absolute;
            background-color: var(--body-alt-color);
        }
    }

    .c-decos__shape {
        --svg-width: var(--decos-path-width);
        position: absolute;
        will-change: transform;
        z-index: 1;

        &.-shape-1 {
            left: 0;
            color: $color-dark;
            transform: translateX( calc(var(--perc) * 100% * 2) );

            @media #{md("md")} {
                transform: translateY( calc(var(--perc) * 100% * -1 * 2) );
            }
            @media #{md("lg")} {
                left: 4%;
            }
            @media #{md("xl")} {
                left: 8%;
            }
        }
        &.-shape-2 {
            opacity: 0;

            @media #{md("md")} {
                left: 0;
                color: $color-light;
                transform: translateY( calc(var(--perc) * 100% * -1 / 2 ) );
                opacity: 1;
            }

            @media #{md("lg")} {
                left: 4%;
            }
            @media #{md("xl")} {
                left: 8%;
            }
        }
        &.-shape-3 {
            right: 0;
            color: $color-orange-light;
            transform: translateY( calc(var(--perc) * 100% * 1.25) );

            [data-color="yellow"] &,
            [data-color="yellow-light"] &,
            [data-color="orange"] &,
            [data-color="orange-light"] & {
                color: $color-blue;
            }
        }
    }

    .c-decos__pattern {
        pointer-events: none;
        .c-decos__path {

            .-path-1 {
                width: var(--decos-path-width);
                height: calc(var(--header-medias-height) * 2 );
                left:0;
                bottom: calc( var(--decos-height-ratio) + ( var(--decos-path-width) / 2 ));

                @media #{md("md", "max")} {
                    display: none;
                }

                @media #{md("md")} {
                }

                @media #{md("lg")} {
                    left: 4%;
                }
                @media #{md("xl")} {
                    left: 8%;
                }
            }
            .-path-2 {
                left:0;
                right: 0;
                height: var(--decos-path-width);
                border-top-right-radius: var(--decos-path-width);
                border-bottom-right-radius: var(--decos-path-width);

                @media #{md("sm")} {
                    left: -2.3%;
                }

                @media #{md("md")} {
                    left: 0;
                    border-radius: var(--decos-path-width);
                }

                @media #{md("lg")} {
                    left: 4%;
                }
                @media #{md("xl")} {
                    left: 8%;
                }
            }
            .-path-3 {
                right:0;
                width: var(--decos-path-width);
            }
        }

        // PATTERN 2
        &.-pattern-2 {
            --decos-ratio: 0.2;

            @media #{md("md")} {
                --decos-ratio: 0.25;
            }
            @media #{md("lg")} {
                --decos-ratio: 0.2;
            }

            @media #{md("xxl")} {
                --decos-ratio: 0.25;
            }

            .c-decos__path {

                .-path-1 {
                    @media #{md("md")} {
                        height: auto;
                        top:0 ;
                        bottom: calc( var(--decos-height-ratio) + ( var(--decos-path-width) / 2 ));
                    }
                }
                .-path-2 {
                    bottom: calc( var(--decos-height-ratio) * 2);
                    border-top-right-radius: calc( var(--decos-path-width) / 2 );
                    border-bottom-right-radius: 0;

                    @media #{md("md", "max")} {
                        left: 0;
                        //outline: 4px dotted black;
                    }

                    @media #{md("md")} {
                        border-radius: var(--decos-path-width);
                        right: var(--container-gutter);
                        bottom: var(--decos-height-ratio);
                    }
                }

                .-path-3 {
                    bottom: 0;
                    height: calc( var(--decos-height-ratio) + var(--decos-path-width) );

                    @media #{md("md")} {
                        border-top-right-radius: 0;
                        height: auto;
                        top: 0;
                        right: var(--container-gutter);
                        bottom: calc( var(--decos-height-ratio) + ( var(--decos-path-width) / 2 ));
                    }
                }
            }

            .c-decos__shape {
                &.-shape-1 {
                    left: auto;
                    right: 82%;
                    bottom: calc( var(--decos-height-ratio) * 2);

                    @media #{md("xs")} {
                        right: 74%;
                    }

                    @media #{md("sm")} {
                        right: 70%;
                    }

                    @media #{md("md")} {
                        top: 2%;
                        left: 0%;
                        right: auto;
                        bottom: auto;
                    }
                    @media #{md("lg")} {
                        top: 4%;
                        left: 4%;
                    }
                    @media #{md("xl")} {
                        top: 6%;
                        left: 8%;
                    }
                }
                &.-shape-2 {
                    display: none;
                }
                &.-shape-3 {
                    bottom: calc( var(--decos-height-ratio) * 2 - 17%);
                    right: 0;
                    transform: translateY( calc(var(--perc) * 100% / 1.5 ) );

                    @media #{md("xs")} {
                        //outline: 2px dotted black;
                        bottom: calc( var(--decos-height-ratio) * 2 - 12%);
                    }

                    @media #{md("sm")} {
                        //outline: 2px dotted blue;
                        bottom: calc( var(--decos-height-ratio) * 2 - 12%);
                    }

                    @media #{md("md")} {
                        top: 40%;
                        left: 0;
                        right: auto;
                        bottom: auto;
                        transform: translateY( calc(var(--perc) * 100% * -1 / 2 ) );
                    }
                    @media #{md("lg")} {
                        top: 40%;
                        left: 4%;
                    }
                    @media #{md("xl")} {
                        top: 36%;
                        left: 8%;
                    }
                }
            }
        }

        // PATTERN 1
        &.-pattern-1 {
            --decos-ratio: 0.75;

            @media #{md("md")} {
                --decos-ratio: 0.5;
            }
            @media #{md("lg")} {
                --decos-ratio: 0.6;
            }

            @media #{md("xxl")} {
                --decos-ratio: 0.75;
            }

            .c-decos__path {

                .-path-2 {
                    border-top-right-radius: calc( var(--decos-path-width) / 2 );
                    border-bottom-right-radius: 0;

                    @media #{md("md")} {
                        border-top-right-radius: calc( var(--decos-path-width) );
                        bottom: calc( var(--decos-height-ratio) );
                    }
                }
                .-path-3 {
                    bottom:0;
                    height: calc( var(--decos-height-ratio) + 1px);

                    @media #{md("md")} {
                        border-top-right-radius: 0;
                    }
                }
            }

            .c-decos__shape {

                &.-shape-1 {
                    top: 0;
                    left: auto;
                    right: 82%;

                    @media #{md("xs")} {
                        right: 74%;
                    }
                    @media #{md("sm")} {
                        right: 70%;
                    }
                    @media #{md("md")} {
                        top: -8%;
                        left: 0%;
                        right: auto;
                    }
                    @media #{md("lg")} {
                        top: -12%;
                        left: 4%;
                    }
                    @media #{md("xl")} {
                        top: -10%;
                        left: 8%;
                    }
                }
                &.-shape-2 {

                    @media #{md("md")} {
                        top: 38%;
                    }
                    @media #{md("lg")} {
                        top: 32%;
                    }
                    @media #{md("xl")} {
                    }
                }
                &.-shape-3 {
                    top: 18%;

                    @media #{md("xs")} {
                        top: 15%;
                    }
                    @media #{md("md")} {
                        top: 60%;
                    }
                    @media #{md("lg")} {
                        top: 52%;
                    }
                }
            }
        }
    }

}
</style>
