<template>
    <div v-if="featuredEntry" class="b-featured-entry">
        <featured-entry
            :entry='featuredEntry'
            :card-type="cardType"
            :img-shape="data.assetShape"
            :display-before-asset="false"
            :model="model"
        />
    </div>
</template>

<script>
import FeaturedEntry from '../components/FeaturedEntry.vue'
export default {
    components: { FeaturedEntry },
    name: 'BlockFeaturedEntry',
    props: {
        data: null,
    },
    data: () => ({
        featuredEntry: null
    }),
    computed: {
        cardType() {
            if (this.featuredEntry) {
                let type = null
                switch (this.featuredEntry.section) {
                    case 'project': type = 'project'; break
                    case 'campaign': type = 'campaign'; break
                    default : type = 'default'
                }
                return type
            }
        },
        model () {
            return this.cardType != 'default' ? 'enhance' : 'standard'
        }
    },
    created() {

        if (this.data && this.data.entry.length) {

            // Increase loader value
            this.$store.dispatch('loader/startLoad')

            const { section, slug } = this.data.entry[0]

            this.$store.dispatch( section + '/loadElement', slug )
                .then(({ description, ...rest }) => {
                    this.description = description
                    this.featuredEntry = rest

                    // Decrease loader value
                    this.$store.dispatch('loader/endLoad')
                })
        }
    },
    mounted() {
        window.addEventListener('resizeEnd', this.getAssetSize)
    },
    methods: {
        getAssetSize() {
            this.$nextTick(() => {
                const img = this.$el.querySelector('img')

                this.$el.style.setProperty('--asset-width', img.innerWidth || img.clientWidth + 'px')
                this.$el.style.setProperty('--asset-height', img.innerHeight || img.clientHeight + 'px')
            })
        },
    },
    destroyed() {
        window.removeEventListener('resizeEnd', this.getAssetSize)
    },
    watch: {
        featuredEntry () {
            this.$nextTick(this.getAssetSize)
        }
    }

}
</script>

<style lang="scss">
.b-featured-entry {

    .c-featured__entry {
        width: fit-content;
        margin: auto;
        padding: 0;
        transform: translate(0);

        &:after {
            @include pseudo-el(
                $height: auto,
                $width: auto,
            );
            position: absolute;
            top: calc(var(--asset-height) / 2);
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            border: 1px $color-beige solid;
            border-radius: $border-radius-md;

            @media #{md('md')} {
                top: 0;
                left: calc(var(--asset-width) / 2);
            }
        }

        .c-card {
            width: 100%;
            padding: 2rem;

            &__content {
                gap: 1rem;
                max-width: 30ch;
            }

            &__title {
                font-size: fs('large');
            }
        }


        .c-btn.-tag {
            padding-left: 0;
        }

        @media #{md('sm')} {
            flex-direction: column;

            .-enhance .c-card__link {
                left: var(--grid-gutter-half);
            }
        }
        @media #{md('md')} {
            flex-direction: row;

            .-enhance .c-card__link {
                white-space: pre;
                left: var(--grid-gutter);
            }

            .c-card {
                padding-left: var(--grid-gutter);
            }
        }

        .o-asset {
            width: 100%;
        }
    }
}

</style>
