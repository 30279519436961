<template>
    <slider
        v-if="slides && slides.length >= 3"
        :slides="slides"
        ref="slider"
        :fullheight="false"
        :prevNext="true"
        :show-index="true"
        :zoom="true"
        modifier="gallery"
        class="b-gallery"
        :options="{
            wrapAround: false,
            draggable: true,
            groupCells: true,
            cellAlign: 'left'
        }"
    >
        <template #slide="image">
            <figure>
                <asset
                    :asset="image"
                    :rounded="true"
                    @loaded="refreshSlider()"
                    :shape="randomShapes[image.index]"
                />
                <figcaption v-if="data.displayCaption" v-html="image.title" />
            </figure>
        </template>
    </slider>
</template>

<script>
import Asset from 'objects/Asset'
import Slider from 'components/Slider'
import Icon from 'objects/Icon'

import { getRandom } from 'src/utils'
import { SHAPES } from 'src/constants'

export default {
    name: 'BlockGallery',
    components: {
        Icon,
        Asset,
        Slider,
    },
    props: {
        data: false
    },
    computed: {
        slides() {
            if (this.data && this.data.images) {
                let images = this.data.images//.map(imgObj => imgObj.image[0])

                return images
            }

            return []
        },
        randomShapes() {
            if (this.data.images.length > SHAPES.length)
                return null

            return getRandom(SHAPES, this.data.images.length)
        }
    },
    methods: {
        refreshSlider() {
            this.$refs.slider.resize()
        },
    },
}
</script>

<style lang="scss">
.b-gallery {
    display: flex;
    flex-direction: column-reverse;

    &.c-slider {
        @media #{md('xs')} {
            --slide-width: 50%;
        }

        @media #{md('lg')} {
            --slide-width: 33.33%;
        }

        .c-slider__slide {
            padding: 0 var(--grid-gutter-half)
        }

    }

    .flickity-viewport {
        overflow: hidden;
    }

    .flickity-slider {
        display: flex;
        align-items: center;
    }

    .c-slider__side {
        justify-content: flex-end;
    }

    .c-slider__btn,
    .c-slide__count {
        --nav-bg: #{$color-light};
    }

    figcaption {
        text-align: center;
        margin-top: 1rem;
        font-size: fs('small');
    }
}
</style>
