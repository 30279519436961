var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"tag",staticClass:"c-btn",class:{
        '-focus': _vm.focus,
        '-large': _vm.size === 'large',
        '-small': _vm.size === 'small',
        '-has-shape': _vm.isShaped,
        '-has-shape--left': _vm.isShaped && _vm.shapeDir == 'left',
        '-has-shape--right': _vm.isShaped && _vm.shapeDir == 'right',
        '-has-icon': _vm.iconBefore || _vm.iconAfter,
    },attrs:{"href":_vm.url,"to":_vm.tag == 'router-link' && _vm.url ? _vm.url : false,"rel":_vm.tag == 'a' ? 'noopener' : false,"target":_vm.tag == 'a' ? '_blank' : false}},[(_vm.iconBefore !== null)?_c('icon',{staticClass:"c-btn__icon",attrs:{"icon":_vm.iconBefore}}):_vm._e(),_c('span',{staticClass:"c-btn__label"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2),(_vm.iconAfter !== null)?_c('icon',{staticClass:"c-btn__icon",attrs:{"icon":_vm.iconAfter}}):_vm._e(),_c('background-shape',{staticClass:"c-btn__bg",attrs:{"is-shaped":_vm.isShaped,"shape-dir":_vm.shapeDir}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }