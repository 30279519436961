import Vue from 'vue'
import { linkResolver } from 'src/router'


// Prefix url for translation
Vue.filter('resolver', function (handle, slug) {
    if (!handle) {
        return '/'
    }

    return linkResolver(handle, slug)
})


// Prefix url for translation
Vue.filter('tel', number => {

    // Remove all non numeric characters
    number = number.replace(/\D/g,'');

    number = `tel:+${number}`
    return number
})

//
Vue.filter('toBr', str => {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
})

Vue.filter('snake', str => {
    return str.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('-');
})


// Capitalize string
Vue.filter('capitalize', str => str.charAt(0).toUpperCase() + str.slice(1))
