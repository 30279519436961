<template>
    <app-section>
        <featured-entry
            v-if="featuredEntry"
            :entry="featuredEntry"
            card-type="project"
            before-asset-shape="circle"
        />
        <app-elements
            v-if="isPageLoaded && featuredEntryId !== null"
            sectionHandle="project"
            cardType="project"
            cardModel="enhance"
            :options="{
                limit: 9,
                ids: [featuredEntryId],
                idsExclude: true
            }"
        />
    </app-section>
</template>

<script>
import AppElements from 'layout/AppElements'
import AppSection from 'layout/AppSection'
import FeaturedEntry from '../components/FeaturedEntry.vue';

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Projects',
    components: {
        AppElements,
        AppSection,
        FeaturedEntry,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        featuredEntry: null,
    }),
    computed: {
        isPageLoaded() {
            return this.page.id || false
        },
        featuredEntryId() {

            // Escape if page isn't properly loaded
            if (!this.isPageLoaded)
                return null

            return this.page.featuredEntry ? this.page.featuredEntry.id : 0
        },
        style() {
            if(this.featuredEntry)
                return `--featured-color: var(--color-${this.featuredEntry.colour})`
        }
    },
    watch: {
        featuredEntryId(id) {

            if (id && this.featuredEntry === null) {

                this.$store.dispatch('loader/startLoad')

                this.$store.dispatch('project/loadElement', this.page.featuredEntry.slug)
                    .then(project => {
                        this.featuredEntry = project
                        this.$store.dispatch('loader/endLoad')
                    })
            }
        }
    }
};

</script>

<style lang="scss">

.p-projects {
}

</style>
